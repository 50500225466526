import { Pipe, PipeTransform } from '@angular/core';
import { formatNumbers } from '../../../../shared/pipes/number-formatter.pipe';

@Pipe({
  name: 'rangeTextFormatter',
})
export class RangeTextFormatterPipe implements PipeTransform {
  transform(
    colorScaleColors: any[][],
    index: number,
    activeLayer: string
  ): string {
    if (index === 0) {
      if (colorScaleColors.length === 1) {
        return `${formatNumbers(colorScaleColors[index][0], 2, activeLayer)}`;
      }
      return `< ${formatNumbers(colorScaleColors[index][0], 2, activeLayer)}`;
    } else if (index !== colorScaleColors.length - 1) {
      return `${formatNumbers(colorScaleColors[index - 1][0], 2, activeLayer)}
      - ${formatNumbers(colorScaleColors[index][0], 2, activeLayer)}`;
    } else {
      return `> ${formatNumbers(colorScaleColors[index - 1][0], 2, activeLayer)}`;
    }
  }
}
