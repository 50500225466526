import {
  ApplicationRef,
  createComponent,
  EmbeddedViewRef,
  Injectable,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as mapboxglType from 'mapbox-gl';
import { LngLatLike, MapboxGeoJSONFeature } from 'mapbox-gl';
import { CellGeoData } from './geocoder-cache';
import { ORI_LAYER } from './layer-store.service';
import { MapBoxService } from '../mapbox.service';
import { schoolsPoiLayers } from '../../menu/right-menu/layers-menu/education/education.service';
import { MapHttpService } from './map-http.service';
import { DatePipe } from '@angular/common';
import { BreakpointObserverService } from '../../../shared/services/breakpoint-observer.service';
import { Feature, Position } from 'geojson';
import {
  DEFAULT_POPUP,
  HOVER_POPUP,
  MapPopupComponent,
  ORI_POPUP,
  PopupData,
  SCHOOLS_POPUP,
} from '../visualization/map-popup/map-popup.component';
import { SearchGeocoderService } from '../../../shared/services/search-geocoder.service';
import { SelectedCellService } from './selected-cell.service';
import { pointsLayersList } from '../../menu/right-menu/layers-menu/points.service';
import { ReportsService } from '../../../reports/reports.service';
import { geocoderContextToString } from '../../../shared/util/geocoder-context-to-string';
import { ModalService } from '../../../shared/services/modal.service';
import { FavoritesService } from '../../favorites/favorites.service';
import { convertLayerIdToCellType } from '../../../shared/util/layerIdToCellType';
import { Router } from '@angular/router';

export const CLICK = 'CLICK';
export const HOVER = 'HOVER';

export type PopupActionType = typeof CLICK | typeof HOVER;

interface PopupOffsetConfig {
  offsetXFactor?: number;
  offsetYTopFactor?: number;
  offsetYMidFactor?: number;
  offsetYBottomFactor?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public popupTitle!: string;
  public popup: mapboxglType.Popup = new mapboxgl.Popup({
    maxWidth: '400px',
    closeButton: false,
    closeOnClick: false,
  });

  public isSeeMoreActive: boolean = false;

  public currentlySelectedId: string | null = null;
  public currentlySelectedLayer: string | null = null;

  constructor(
    private mapboxService: MapBoxService,
    private mapHttpService: MapHttpService,
    private datePipe: DatePipe,
    private isMobileService: BreakpointObserverService,
    private geocoderService: SearchGeocoderService,
    private selectedCellService: SelectedCellService,
    private appRef: ApplicationRef,
    private reportsService: ReportsService,
    private modalService: ModalService,
    private favoritesService: FavoritesService,
    private router: Router
  ) {}

  public handlePopup(
    feature: MapboxGeoJSONFeature,
    coordinates: LngLatLike,
    actionType: PopupActionType
  ): void {
    if (this.popup && this.popup.isOpen()) {
      this.popup.remove();
    }

    this.currentlySelectedId = feature.properties!.external_id;
    this.currentlySelectedLayer = feature.layer.id;

    const data: PopupData = {
      type:
        actionType === HOVER
          ? HOVER_POPUP
          : schoolsPoiLayers.includes(feature.layer.id)
            ? SCHOOLS_POPUP
            : feature.layer.id === ORI_LAYER
              ? ORI_POPUP
              : DEFAULT_POPUP,
      properties: { ...feature.properties, ...feature.state },
    };

    if (pointsLayersList.includes(feature.layer.id)) {
      this.handlePointsPopup(feature);
    }

    this.initPopup(coordinates);
    this.setPopupContent(data);
  }

  public initPopup(coordinates: mapboxgl.LngLatLike): void {
    const isMobile = this.isMobileService.isMobile.getValue();
    const { anchor, offset } = {
      anchor: 'center' as mapboxgl.Anchor,
      offset: [0, 0] as mapboxgl.Offset,
    };

    if (isMobile) {
      this.popup = new mapboxgl.Popup({
        anchor: anchor,
        offset: offset,
        maxWidth: '400px',
        closeButton: false,
        closeOnClick: false,
      }).setLngLat(coordinates);

      this.centerPopupOnMobile(coordinates);
    } else {
      this.setDesktopPopupPosition(
        coordinates,
        this.mapboxService.map,
        this.popup
      );
    }
  }

  public setDesktopPopupPosition(
    coordinates: mapboxgl.LngLatLike,
    map: mapboxgl.Map,
    popup: mapboxgl.Popup,
    config?: PopupOffsetConfig
  ): void {
    // Defaults:
    const offsetXFactor = config?.offsetXFactor ?? 0.1;
    const offsetYTopFactor = config?.offsetYTopFactor ?? 0.25;
    const offsetYMidFactor = config?.offsetYMidFactor ?? 0.1;
    const offsetYBottomFactor = config?.offsetYBottomFactor ?? 0.1;

    // Project the coordinates to pixels
    const projected = map.project(coordinates);

    // Get map container sizes
    const mapContainer = map.getContainer();
    const mapWidth = mapContainer.offsetWidth;
    const mapHeight = mapContainer.offsetHeight;

    // Calculate percentages for x and y relative to map width and height
    const xPercentage = (projected.x / mapWidth) * 100;
    const yPercentage = (projected.y / mapHeight) * 100;

    const newPoint = { x: projected.x, y: projected.y };
    let offsetX = 0;
    let offsetY = 0;

    // Horizontal offset: adjust differently depending on which half of the map the point is in
    if (xPercentage > 50) {
      offsetX = -mapWidth * offsetXFactor;
    } else {
      offsetX = mapWidth * offsetXFactor;
    }

    // Vertical offset: use different factors based on the y position
    if (yPercentage < 30) {
      offsetY = mapHeight * offsetYTopFactor;
    } else if (yPercentage >= 30 && yPercentage < 80) {
      offsetY = -mapHeight * offsetYMidFactor;
    } else if (yPercentage >= 80) {
      offsetY = -mapHeight * offsetYBottomFactor;
    }

    newPoint.x += offsetX;
    newPoint.y += offsetY;

    // Unproject the new point and update the popup location
    const newCoord = map.unproject(newPoint as mapboxgl.PointLike);
    popup.setLngLat(newCoord);
  }

  public setPopupTopRight(
    map: mapboxgl.Map,
    popup: mapboxgl.Popup,
    margin: number = 10
  ): void {
    // Get the map container dimensions
    const mapContainer = map.getContainer();
    const mapWidth = mapContainer.offsetWidth;
    // Use the margin for both x and y directions
    const topRightPixel = { x: mapWidth - margin, y: 5 };
    // Convert pixel position to geographical coordinates
    const topRightCoord = map.unproject(topRightPixel as mapboxgl.PointLike);
    // Set the popup's location to the computed coordinates
    popup.setLngLat(topRightCoord);
  }

  private centerPopupOnMobile(coordinates: LngLatLike): void {
    const mapContainer = this.mapboxService.map;
    if (mapContainer) {
      mapContainer.flyTo({
        center: coordinates,
        zoom: mapContainer.getZoom(),
      });
      this.popup.addTo(mapContainer);
    }
  }

  public handlePopupTitle(feature?: MapboxGeoJSONFeature): void {
    this.setTitle('');

    const selectedFeature =
      feature ?? this.selectedCellService.getSelectedFeature();

    const id = selectedFeature?.id;
    const center = this.mapboxService.getFeatureCenter(selectedFeature!);

    if (!this.geocoderService.geocoderCache.getCellData(id)) {
      this.handleGeocoderRequest(id, center).then((res) => {
        this.geocoderService.geocoderCache.addCell(res.id, res.data);
        this.updateTitle(selectedFeature!);
      });
    } else {
      this.updateTitle(selectedFeature!);
    }
  }

  public handleOnLoadPopup(
    feature: MapboxGeoJSONFeature,
    coordinates: LngLatLike
  ): void {
    this.handlePopup(
      feature,
      this.mapboxService.getFeatureCenter(feature),
      CLICK
    );
  }

  private setPopupContent(data: PopupData): void {
    const componentRef = createComponent(MapPopupComponent, {
      environmentInjector: this.appRef.injector,
    });

    componentRef.instance.data = data;

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.popup.setDOMContent(domElem).addTo(this.mapboxService.map);

    this.popup.on('close', () => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
  }

  private async handleGeocoderRequest(
    featureID: Feature['id'],
    coordinates: Position
  ): Promise<{
    id: Feature['id'];
    data: CellGeoData;
  }> {
    this.geocoderService.reverseGeocoder.query(
      `${coordinates[1]},${coordinates[0]}`
    );

    const { id, data } = await this.handleGeocoderResult(featureID);
    return { id, data };
  }

  private handleGeocoderResult(
    id: Feature['id']
  ): Promise<{ id: Feature['id']; data: CellGeoData }> {
    return new Promise((res) => {
      const resultHandler = (event: any) => {
        this.geocoderService.reverseGeocoder.off('result', resultHandler);
        res({ id: id, data: event.result });
      };

      this.geocoderService.reverseGeocoder.on('result', resultHandler);
    });
  }

  private updateTitle(feature: MapboxGeoJSONFeature): void {
    const id = feature.id;
    const levelLayer = feature.layer.id;
    const defaultName = feature!.properties!.name;
    const cellData = this.geocoderService.geocoderCache.getCellData(id);

    if (!cellData) {
      return;
    }

    let title = geocoderContextToString(cellData, levelLayer, defaultName);

    // Fallback to coordinates if no title could be constructed
    if (!title.trim()) {
      title = `lng: ${cellData.center[0].toFixed(
        2
      )} lat: ${cellData.center[1].toFixed(2)}`;
    }

    feature.properties!.addressToCompare = title;
    this.setTitle(title);
  }

  private handlePointsPopup(feature: MapboxGeoJSONFeature): void {
    this.popup.once('open', () => {
      this.assignSelectedStatus(true, feature);
    });

    this.popup.once('close', () => {
      this.assignSelectedStatus(false, feature);
    });
  }

  private assignSelectedStatus(
    status: boolean,
    feature: MapboxGeoJSONFeature
  ): void {
    this.mapboxService.map.setFeatureState(feature, { selected: status });
  }

  private setTitle(title: string): void {
    this.popupTitle = title;

    // Also set title for selected location in reports
    this.reportsService.selectedLocation = title;
  }

  public downloadXlsx(): void {
    if (!this.currentlySelectedId || !this.currentlySelectedLayer) {
      return;
    }

    this.mapHttpService
      .downloadXlsx(
        this.currentlySelectedId,
        convertLayerIdToCellType(this.currentlySelectedLayer)!
      )
      .subscribe((data) => {
        const currentTime = this.datePipe.transform(
          new Date(),
          'yyyy-MM-dd HH:mm:ss'
        );

        const blob: Blob = data as Blob;
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.currentlySelectedLayer}_${
          this.currentlySelectedId
        }_${currentTime}.xlsx`;
        link.click();
      });
  }

  public handleAddToFavorites(): void {
    if (!this.currentlySelectedId || !this.currentlySelectedLayer) {
      return;
    }
    this.modalService.handleFavoriteCellPersonalizationModal(
      this.currentlySelectedId,
      this.popupTitle,
      this.currentlySelectedLayer
    );
  }

  public handleRemoveFromFavorites(): void {
    if (!this.currentlySelectedId || !this.currentlySelectedLayer) {
      return;
    }
    this.favoritesService.removeFavoriteCell(
      this.currentlySelectedId,
      convertLayerIdToCellType(this.currentlySelectedLayer)!
    );
  }

  public openCellDescriptionModal(isUnauthorized: boolean): void {
    if (isUnauthorized) {
      this.router.navigate(['sign-in']);
    } else {
      if (!this.currentlySelectedId || !this.currentlySelectedLayer) {
        return;
      }

      this.modalService.openCellAIDescriptionModal(
        this.currentlySelectedId,
        this.currentlySelectedLayer,
        this.popupTitle
      );
    }
  }

  public toggleSeeMore(): void {
    this.isSeeMoreActive = !this.isSeeMoreActive;

    // Simulate map.move simply to recalculate popup position relative to map bounds and rerender it accordingly
    this.mapboxService.flyToCurrentCenter();
  }

  public handleRequestReport(): void {
    this.router.navigate(['reports']);
  }

  public handleClose(): void {
    this.selectedCellService.deselectCell();
    this.popup.remove();
  }
}
