import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appChartLegendGapFix]',
})
export class ChartLegendGapFixDirective implements AfterViewInit, OnDestroy {
  //we need mutation observer to adjust gap on rerender
  private observer!: MutationObserver;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.observer = new MutationObserver(() => {
      this.modifyLegendTextX();
    });

    this.observer.observe(this.elRef.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private modifyLegendTextX() {
    const textElements = this.elRef.nativeElement.querySelectorAll(
      '.traces .legendtext'
    );

    // set the position of plotly legend label to decrease
    // the gap between marker and label (default '40')
    textElements.forEach((textLegend: SVGTextElement) => {
      textLegend.setAttribute('x', '30');
    });
  }
}
