import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IconRegistry {
  private svgIconMap = new Map<string, SafeResourceUrl>();

  constructor(private domSanitizer: DomSanitizer) {}

  /**
   * Registers an SVG icon by name
   * @param iconName Name to associate with the icon
   * @param url Safe resource URL for the SVG icon
   */
  addSvgIcon(iconName: string, url: SafeResourceUrl): void {
    this.svgIconMap.set(iconName, url);
  }

  /**
   * Gets an SVG icon by name
   * @param iconName Name of the icon to retrieve
   * @returns The SafeResourceUrl for the icon or undefined if not found
   */
  getIcon(iconName: string): SafeResourceUrl | undefined {
    return this.svgIconMap.get(iconName);
  }

  /**
   * Gets an SVG icon as an Observable (MatIconRegistry compatibility)
   * @param namespace Namespace (ignored in this implementation)
   * @param iconName Name of the icon to retrieve
   * @returns Observable of the icon or error if not found
   */
  getNamedSvgIcon(
    iconName: string,
    namespace: string = ''
  ): Observable<SafeResourceUrl> {
    // Remove colon prefix if present
    if (iconName.startsWith(':')) {
      iconName = iconName.substring(1);
    }

    const icon = this.svgIconMap.get(iconName);
    if (icon) {
      return of(icon);
    }

    return throwError(
      () =>
        new Error(
          `Unable to find icon with the name "${namespace ? namespace + ':' : ''}${iconName}"`
        )
    );
  }

  /**
   * Checks if an icon exists
   * @param iconName Name of the icon to check
   * @returns True if the icon exists, false otherwise
   */
  hasIcon(iconName: string): boolean {
    return this.svgIconMap.has(iconName);
  }

  /**
   * Removes an icon from the registry
   * @param iconName Name of the icon to remove
   */
  removeIcon(iconName: string): void {
    this.svgIconMap.delete(iconName);
  }
}
