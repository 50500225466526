:host {
  display: inline-block;
}

.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.toggle-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
  font-size: 14px;
}

.toggle-track {
  position: relative;
  width: 36px;
  height: 19px;
  border-radius: 34px;
  background-color: var(--UI-disable);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;

  &.checked {
    background-color: var(--primary-blue);
  }
}

.toggle-thumb {
  position: absolute;
  left: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);

  .checked & {
    transform: translateX(16px);
  }
}
