import { Data, Layout } from 'plotly.js-dist-min';

export const pieChartLegend = {
  x: 0.85,
  y: 0.75,
  traceorder: 'normal',
  orientation: 'v',
};

export const pieChartMargins = { t: 20, l: 5 };

export function getPieChartLayout(
  values: number[] | undefined,
  labelsName: string,
  valuesUnits: string
): Layout {
  return {
    autosize: true,
    font: {
      family: 'Rising Sun, sans-serif',
    },
    height: 315,
    width: 450,
    margin: pieChartMargins,
    annotations: values ? getTotalAnnotation(values) : undefined,
    legend: pieChartLegend as any,
    yaxis: {
      title: {
        text: labelsName,
        font: {
          size: 1,
          color: '#fff',
        },
      },
    },
    xaxis: {
      title: {
        text: valuesUnits,
        font: {
          size: 1,
          color: '#fff',
        },
      },
    },
  } as Layout;
}
export function sortKeys(data: string[]): string[] {
  return data.sort((a, b) => {
    //sort strings which contains < or > signs, > goes in the end of array and < into beginning
    if (a.includes('<')) return -1;
    if (b.includes('<')) return 1;
    if (a.includes('>')) return 1;
    if (b.includes('>')) return -1;

    //split string in two and parse numbers out of it to compare values
    const aRange = a.split('-').map((el) => Number(el));
    const bRange = b.split('-').map((el) => Number(el));

    return aRange[0] - bRange[0];
  });
}

export function formatNumber(n: number): string {
  let abs = Math.abs(n);
  let rounded = n;

  if (abs >= 1000 && abs < 10000) {
    rounded = Math.ceil(n / 10) * 10;
    abs = Math.abs(rounded);
  } else if (abs >= 10000 && abs < 100000) {
    rounded = Math.ceil(n / 100) * 100;
    abs = Math.abs(rounded);
  } else if (abs >= 100000 && abs < 1000000) {
    rounded = Math.ceil(n / 1000) * 1000;
    abs = Math.abs(rounded);
  }

  if (abs >= 1000000) {
    const millions = rounded / 1000000;
    return (
      (millions % 1 === 0
        ? millions.toFixed(0)
        : parseFloat(millions.toFixed(2)).toString()) + 'M'
    );
  } else if (abs >= 1000) {
    const thousands = rounded / 1000;
    return (
      (thousands % 1 === 0
        ? thousands.toFixed(0)
        : parseFloat(thousands.toFixed(2)).toString()) + 'K'
    );
  } else {
    return rounded.toFixed(0);
  }
}

export function getPercents(
  totalValue: number,
  populations: number[]
): number[] {
  return populations.map((el) => {
    return (el / totalValue) * 100;
  });
}

export function formatChartRangesAndHoverNumber(
  input: string | number
): string {
  //handle if we're getting strings with symbols
  if (typeof input === 'string') {
    if (input.includes('-')) {
      const [start, end] = input.split('-').map((str) => parseInt(str, 10));
      return formatNumber(start) + '-' + formatNumber(end);
    } else if (input.startsWith('<')) {
      return '<' + formatNumber(parseInt(input.slice(1), 10));
    } else if (input.startsWith('>')) {
      return '>' + formatNumber(parseInt(input.slice(1), 10));
    } else {
      return formatNumber(parseInt(input, 10));
    }
  } else {
    return formatNumber(input);
  }
}

export function getTotalAnnotation(values: number[]): any {
  return [
    {
      font: {
        size: 12,
      },
      showarrow: false,
      text: `Total:<br>${values.reduce((acc, val) => acc + val, 0)}`,
      x: 0.5,
      y: 0.5,
    },
  ];
}

export function pieChartDataToCSV(data: Data, layout: Layout): string {
  const header = ['Label', 'Value'];
  const rows = [header];

  if ((data as any)[0]?.labels && (data as any)[0]?.values) {
    const labels = (data as any)[0].labels;
    const values = (data as any)[0].values;

    // Combine labels and values, then sort by value in descending order
    const sortedData = labels
      .map((label: string, i: number) => ({
        label,
        value: values[i],
      }))
      .sort((a: any, b: any) => b.value - a.value);

    // Create rows for each label-value pair
    for (const item of sortedData) {
      rows.push([item.label, item.value]);
    }
  }

  // Join rows into a CSV string
  return rows.map((row) => row.join(',')).join('\n');
}

export function tracesBarChartDataToCSV(data: Data, layout: Layout): string {
  const csvRows = [];
  let suffix = '';

  if (layout.yaxis?.ticksuffix || layout.xaxis?.ticksuffix) {
    const yAxisSuffix = layout.yaxis?.ticksuffix || '';
    const xAxisSuffix = layout.xaxis?.ticksuffix || '';
    suffix =
      yAxisSuffix.includes('%') || xAxisSuffix.includes('%') ? ', %' : '';
  }

  // Determine labels/values array paths
  const labelsPath: string = (data as any)[0].y.every(
    (el: number | string) => typeof el === 'string'
  )
    ? 'y'
    : 'x';
  const valuesPath: string = labelsPath === 'x' ? 'y' : 'x';

  const labels = (data as any)[0][labelsPath];

  if ((data as any).length > 1 && (data as any)[0].name) {
    // Create the header row with trace names
    const headerRow = [
      escapeCSVValue((layout as any)[`${labelsPath}axis`].title.text),
      ...(data as any).map((trace: any) => {
        return escapeCSVValue(trace.name + suffix);
      }),
    ];
    csvRows.push(headerRow.join(','));
  } else {
    const headerRow = [
      escapeCSVValue((layout as any)[`${labelsPath}axis`].title.text),
      escapeCSVValue((layout as any)[`${valuesPath}axis`].title.text + suffix),
    ];
    csvRows.push(headerRow.join(','));
  }

  function escapeCSVValue(value: string): string {
    if (value.includes('"')) {
      return `"${value.replace(/"/g, '""')}"`;
    }

    if (value.includes(',')) {
      return `"${value}"`;
    }

    return value;
  }

  function removeHtmlTags(text: string): string {
    return text.replace(/<[^>]*>/g, '');
  }

  function isString(label: any): label is string {
    return typeof label === 'string';
  }

  // Create row with values for each label
  for (const label of labels) {
    const valuesRow = [
      isString(label)
        ? removeHtmlTags(label)
        : label.toFixed(label >= 1 ? 0 : 2),
    ];

    // Add values from each trace for the current label
    for (const trace of data as any) {
      const labelIndex = trace[labelsPath].indexOf(label);

      if (labelIndex !== -1) {
        const value = Math.abs(trace[valuesPath][labelIndex]);
        valuesRow.push(value.toFixed(value >= 1 ? 0 : 2));
      } else {
        valuesRow.push('');
      }
    }

    // Only add the row if there's a value for any trace
    if (valuesRow.slice(1).some((value) => value !== '')) {
      csvRows.push(valuesRow.join(','));
    }
  }

  // Join rows with line breaks to create the CSV string
  return csvRows.join('\n');
}
