import {
  PopulationSample,
  PopulationSampleAttributes,
  PopulationSamples,
} from './population';
import { AgeSexDataJSON } from '../../map/menu/right-menu/layers-menu/population-menu/population.service';
import { AgeRange, IncomeRange, YearRange } from './range';
import { PersonalDistributionJSON } from '../../map/menu/right-menu/layers-menu/wealth-menu/income-distribution.service';
import {
  IncomeSample,
  IncomeSampleAttributes,
  IncomeSamples,
  Work,
} from './income';

export const WORK_FULLTIME = 'FULL_TIME';
export const WORK_OTHER = 'OTHER'


export function mapAgeSexDistribution(backendAgeSex: AgeSexDataJSON[]): PopulationSamples {
  const samples = backendAgeSex.map(mapSingle);
  return new PopulationSamples(samples);
}

export function mapIncomeDistribution(backendIncome: PersonalDistributionJSON[]): IncomeSamples {
  const samples: IncomeSample[] = backendIncome.map(mapSingleIncome);
  return new IncomeSamples(samples);
}


export function mapSingle(p: AgeSexDataJSON) {
  const ageRange = mapAgeRangeJson(p);
  const yearRange = YearRange.ofSingleYear(p.year);

  const sample: PopulationSampleAttributes =
    new PopulationSampleAttributes(p.sex, ageRange, yearRange);
  return new PopulationSample(sample, p.population);
}

export function mapSingleIncome(p: PersonalDistributionJSON): IncomeSample {
  const incomeRange = mapIncomeRange(p);
  const yearRange = YearRange.ofSingleYear(p.year);
  const work = mapWork(p);
  const sample = new IncomeSampleAttributes(p.sex, incomeRange, yearRange, work);
  return new IncomeSample(sample, p.population);
}


export function mapAgeRangeJson(data: AgeSexDataJSON): AgeRange {
  if (!data.ageFrom && !data.ageTo) {
    return AgeRange.ofAnyAge();
  }
  const from = data.ageFrom || 0;
  const to = data.ageTo != null ? data.ageTo + 1 : AgeRange.MAX_AGE;

  return new AgeRange(from, to);
}


export function mapIncomeRange(d: PersonalDistributionJSON): IncomeRange {
  if (!d.incomeFrom && !d.incomeTo) {
    return IncomeRange.ofAnyIncome();
  }

  const from = d.incomeFrom || 0;
  const to = d.incomeTo != null ? d.incomeTo + 1 : IncomeRange.MAX_INCOME;

  return new IncomeRange(from, to);
}



export function mapWork(p: PersonalDistributionJSON): Work {
  if (!p.workType) {
    return Work.ANY
  }

  if (p.workType === WORK_FULLTIME) {
    return Work.FULLTIME
  }
  if (p.workType === WORK_OTHER) {
    return Work.OTHER
  }


  throw new Error('Can\'t parse work from ' + JSON.stringify(p));
}
