import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorscaleService } from '../mapbox/visualization/colorscale/colorscale.service';
import { ColorScaleComponent } from '../mapbox/visualization/colorscale/color-scale.component';
import { NgIf } from '@angular/common';
import { MapStyleSelectorComponent } from '../map-style-selector/map-style-selector.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { IconRegistryService } from '../../shared/services/icon-registry.service';
import { IconButtonDirective } from '../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-common-colorscale',
  templateUrl: './common-colorscale.component.html',
  imports: [
    ColorScaleComponent,
    NgIf,
    MapStyleSelectorComponent,
    IconComponent,
    IconButtonDirective,
  ],
  styleUrls: ['./common-colorscale.component.scss', '../../app.component.scss'],
})
export class CommonColorscaleComponent implements OnInit {
  @Input() currentLayer!: string;
  @Input() isMobile: boolean = false;

  @Output() openMenuMobile = new EventEmitter<void>();

  constructor(
    public colorScaleService: ColorscaleService,
    private iconRegistry: IconRegistryService
  ) {
    this.iconRegistry.initRightMenuIcons();
  }

  ngOnInit(): void {}

  public emitOpenMenuMobile(): void {
    this.openMenuMobile.emit();
  }
}
