import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IconRegistryService } from '../../services/icon-registry.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { AuthenticationService } from '../../../user/authentication.service';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';
import { UserAccessService } from '../../../user/access/user-access.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatNavList } from '@angular/material/list';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { filter, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss', 'navigation-mobile.scss'],
  imports: [
    NgIf,
    MatNavList,
    RouterLink,
    MatTooltip,
    RouterLinkActive,
    AsyncPipe,
    IconComponent,
  ],
})
export class NavigationMenuComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @ViewChildren('navLink') navLinks!: QueryList<ElementRef>;

  private updatePointerSubject = new Subject<void>();
  private destroy$ = new Subject<void>();
  private lastUpdateTime = 0;
  private updateThreshold = 100; // Only update every 100ms

  constructor(
    private iconRegistry: IconRegistryService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    public accessService: UserAccessService,
    public breakpointObserverService: BreakpointObserverService
  ) {
    this.iconRegistry.initNavigationIcons();
  }

  ngOnInit(): void {
    // Listen for route changes to update the pointer position
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.triggerPointerUpdate();
      });

    // Set up debounced pointer updates
    this.updatePointerSubject
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => {
        this.updatePointerPosition();
      });
  }

  ngAfterViewInit(): void {
    // Initial position of the pointer after view is initialized
    setTimeout(() => this.triggerPointerUpdate(), 500);
  }

  ngAfterViewChecked(): void {
    // Throttle updates to avoid excessive calculations
    const now = Date.now();
    if (now - this.lastUpdateTime > this.updateThreshold) {
      this.lastUpdateTime = now;
      this.triggerPointerUpdate();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private triggerPointerUpdate(): void {
    this.updatePointerSubject.next();
  }

  updatePointerPosition(): void {
    // Convert NodeLists to arrays for better TypeScript support
    const desktopLinks = Array.from(
      document.querySelectorAll('.navigation-menu-panel .navmenu-links a')
    );
    const mobileLinks = Array.from(
      document.querySelectorAll(
        '.navigation-menu-panel-mobile .navmenu-links a'
      )
    );

    // Handle desktop pointer
    if (!this.breakpointObserverService.isMobile.value) {
      const desktopPointer = document.querySelector(
        '.desktop-pointer'
      ) as HTMLElement;
      if (desktopPointer) {
        let activeLink: HTMLElement | null = null;

        // Find the active link
        for (const link of desktopLinks) {
          const htmlLink = link as HTMLElement;
          const routerLink = htmlLink.getAttribute('routerLink');
          if (routerLink && this.isActiveWithQueryParams(routerLink)) {
            activeLink = htmlLink;
            break;
          } else if (htmlLink.querySelector('.active-link')) {
            activeLink = htmlLink;
            break;
          }
        }

        if (activeLink) {
          const rect = activeLink.getBoundingClientRect();
          const navPanel = document.querySelector(
            '.navigation-menu-panel'
          ) as HTMLElement;
          if (navPanel) {
            const navPanelRect = navPanel.getBoundingClientRect();

            // Position the pointer next to the active link
            desktopPointer.style.top = `${rect.top - navPanelRect.top + rect.height / 2 - desktopPointer.offsetHeight / 2}px`;
            desktopPointer.style.opacity = '1';
          }
        } else {
          desktopPointer.style.opacity = '0';
        }
      }
    }
    // Handle mobile pointer
    else {
      const mobilePointer = document.querySelector(
        '.mobile-pointer'
      ) as HTMLElement;
      if (mobilePointer) {
        let activeLink: HTMLElement | null = null;

        // Find the active link
        for (const link of mobileLinks) {
          const htmlLink = link as HTMLElement;
          const routerLink = htmlLink.getAttribute('routerLink');
          if (routerLink && this.isActiveWithQueryParams(routerLink)) {
            activeLink = htmlLink;
            break;
          } else if (htmlLink.querySelector('.active-link')) {
            activeLink = htmlLink;
            break;
          }
        }

        if (activeLink) {
          const rect = activeLink.getBoundingClientRect();
          const navPanel = document.querySelector(
            '.navigation-menu-panel-mobile'
          ) as HTMLElement;

          if (navPanel) {
            const navPanelRect = navPanel.getBoundingClientRect();

            // Calculate position relative to the nav panel
            const leftPosition =
              rect.left -
              navPanelRect.left +
              rect.width / 2 -
              mobilePointer.offsetWidth / 2;

            // Position the pointer below the active link
            mobilePointer.style.left = `${leftPosition}px`;
            mobilePointer.style.bottom = '0px';
            mobilePointer.style.top = 'auto';
            mobilePointer.style.opacity = '1';
          }
        }
      }
    }
  }

  //check if url corresponds to route including query params for map
  isActiveWithQueryParams(route: string): boolean {
    const currentRoute = this.router.url.split('?')[0];
    const currentQueryParams = this.route.snapshot.queryParams;

    if (currentRoute !== route) {
      return false;
    }

    if (Object.keys(currentQueryParams).length === 0) {
      return true;
    }

    if (currentQueryParams.hasOwnProperty('ll')) {
      return true;
    }

    return false;
  }
}
