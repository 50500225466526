import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[app-icon-button]',
})
export class IconButtonDirective implements AfterViewInit {
  @Input() iconSize: string = '24px';
  @Input() ripple: boolean = true;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const button = this.el.nativeElement as HTMLButtonElement;
    const icon = button.querySelector('app-icon');

    this.renderer.setStyle(button, 'display', 'inline-flex');
    this.renderer.setStyle(button, 'align-items', 'center');
    this.renderer.setStyle(button, 'justify-content', 'center');

    if (icon) {
      this.renderer.setStyle(icon, 'width', this.iconSize);
      this.renderer.setStyle(icon, 'height', this.iconSize);
    }

    if (this.ripple) {
      // this.addRipple(button);
    }
  }

  private addRipple(button: HTMLElement) {
    this.renderer.setStyle(button, 'position', 'relative');
    this.renderer.setStyle(button, 'overflow', 'hidden');

    button.addEventListener('click', (e: MouseEvent) => {
      const ripple = document.createElement('span');
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;

      ripple.style.width = ripple.style.height = `${diameter}px`;
      ripple.style.left = `${e.offsetX - radius}px`;
      ripple.style.top = `${e.offsetY - radius}px`;
      ripple.classList.add('ripple');

      button.appendChild(ripple);

      setTimeout(() => {
        ripple.remove();
      }, 600);
    });
  }
}
