import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import {
  AgeSexDataJSON,
  NaturalId,
  RacePopulationByYear,
} from '../../menu/right-menu/layers-menu/population-menu/population.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RoadLengthBackend } from '../../menu/right-menu/layers-menu/road-density/road-density.service';
import { JsonResponse } from '../../../shared/api/backend-config';
import { TargetAudienceBackend } from '../../menu/right-menu/layers-menu/target-audience/target-audience.service';
import {
  HouseholdDistributionJSON,
  PersonalDistributionJSON,
} from '../../menu/right-menu/layers-menu/wealth-menu/income-distribution.service';
import { HomeownershipJSON } from '../../menu/right-menu/layers-menu/homeownership/homeownership.service';
import {
  EducationDistributionJSON,
  SchoolsPoisData,
} from '../../menu/right-menu/layers-menu/education/education.service';
import { HealthInsuranceJSON } from '../../menu/right-menu/layers-menu/health/health.service';
import { ByCellsJSON } from '../visualization/map-coloring.service';
import {
  PoisChartJSON,
  PoisFeatureCollection,
} from '../../menu/right-menu/layers-menu/pois/pois.service';
import { TrafficIntensityBackend } from '../../menu/right-menu/layers-menu/physical-environment/physical-environment.service';
import {
  arbitraryCellPopupFeatures,
  availableFeatures,
} from '../../../shared/types/feature-data-type';
import { SelectedCellService } from './selected-cell.service';
import { CrimeRateByYearsResponse } from '../../menu/right-menu/layers-menu/points.service';
import { ComparisonDataJSON } from './comparison.service';
import { Feature } from 'geojson';
import { FavoriteCell } from '../../favorites/favorites.service';
import {
  HouseOfRepresentativesElectionByCells,
  SenateElectionByCells,
  VotersDataByCells,
} from '../../menu/right-menu/layers-menu/political-landscape/political-landscape.service';

@Injectable({
  providedIn: 'root',
})
export class MapHttpService {
  private readonly demographyURL = 'features/demography/';
  private readonly ageSexURL = this.demographyURL + 'age-sex';
  private readonly incomeURL = this.demographyURL + 'income';
  private readonly educationAttainmentURL = this.demographyURL + 'education';
  private readonly employmentURL = this.demographyURL + 'employment';
  private readonly homeOwnershipVacancyURL =
    this.demographyURL + 'home-ownership-occupation';
  private readonly homeOwnershipTenureURL =
    this.demographyURL + 'home-ownership-tenure';
  private readonly numberOfRoomsURL = this.demographyURL + 'number-of-rooms';
  private readonly grossRentURL = this.demographyURL + 'gross-rent';
  private readonly insuranceURL = this.demographyURL + 'insurance';
  private readonly housingSellCostURL =
    this.demographyURL + 'housing-sale-cost';
  private readonly targetAudienceURL = 'target-audience';
  private readonly bulkColoringDataURL =
    environment.apiUrl + 'features/get-bulk';
  private readonly pointsOfInterestDataURL =
    environment.apiUrl + 'features/points-of-interest';
  // private readonly accessibleFeaturesURL: string = environment.apiUrl + 'user/access/trial/cells'
  private readonly trafficIntensityURL: string =
    environment.apiUrl + 'features/traffic/intensity';
  private readonly poisGeoJSONURL: string = environment.apiUrl + 'pois';
  private readonly schoolsPoisDataURL: string =
    environment.apiUrl + 'features/schools';
  private readonly xlsxDownloadURL: string =
    environment.apiUrl + 'features/xlsx-v1';
  private readonly comparisonDataURL: string =
    environment.apiUrl + 'features/atUpperLevels';

  constructor(
    private http: HttpClient,
    private selectedCellService: SelectedCellService
  ) {}

  public getFavoriteCells(): Observable<JsonResponse<FavoriteCell[]>> {
    return this.http.get<JsonResponse<FavoriteCell[]>>(
      `${environment.apiUrl}cells/favorites`
    );
  }

  public createFavoriteCell(
    id: string,
    description: string,
    title: string,
    cellType: string
  ): Observable<JsonResponse<any>> {
    return this.http.put<JsonResponse<any>>(
      `${environment.apiUrl}cells/favorite?favorite=true&geoId=${id}&cellType=${cellType}`,
      { description, title }
    );
  }

  public removeFavoriteCell(
    id: string,
    cellType: string
  ): Observable<JsonResponse<any>> {
    return this.http.put<JsonResponse<any>>(
      `${environment.apiUrl}cells/favorite?favorite=false&geoId=${id}&cellType=${cellType}`,
      {}
    );
  }

  public getCellById(
    id: string,
    cellType: string
  ): Observable<JsonResponse<Feature['properties']>> {
    return this.http.get<JsonResponse<Feature['properties']>>(
      `${environment.apiUrl}features/?cellType=${cellType}&cellId=${id}&features=${arbitraryCellPopupFeatures}`
    );
  }

  public getCrimeDataByOri(
    id: string
  ): Observable<JsonResponse<CrimeRateByYearsResponse>> {
    return this.http.get<JsonResponse<CrimeRateByYearsResponse>>(
      `${environment.apiUrl}features/crime/${id}`
    );
  }

  public downloadXlsx(id: NaturalId, cellType: string): Observable<any> {
    return this.http.get(
      `${this.xlsxDownloadURL}?cellId=${id}&cellType=${cellType}&features=${availableFeatures}`,
      { responseType: 'blob' }
    );
  }

  // public getAccessibleFeatures(): Observable<JsonResponse<AccessibleFeaturesIds>> {
  //   return this.http.get<JsonResponse<AccessibleFeaturesIds>>(`${this.accessibleFeaturesURL}`)
  // }

  public getPoisGeoJson(
    ids: NaturalId[]
  ): Observable<JsonResponse<PoisFeatureCollection>> {
    return this.http.get<JsonResponse<PoisFeatureCollection>>(
      `${this.poisGeoJSONURL}?h3R7ID=${ids}`
    );
  }

  public getSchoolsPoisData(
    ids: string[]
  ): Observable<JsonResponse<SchoolsPoisData>> {
    return this.http.get<JsonResponse<SchoolsPoisData>>(
      `${this.schoolsPoisDataURL}/?schoolIds=${ids}`
    );
  }

  public getTrafficIntensityChartData(
    id: NaturalId
  ): Observable<JsonResponse<TrafficIntensityBackend>> {
    return this.http
      .get<
        JsonResponse<TrafficIntensityBackend>
      >(`${this.trafficIntensityURL}?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            `MapHttpService, COMPETITORS_CHART_DATA: ${id} fetching error: `,
            err
          );
          return throwError(err);
        })
      );
  }

  public getPoisChartData(
    id: NaturalId
  ): Observable<JsonResponse<PoisChartJSON>> {
    return this.http
      .get<
        JsonResponse<PoisChartJSON>
      >(`${this.pointsOfInterestDataURL}?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            `MapHttpService, COMPETITORS_CHART_DATA: ${id} fetching error: `,
            err
          );
          return throwError(err);
        })
      );
  }

  public getBulkMapColoringData(
    ids: NaturalId[],
    layer: string,
    level: string
  ) {
    // sort to hint the cache no matter of ids order
    // unique to avoid unnecessary duplicates requests
    const uniqueAndSortedIds = [...new Set(ids)];
    return this.http
      .post<
        JsonResponse<ByCellsJSON>
      >(`${this.bulkColoringDataURL}?cellType=${level}&availableBulks=${layer}`, { naturalIds: uniqueAndSortedIds })
      .pipe(
        catchError((err) => {
          console.error(
            `MapHttpService, BULK_COLORING_DATA: ${layer} fetching error: `,
            err
          );
          return throwError(err);
        })
      );
  }

  public getAgeSexData(
    id: NaturalId
  ): Observable<JsonResponse<AgeSexDataJSON[]>> {
    return this.http
      .get<
        JsonResponse<AgeSexDataJSON[]>
      >(`${environment.apiUrl}${this.ageSexURL}?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error('MapHttpService, AGE_SEX fetching error: ', err);
          return throwError(err);
        })
      );
  }

  public getRoadDensityData(
    id: NaturalId
  ): Observable<JsonResponse<RoadLengthBackend>> {
    return this.http
      .get<
        JsonResponse<RoadLengthBackend>
      >(`${environment.apiUrl}features/traffic/road-density/?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error('MapHttpService, ROAD_DENSITY fetching error: ', err);
          return throwError(err);
        })
      );
  }

  public getPersonalIncomeDistributionData(
    id: NaturalId
  ): Observable<JsonResponse<PersonalDistributionJSON[]>> {
    return this.http
      .get<
        JsonResponse<PersonalDistributionJSON[]>
      >(`${environment.apiUrl}${this.incomeURL}?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, INCOME_DISTRIBUTION fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getHouseholdsIncomeDistributionData(
    id: NaturalId
  ): Observable<JsonResponse<HouseholdDistributionJSON[]>> {
    return this.http
      .get<
        JsonResponse<HouseholdDistributionJSON[]>
      >(`${environment.apiUrl}${this.incomeURL}/households?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, INCOME_DISTRIBUTION fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getRaceDistributionData(
    id: NaturalId
  ): Observable<JsonResponse<RacePopulationByYear>> {
    return this.http
      .get<
        JsonResponse<RacePopulationByYear>
      >(`${environment.apiUrl}features/demography/population-by-race?naturalId=${id}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, RACE_DISTRIBUTION fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getTargetAudienceData(
    ids: NaturalId[],
    industry: string
  ): Observable<JsonResponse<TargetAudienceBackend>> {
    const cellType =
      ids[0] === 'usa' ? 'COUNTRY' : this.selectedCellService.getCellType();
    return this.http
      .post<
        JsonResponse<TargetAudienceBackend>
      >(`${environment.apiUrl}${this.targetAudienceURL}?industry=${String(industry)}&cellType=${cellType}`, { naturalIds: ids })
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, TARGET_AUDIENCE fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getVotersData(
    ids: NaturalId[],
    year: number
  ): Observable<JsonResponse<{ byCells: VotersDataByCells[] }>> {
    return this.http.post<JsonResponse<{ byCells: VotersDataByCells[] }>>(
      `${
        environment.apiUrl
      }features/elections/president-elections-by-year?cellType=${this.selectedCellService.getCellType()}&year=${year}`,
      { naturalIds: ids }
    );
  }

  public getSenateElectionData(
    ids: NaturalId[]
  ): Observable<JsonResponse<{ byCells: SenateElectionByCells[] }>> {
    return this.http.post<JsonResponse<{ byCells: SenateElectionByCells[] }>>(
      `${
        environment.apiUrl
      }features/elections/senate-election?cellType=${this.selectedCellService.getCellType()}`,
      { naturalIds: ids }
    );
  }

  public getHouseOfRepresentativesElectionData(
    ids: NaturalId[]
  ): Observable<
    JsonResponse<{ byCells: HouseOfRepresentativesElectionByCells[] }>
  > {
    return this.http.post<
      JsonResponse<{ byCells: HouseOfRepresentativesElectionByCells[] }>
    >(
      `${
        environment.apiUrl
      }features/elections/house-of-representatives-election?cellType=${this.selectedCellService.getCellType()}`,
      { naturalIds: ids }
    );
  }

  public getEducationAttainmentData(
    id: NaturalId,
    year: number
  ): Observable<JsonResponse<EducationDistributionJSON>> {
    const cellType =
      id === 'usa' ? 'COUNTRY' : this.selectedCellService.getCellType();
    return this.http
      .get<
        JsonResponse<EducationDistributionJSON>
      >(`${environment.apiUrl}${this.educationAttainmentURL}?naturalId=${id}&year=${year}&cellType=${cellType}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, EDUCATION_ATTAINMENT fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getEmploymentData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<PersonalDistributionJSON>
      >(`${environment.apiUrl}${this.employmentURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error('MapHttpService, EMPLOYMENT fetching error: ', err);
          return throwError(err);
        })
      );
  }

  public getHomeOwnershipVacancyData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HomeownershipJSON>
      >(`${environment.apiUrl}${this.homeOwnershipVacancyURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, HOME_OWNERSHIP_OCCUPATION fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getHomeOwnershipTenureData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HomeownershipJSON>
      >(`${environment.apiUrl}${this.homeOwnershipTenureURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, HOME_OWNERSHIP_TENURE fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getNumberOfRoomsData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HomeownershipJSON>
      >(`${environment.apiUrl}${this.numberOfRoomsURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, NUMBER_OF_ROOMS fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getGrossRentData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HomeownershipJSON>
      >(`${environment.apiUrl}${this.grossRentURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error('MapHttpService, GROSS_RENT fetching error: ', err);
          return throwError(err);
        })
      );
  }

  public getHousingSellCostData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HomeownershipJSON>
      >(`${environment.apiUrl}${this.housingSellCostURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error(
            'MapHttpService, HOUSING_SELL_COST fetching error: ',
            err
          );
          return throwError(err);
        })
      );
  }

  public getInsuranceData(id: NaturalId, year: number) {
    return this.http
      .get<
        JsonResponse<HealthInsuranceJSON>
      >(`${environment.apiUrl}${this.insuranceURL}?naturalId=${id}&year=${year}&cellType=${this.selectedCellService.getCellType()}`)
      .pipe(
        catchError((err) => {
          console.error('MapHttpService, INSURANCE fetching error: ', err);
          return throwError(err);
        })
      );
  }

  public getComparisonData(naturalId: string, features: string[]) {
    return this.http
      .get<
        JsonResponse<ComparisonDataJSON>
      >(`${this.comparisonDataURL}?cellId=${naturalId}&cellType=${this.selectedCellService.getCellType()}&features=${features}`)
      .pipe(
        catchError((err) => {
          console.error(
            `MapHttpService, COMPARISON_DATA: ${features} fetching error: `,
            err
          );
          return throwError(err);
        })
      );
  }

  public getDataForSingleCell(
    naturalId: string,
    cellType: string,
    features: string[]
  ): Observable<JsonResponse<Feature['properties']>> {
    return this.http
      .get<
        JsonResponse<Feature['properties']>
      >(`${environment.apiUrl}features?cellId=${naturalId}&cellType=${cellType}&features=${features}`)
      .pipe(
        catchError((err) => {
          console.error(
            `MapHttpService, getDataForSingleCell: ${naturalId}, ${features} fetching error`,
            err
          );
          return throwError(err);
        })
      );
  }
}
