<div class="search-container">
  <mat-button-toggle
    *ngIf="!isSearchInputActive"
    (click)="handleSearchOpenButton()"
    class="search-open-button"
  >
    <app-icon svgIcon="searchIcon"></app-icon>
  </mat-button-toggle>

  <div class="search-history-wrapper" *ngIf="geocoderService.isListVisible && geocoderService.searchHistoryList.length > 0">
    <div class="scrollable-content">
    <span
    *ngFor="let item of geocoderService.searchHistoryList"
    (click)="handleJumpToAddress(item)"
    class="history-search-item"
  >
    {{ item.address }}
    <button class="remove-button" (click)="handleRemove($event, item.id)">
      <img src="assets/icons/Cancel.svg" alt="Remove address from search history">
    </button>
    </span>
    </div>
  </div>

</div>

