import { Component, Input, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup.service';
import { SchoolsPoiProperties } from '../../../menu/right-menu/layers-menu/education/education.service';
import { CrimeRateByYear } from '../../../menu/right-menu/layers-menu/points.service';
import { Feature } from 'geojson';
import {
  H3_RES5_LEVEL_LAYER,
  LayerStoreService,
} from '../../services/layer-store.service';
import { SelectedCellService } from '../../services/selected-cell.service';
import {
  FavoriteCell,
  FavoritesService,
} from '../../../favorites/favorites.service';
import { UserAccessService } from '../../../../user/access/user-access.service';
import { featuresToCompareToNationalPopup } from '../../../../shared/types/feature-data-type';
import { MapHttpService } from '../../services/map-http.service';
import { MapUrlService } from '../../services/map-url.service';
import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { LevelsPopupListComponent } from './layers-popup-list/levels-popup-list.component';
import { SortPropertiesPipe } from './sort-properties.pipe';
import { SchoolsPopupListComponent } from './schools-popup-list/schools-popup-list.component';
import { OriPopupListComponent } from './ori-popup-list/ori-popup-list.component';
import { MatTooltip } from '@angular/material/tooltip';
import { FavoriteIconComponent } from '../../../../shared/components/favorite-icon/favorite-icon.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../../../shared/directives/icon-button.directive';
import { FeatureTooltipComponent } from '../../../menu/right-menu/layers-menu/shared/feature-tooltip/feature-tooltip.component';
import { ModalService } from '../../../../shared/services/modal.service';
import { MapModalData } from '../../../../user/map-redirect-modal/map-redirect-modal.component';

export const DEFAULT_POPUP = 'DEFAULT_POPUP';
export const SCHOOLS_POPUP = 'SCHOOLS_POPUP';
export const ORI_POPUP = 'ORI_POPUP';
export const HOVER_POPUP = 'HOVER_POPUP';

export type PopupData = {
  type:
    | typeof DEFAULT_POPUP
    | typeof SCHOOLS_POPUP
    | typeof ORI_POPUP
    | typeof HOVER_POPUP;
  properties: Feature['properties'] | SchoolsPoiProperties | CrimeRateByYear;
};

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  imports: [
    NgIf,
    AsyncPipe,
    NgClass,
    IconComponent,
    NgSwitch,
    LevelsPopupListComponent,
    NgSwitchCase,
    SortPropertiesPipe,
    SchoolsPopupListComponent,
    OriPopupListComponent,
    MatTooltip,
    FavoriteIconComponent,
    IconButtonDirective,
    FeatureTooltipComponent,
  ],
  styleUrls: ['./map-popup.component.scss', '../../../../app.component.scss'],
})
export class MapPopupComponent implements OnInit {
  @Input() data!: PopupData;

  public title!: string;

  public isUnauthorized!: boolean;

  constructor(
    private selectedCellService: SelectedCellService,
    public accessService: UserAccessService,
    private http: MapHttpService,
    public popupService: PopupService,
    public layerStore: LayerStoreService,
    public favoritesService: FavoritesService,
    private urlService: MapUrlService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.isUnauthorized = this.accessService.getIsUnauthorized();
    if (this.data.type !== HOVER_POPUP) {
      this.popupService.handlePopupTitle();
      this.loadWrittenInStateFeatures(
        this.selectedCellService.getSelectedCellGeoId()!
      );
    }
  }

  public handleClose(): void {
    this.popupService.handleClose();
  }

  public toggleSeeMore(): void {
    this.popupService.toggleSeeMore();
  }

  public handleDownloadXlsx(): void {
    this.popupService.downloadXlsx();
  }

  public handleRequestReport(): void {
    this.popupService.handleRequestReport();
  }

  public handleAreaDescriptionClick(): void {
    this.popupService.openCellDescriptionModal(this.isUnauthorized);
  }

  public handleShare(): void {
    this.urlService.copyUrlToClipboard();
  }

  public handleAddToFavorites(): void {
    this.popupService.handleAddToFavorites();
  }

  public handleRemoveFromFavorites(): void {
    this.popupService.handleRemoveFromFavorites();
  }

  public isFeatureFavorite(): boolean {
    return !!this.favoritesService.favoritePlaces.find(
      (el: FavoriteCell) =>
        el.geoId === this.selectedCellService.getSelectedCellGeoId()
    );
  }

  public handleTooltipModalOpen(modalData: MapModalData): void {
    this.modalService.openModal(modalData);
  }

  private loadWrittenInStateFeatures(id: string) {
    this.http
      .getDataForSingleCell(
        id,
        this.selectedCellService.getCellType()!,
        featuresToCompareToNationalPopup
      )
      .subscribe((data) => {
        this.data.properties = { ...this.data.properties, ...data.object };
      });
  }

  protected readonly ORI_POPUP = ORI_POPUP;
  protected readonly SCHOOLS_POPUP = SCHOOLS_POPUP;
  protected readonly DEFAULT_POPUP = DEFAULT_POPUP;
  protected readonly HOVER_POPUP = HOVER_POPUP;
  protected readonly H3_RES5_LEVEL_LAYER = H3_RES5_LEVEL_LAYER;
}
