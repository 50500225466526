import { Injectable } from '@angular/core';
import { Feature } from 'geojson';
import {
  PopulationSamples,
  PopulationSamplesByYears,
  Sex,
} from '../../../../../shared/model/population';
import { mapAgeSexDistribution } from '../../../../../shared/model/population-mapper';
import { range } from '../../../../../shared/util/arrays';
import { AgeRange } from '../../../../../shared/model/range';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import { catchError, of } from 'rxjs';
import {
  formatChartRangesAndHoverNumber,
  formatNumber,
  pieChartMargins,
} from '../shared/chart-utility';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { Data, Layout } from 'plotly.js-dist-min';

export interface RacePopulationByYear {
  racePopulationByYear: {
    year: number;
    racePopulation: {
      [key: string]: number;
    };
  }[];
}

export interface AgeSexDataJSON {
  year: number;
  sex: Sex;
  ageFrom: number;
  ageTo: number;
  population: number;
}

export interface ChartDataByYear {
  [year: number]: {
    data: Data;
    layout: Layout;
  };
}

export interface XAxisData {
  title: string;
  range: number[];
  tickvals: number[];
  ticktext: (string | number)[];
  tickangle: number;
}

export interface FeatureStatus {
  featureId?: Feature['id'];
  naturalId?: NaturalId;
  isSelected: boolean;
}

export interface PopulationFeatureStatus extends FeatureStatus {
  containsAgeSexInfo: boolean;
  ageSexYear: number;
  containsRaceDistribution: boolean;
  raceDistributionYear: number;
}

export const MALE_COLOR = '#01a5ff';
export const FEMALE_COLOR = '#8247ff';
export const ALL_COLOR = '#02A4FF';

export type NaturalId = string;

@Injectable({
  providedIn: 'root',
})
export class PopulationService {
  private readonly raceKeyToReadableName = new Map([
    ['OTHER', 'Some other race alone'],
    ['WHITE', 'White'],
    ['AMERICAN_INDIAN_AND_ALASKA', 'American Indian or Alaska Native'],
    ['TWO_OR_MORE', 'Two or more races'],
    ['BLACK', 'Black or African American'],
    ['ASIAN', 'Asian'],
    [
      'HAWAIIAN_AND_PACIFIC_ISLANDER',
      'Native Hawaiian or Other Pacific Islander',
    ],
  ]);

  private readonly colorsPool = [
    '#3B75AF',
    '#EF8636',
    '#519E3E',
    '#C53A32',
    '#8D69B8',
    '#EFB743',
    '#9CE0FC',
  ];

  public readonly ageSexAccessibleYears: number[] = [];

  public readonly raceDistributionAccessibleYears: number[] = [];

  public readonly featureStatus: PopulationFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsAgeSexInfo: false,
    ageSexYear: 2024,
    containsRaceDistribution: false,
    raceDistributionYear: 2022,
  };

  public populationTrendChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public readonly ageSexChartDataByYear: ChartDataByYear = {};

  public readonly raceDistributionDataByYear: ChartDataByYear = {};

  private readonly layoutLegend = {
    x: -0.15,
    y: 1.2,
    orientation: 'h',
  };

  private static readonly ageRangesPyramidAggregation: AgeRange[] = [
    new AgeRange(0, 6),
    ...range(5, 85, 5).map((i) => new AgeRange(i, i + 5)),
    new AgeRange(85, AgeRange.MAX_AGE),
  ];

  constructor(private http: MapHttpService) {}

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId = feature.properties!.external_id;

    this.featureStatus.naturalId = naturalId;
    this.featureStatus.isSelected = true;

    this.http.getAgeSexData(naturalId).subscribe(
      (data: JsonResponse<AgeSexDataJSON[]>) => {
        if (data.object) {
          const ageSexDistribution: PopulationSamples = mapAgeSexDistribution(
            data.object
          );

          this.ageSexAccessibleYears.length = 0;

          this.fillAgeSexPyramidData(ageSexDistribution, naturalId);

          this.fillPopulationTrendData(ageSexDistribution.byYears());
        } else {
          this.featureStatus.containsAgeSexInfo = false;
          return;
        }
      },
      (error: any) => {
        this.featureStatus.containsAgeSexInfo = false;
        console.error('ERROR FETCHING AGE SEX', error);
        return of(null);
      }
    );

    this.fillRaceDistributionChart(naturalId);
  }

  private fillRaceDistributionChart(id: NaturalId): void {
    this.http
      .getRaceDistributionData(id)
      .pipe(
        catchError((err) => {
          this.featureStatus.containsRaceDistribution = false;
          console.error('ERROR FETCHING RACE_DISTRIBUTION', err);
          return of(null);
        })
      )
      .subscribe((data) => {
        if (!data) return;

        this.raceDistributionAccessibleYears.length = 0;

        if (data?.object) {
          data.object.racePopulationByYear.forEach((el) => {
            const values: number[] = Object.values(el.racePopulation);
            const labels: string[] = Object.keys(el.racePopulation).map(
              (el) => {
                return this.raceKeyToReadableName.get(el)!;
              }
            );

            this.raceDistributionAccessibleYears.push(el.year);

            const maxValue = Math.max(...values);

            if (!maxValue || maxValue === -Infinity) {
              this.featureStatus.naturalId = id;
              this.featureStatus.containsRaceDistribution = false;
            } else {
              this.featureStatus.naturalId = id;
              this.featureStatus.containsRaceDistribution = true;
            }

            const layout = {
              autosize: true,
              font: {
                family: 'Rising Sun, sans-serif',
              },
              height: 500,
              width: 450,

              margin: pieChartMargins,
              legend: {
                orientation: 'h',
                width: '100%',
                y: 1.6,
                x: 0.03,
              },
              marker: {
                colors: this.colorsPool,
              },
            };

            this.raceDistributionDataByYear[el.year] = {
              data: [
                {
                  values: values,
                  labels: labels,
                  type: 'pie',
                  textposition: 'inside',
                  hoverinfo: 'label+percent+value',
                  hovertemplate:
                    '&nbsp;&nbsp;%{label}: %{customdata} (%{percent})&nbsp;&nbsp;<extra></extra>',
                  customdata: values.map((val) => formatNumber(val)),
                },
              ] as Data,
              layout: layout as any,
            };
          });
        }
      });
  }

  private fillPopulationTrendData(ageSex: PopulationSamplesByYears) {
    const yearsSums = ageSex.asArray().map(([year, population]) => {
      const totalSum = population.sum();
      return {
        year,
        totalSum,
      };
    });
    const years = yearsSums.map((d) => d.year).filter((year) => year !== 2009);
    const yBothSex = yearsSums.map((d) => d.totalSum);
    //
    const maxValue = Math.max(...yBothSex);
    const bottomValue = Math.min(...yBothSex) * 0.95;

    const barWidth = 0.5;

    const bothSexData = {
      x: years,
      y: yBothSex,
      name: 'All',
      type: 'bar',
      customdata: yBothSex.map(
        (population) => `${formatChartRangesAndHoverNumber(population)}`
      ),
      hovertemplate: `%{customdata}<extra></extra>`,
      marker: { color: ALL_COLOR },
      width: barWidth,
    };

    const populationTrendData = [bothSexData];

    const populationTrendLayout = {
      autosize: true,
      margin: {
        t: 30,
        r: 35,
      },
      xaxis: {
        title: 'Year',
        type: 'category',
      },
      yaxis: {
        title: 'Population',
        range: [bottomValue, maxValue],
      },
      font: {
        family: 'Rising Sun, sans-serif',
      },
    };

    this.populationTrendChart.data = populationTrendData as Data;
    this.populationTrendChart.layout = populationTrendLayout as Layout;
  }

  private fillAgeSexPyramidData(
    ageSex: PopulationSamples,
    naturalId: NaturalId
  ) {
    ageSex
      .byYears()
      .asArray()
      .forEach(([year, data]) => {
        const bothSex = data.populations().reduce((acc, cur) => acc + cur);

        const malePopulationData = data
          .filterSex(Sex.MALE)
          .sumInsideAgeRanges(PopulationService.ageRangesPyramidAggregation)
          .sortedByAge();

        const xMale = malePopulationData.populations();

        const malePercentages = malePopulationData
          .populations()
          .map((pop) => (pop / bothSex) * 100);

        const onlyFem = data
          .filterSex(Sex.FEMALE)
          .sumInsideAgeRanges(PopulationService.ageRangesPyramidAggregation)
          .sortedByAge();

        const femPopulation = onlyFem.populations();
        //make female values negative so that it will be shown beside male values
        const xFemale = femPopulation.map((x) => -x);
        const femalePercentages = femPopulation.map(
          (pop) => (pop / bothSex) * 100
        );

        if (
          year == 0 ||
          ![...xFemale, ...xMale].some((el) => Math.abs(el) >= 10)
        ) {
          this.featureStatus.featureId = naturalId;
          this.featureStatus.containsAgeSexInfo = false;
          return;
        } else {
          this.featureStatus.featureId = naturalId;
          this.featureStatus.containsAgeSexInfo = true;
        }

        //values to be shown on Y axis left from chart
        const y = onlyFem.uniqueAgeRangesStrings();
        this.ageSexAccessibleYears.push(year);

        const maleData = {
          y,
          x: xMale,
          type: 'bar',
          name: 'Male',
          orientation: 'h',
          customdata: xMale.map(
            (population, i) =>
              `${formatChartRangesAndHoverNumber(population)} (${malePercentages[i].toFixed(1)}%)`
          ), // append percentages to values
          hovertemplate: `%{customdata}<extra></extra>`,
          marker: { color: MALE_COLOR },
        };

        const femaleData = {
          y,
          x: xFemale,
          type: 'bar',
          name: 'Female',
          orientation: 'h',
          customdata: femPopulation.map(
            (population, i) =>
              `${formatChartRangesAndHoverNumber(population)} (${femalePercentages[i].toFixed(1)}%)`
          ),
          hovertemplate: `%{customdata}<extra></extra>`,
          marker: { color: FEMALE_COLOR },
        };

        const layout = {
          autosize: true,
          margin: {
            t: 30,
            r: 35,
          },
          xaxis: this.getXAxisData(xFemale, xMale),
          yaxis: {
            title: 'Age',
            //adjust gaps for y-axis keys
            dtick: 0.1,
          },
          legend: this.layoutLegend,
          barmode: 'overlay',
          bargap: 0.1,
          font: {
            family: 'Rising Sun, sans-serif',
          },
        } as Layout;

        this.ageSexChartDataByYear[year] = {
          data: [femaleData, maleData] as Data,
          layout,
        };
      });

    this.featureStatus.ageSexYear =
      this.ageSexAccessibleYears.sort()[this.ageSexAccessibleYears.length - 1];
  }

  private getXAxisData(females: number[], males: number[]): XAxisData {
    const maxPopulation: number = Math.max(...females, ...males);
    let maxPopulationCeiled: number;

    if (maxPopulation >= 3000) {
      maxPopulationCeiled = Math.ceil(maxPopulation / 500) * 500;
    } else if (maxPopulation >= 1500) {
      maxPopulationCeiled = Math.ceil(maxPopulation / 250) * 250;
    } else if (maxPopulation >= 750) {
      maxPopulationCeiled = Math.ceil(maxPopulation / 125) * 125;
    } else if (maxPopulation >= 375) {
      maxPopulationCeiled = Math.ceil(maxPopulation / 65) * 65;
    } else {
      maxPopulationCeiled = Math.ceil(maxPopulation / 20) * 20;
    }

    const range: number[] = [
      maxPopulationCeiled * -1.05,
      maxPopulationCeiled * 1.05,
    ];

    const tickvals: number[] = [
      maxPopulationCeiled,
      maxPopulationCeiled * 0.65,
      maxPopulationCeiled * 0.3,
      0,
      maxPopulationCeiled * -0.3,
      maxPopulationCeiled * -0.65,
      maxPopulationCeiled * -1,
    ].map((el) => Math.round(el / 10) * 10);

    //get absolute values to show in chart
    const ticktext: (string | number)[] = tickvals.map((el) => {
      const absoluteNumber = Math.abs(el);
      if (absoluteNumber >= 1000000) {
        return `${(absoluteNumber / 1000000).toFixed(2)}M`;
      } else if (absoluteNumber >= 10000) {
        return `${Math.round(absoluteNumber / 1000)}K`;
      } else if (absoluteNumber >= 1000) {
        if (absoluteNumber % 1000 === 0) return `${absoluteNumber / 1000}K`;
        return `${(absoluteNumber / 1000).toFixed(1)}K`;
      } else return Math.abs(el);
    });

    return {
      title: 'Population',
      range: range,
      tickvals: tickvals,
      ticktext: ticktext,
      tickangle: 0,
    };
  }
}
