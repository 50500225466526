import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
  host: {
    class: 'app-custom-checkbox',
    '[class.app-checkbox-checked]': 'checked',
    '[class.app-checkbox-disabled]': 'disabled',
    '[class.app-checkbox-non-interactive]': '!interactive',
    '[class.app-checkbox-primary]': 'color === "primary"',
    '[class.app-checkbox-accent]': 'color === "accent"',
    '[class.app-checkbox-warn]': 'color === "warn"',
    '(click)': 'interactive && _onInputClick($event)',
  },
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() interactive = true;
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() id: string =
    `app-checkbox-${Math.random().toString(36).substring(2)}`;
  @Input() required: boolean = false;
  @Input() name: string | null = null;
  @Input() ariaLabel: string | null = null;
  @Input() ariaLabelledby: string | null = null;
  @Input() tabIndex: number = 0;
  @Input() indeterminate: boolean = false;

  @Output() change = new EventEmitter<boolean>();

  private onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};

  // Animation state
  public rippleActive = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public _onInputClick(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }

    if (this.disabled || !this.interactive) {
      return;
    }

    this.checked = !this.checked;
    this.onChange(this.checked);
    this.change.emit(this.checked);

    // Trigger ripple effect
    this.rippleActive = true;
    setTimeout(() => {
      this.rippleActive = false;
      this.cdr.markForCheck();
    }, 300);
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.checked = !!value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
