import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FavoriteCell, FavoritesService } from './favorites.service';
import { MapBoxService } from '../mapbox/mapbox.service';
import { NgForOf, NgIf } from '@angular/common';
import { FavoriteIconComponent } from '../../shared/components/favorite-icon/favorite-icon.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  imports: [
    NgIf,
    FavoriteIconComponent,
    IconComponent,
    NgForOf,
    IconButtonDirective,
  ],
  styleUrls: ['./favorites.component.scss', '../../app.component.scss'],
})
export class FavoritesComponent implements OnInit {
  @Output() openFavorites = new EventEmitter<void>();
  @Output() closeFavorites = new EventEmitter<void>();

  @Input() isFavoritesVisible!: boolean;

  public hoveredId = '';

  public isDropdownOpened = false;

  constructor(
    public favoritesService: FavoritesService,
    private mapboxService: MapBoxService
  ) {}

  ngOnInit(): void {}

  public handleOpenFavorites(): void {
    this.openFavorites.emit();
  }

  public handleCloseFavorites(e: MouseEvent): void {
    e.stopPropagation();
    this.closeFavorites.emit();
  }

  public toggleDropdown(): void {
    this.favoritesService.updateFavoritesList();
    this.isDropdownOpened = !this.isDropdownOpened;
  }

  public handleRemoveFavorite(id: string, cellType: string): void {
    this.favoritesService.removeFavoriteCell(id, cellType);
  }

  public handleMoveToFavoriteCell(cell: FavoriteCell): void {
    this.isDropdownOpened = false;
    this.favoritesService.moveToFavoriteCellLocation(
      cell,
      this.mapboxService.map
    );
  }
}
