import { Injectable } from '@angular/core';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import {
  ByCellsJSON,
  FeatureMetaData,
} from '../../../../mapbox/visualization/map-coloring.service';

@Injectable({
  providedIn: 'root',
})
export class HealthWealthWiseService {
  constructor(private mapService: MapBoxService) {}

  public handleHwwFeatures(
    data: ByCellsJSON,
    naturalIdToFeatureMap: Map<string, FeatureMetaData>,
    formattedLayer: string
  ): void {
    const map = this.mapService.map;

    const suffixMap = {
      byState: '_by_state',
      byCountry: '_by_country',
      absolute: '_absolute',
    };

    data.byCells.forEach((cell) => {
      const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);
      if (correspondingFeature) {
        if (!!cell.value && typeof cell.value === 'object') {
          for (let valueKey in cell.value as Object) {
            const value = cell.value[valueKey];
            const layerName = formattedLayer + (suffixMap as any)[valueKey];

            map.setFeatureState(correspondingFeature, { [layerName]: value });
          }
        } else {
          for (let valueKey in cell.value as Object) {
            const layerName = formattedLayer + (suffixMap as any)[valueKey];
            map.setFeatureState(correspondingFeature, { [layerName]: null });
          }
        }
      }
    });
  }
}
