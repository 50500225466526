import { Injectable } from '@angular/core';
import {
  MapModalData,
  MapRedirectModalComponent,
} from '../../user/map-redirect-modal/map-redirect-modal.component';
import { SubscriptionConfirmationModalComponent } from '../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-confirmation-modal.component';
import { SubscriptionPlans } from '../../user/user/user.model';
import { LANDSCAPE_MOBILE } from './device-orientation.service';
import { SwitchToPortraitComponent } from '../components/switch-to-portrait-modal/switch-to-portrait.component';
import { SubscriptionRenewalModalComponent } from '../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-renewal-modal/subscription-renewal-modal.component';
import { FavoritePersonalizationModalComponent } from '../../map/favorites/favorite-personalization-modal/favorite-personalization-modal.component';
import { AiDescriptionModalComponent } from '../components/ai-description-modal/ai-description-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public isAIDescriptionModalOpened = false;
  public isModalOpen = false;

  constructor(private dialog: MatDialog) {}

  //TODO: Extend this service to open other modals too
  public openModal(text: MapModalData): void {
    // Check if modal is already opened otherwise it could open in multiple instances
    if (!this.isModalOpen) {
      this.isModalOpen = true; // Set the status to open
      const dialogRef = this.dialog.open(MapRedirectModalComponent, {
        panelClass: 'custom-dialog-container',
        disableClose: text.disableClose,
        hasBackdrop: text.hasBackdrop,
        maxWidth: '620px',
        data: text,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.isModalOpen = false;
      });
    }
  }

  public openCellAIDescriptionModal(
    geoId: string,
    level: string,
    title: string
  ): void {
    if (!this.isAIDescriptionModalOpened) {
      const dialogRef = this.dialog.open(AiDescriptionModalComponent, {
        panelClass: 'custom-dialog-container',
        disableClose: false,
        hasBackdrop: false,
        maxWidth: '620px',
        data: { geoId, level, title },
      });

      dialogRef.afterClosed().subscribe(() => {
        this.isAIDescriptionModalOpened = false;
      });

      dialogRef.afterOpened().subscribe(() => {
        this.isAIDescriptionModalOpened = true;
      });
    }
  }

  public closeCellAiDescriptionModal(): void {
    if (this.isAIDescriptionModalOpened) {
      this.dialog.closeAll();
    }
  }
  // public handleLimitedAccessModal(focusedFeatures: FocusedFeatures, zoom: number): void {
  //   const accessibleFeatures = this.subscriptionsService.getAccessibleFeatures().features;
  //   const focusedFeaturesArray = focusedFeatures.getFeatures();
  //
  //   const existsInFocusedFeatures = accessibleFeatures.some(
  //     accessibleFeature => focusedFeaturesArray.find(
  //       focusedFeature => focusedFeature.properties!.external_id === accessibleFeature
  //     )
  //   );
  //
  //   if (!existsInFocusedFeatures || zoom < 9) {
  //     this.openModal(moveTrialUserToAtlantaText)
  //   }
  // }

  public handleSubscriptionConfirmationModal(
    plan: SubscriptionPlans,
    isPeriodMonthly: boolean,
    freePeriodString: string | null,
    promoCode: string | null
  ): void {
    this.dialog.open(SubscriptionConfirmationModalComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      data: {
        plan: plan,
        isPeriodMonthly: isPeriodMonthly,
        freePeriodString: freePeriodString,
        promoCode: promoCode,
      },
    });
  }

  public handleSubscriptionRenewalModal(isAutoRenewable: boolean): void {
    this.dialog.open(SubscriptionRenewalModalComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      data: {
        isAutoRenewable: isAutoRenewable,
      },
    });
  }

  public handleSwitchLandscapeModal(orientation: string): void {
    if (orientation === LANDSCAPE_MOBILE) {
      this.dialog.open(SwitchToPortraitComponent, {
        panelClass: 'custom-dialog-container',
        disableClose: false,
        maxWidth: '620px',
        maxHeight: '180px',
        data: {
          title: 'Rotate Your Device',
          text: 'Please rotate your device to portrait mode',
          closable: true,
        },
      });
    }
  }

  public handleFavoriteCellPersonalizationModal(
    id: string,
    title: string,
    level: string
  ): void {
    this.dialog.open(FavoritePersonalizationModalComponent, {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      maxHeight: '510px',
      data: { id, title: title, level: level },
    });
  }
}
