import { Injectable } from '@angular/core';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { MapBoxService } from '../../../mapbox.service';

export const MULTIPLE_SELECTED_POLYGONS = 'MULTIPLE_SELECTED_POLYGONS';

@Injectable({
  providedIn: 'root',
})
export class MultipleFeaturesSelectionService {
  public selectedFeatures: MapboxGeoJSONFeature[] = [];

  public isSelectionActive = false;

  constructor(private mapboxService: MapBoxService) {}

  public selectFeature(feature: MapboxGeoJSONFeature): void {
    if (this.selectedFeatures.find((el) => el.id === feature.id)) {
      this.deselectFeature(feature);
    } else {
      this.selectedFeatures.push(feature);
    }

    this.outlineSelectedFeature();
  }

  public deselectFeature(feature: MapboxGeoJSONFeature): void {
    this.selectedFeatures = this.selectedFeatures.filter(
      (el) => el.id !== feature.id
    );

    this.outlineSelectedFeature();
  }

  public deselectAllFeatures(): void {
    this.selectedFeatures.length = 0

    if (this.mapboxService.map.getLayer(MULTIPLE_SELECTED_POLYGONS)) {
      this.mapboxService.map.removeLayer(MULTIPLE_SELECTED_POLYGONS);
      this.mapboxService.map.removeSource(MULTIPLE_SELECTED_POLYGONS);
    }
  }

  private outlineSelectedFeature(): void {
    const map = this.mapboxService.map;
    const featureCollection = {
      type: 'FeatureCollection',
      features: this.selectedFeatures,
    };

    const source = map.getSource(MULTIPLE_SELECTED_POLYGONS) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(featureCollection as any);
    } else {
      map.addLayer({
        id: MULTIPLE_SELECTED_POLYGONS,
        type: 'line',
        source: {
          type: 'geojson',
          data: featureCollection as any,
        },
        paint: {
          'line-color': '#35BB42',
          'line-width': 3,
        },
      });
    }

    this.mapboxService.map.once('sourcedata', () => {
      this.mapboxService.map.moveLayer(MULTIPLE_SELECTED_POLYGONS);
    })
  }
}
