import { Injectable } from '@angular/core';
import { LngLat } from 'mapbox-gl';
import { ArbitraryCellService } from '../map/mapbox/services/arbitrary-cell.service';
import circle from '@turf/circle';
import { Feature, Polygon } from 'geojson';
import { WKT } from '../reports/order-report/choose-location/choose-location-map/radius-selection.service';
import { geojsonToWKT } from '@terraformer/wkt';
import { catchError, of, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MapHttpService } from '../map/mapbox/services/map-http.service';
import { ARBITRARY_CELL } from '../map/mapbox/services/layer-store.service';
import {
  AREA,
  availableFeatures,
  BIDEN_VS_TRUMP_2020_POPULATION,
  COMBINED_HWW_CONST,
  CUMULATIVE_TRAFFIC_INTENSITY,
  HARRIS_VS_TRUMP_2024_POPULATION,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  ROAD_DENSITY,
  SENATE_ELECTION_2022_RESULTS,
  targetAudienceIndustries,
} from '../shared/types/feature-data-type';
import { Router } from '@angular/router';
import { MultipleFeaturesSelectionService } from '../map/mapbox/visualization/selection-tool/multiple-features-selection/multiple-features-selection.service';
import { convertLayerIdToCellType } from '../shared/util/layerIdToCellType';

export interface AddressToCoordinates {
  name: string;
  coordinates: LngLat;
}

export type ComparisonType = 'GEOCODER' | 'MULTIPLE_FEATURES_SELECTION';

export const excludedFromComparisonFeatures = [
  // Features without a clear way of how to present them to user
  'HOUSE_OF_REPRESENTATIVES_ELECTION',
  'SENATE_ELECTION',
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  SENATE_ELECTION_2022_RESULTS,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  ROAD_DENSITY,
  CUMULATIVE_TRAFFIC_INTENSITY,
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,

  // Features that are inherently not comparable as they are
  AREA,
  'ROAD_LENGTH',
  'POPULATION_BY_YEARS',
  'HOME_OWNERSHIP',
  'INSURANCE',
  'EMPLOYMENT',
  'HOUSING_GROSS_RENT',
  'POINTS_OF_INTEREST',
  'HOUSING_SALE_COST',
  'HOUSING_SALE_COST_MEDIANS_BY_YEARS',
  'HOUSEHOLD_INCOME_MEDIAN_BY_YEARS',
  'HOUSING_ROOMS',
  'TRAFFIC_INTENSITY',
  'HOUSEHOLDS_INCOME',
  'HOUSING_GROSS_RENT_MEDIANS_BY_YEARS',
  'EDUCATION_ATTAINMENT',
  'INCOME_DISTRIBUTION',
  'HOUSING_AGGREGATED_PRICE',
  'AGE_SEX',
];

@Injectable({
  providedIn: 'root',
})
export class ComparativeLocationService {
  private featuresUpdated = new Subject<void>();
  public featuresToCompareMap$ = this.featuresUpdated.asObservable();

  public currentComparisonType!: ComparisonType;

  public featuresToCompareMap = new Map<string, Feature['properties']>();
  public isCompareBeingProcessed: boolean = false;

  private featuresToRequest = availableFeatures.filter((feature) => {
    return !excludedFromComparisonFeatures.includes(feature);
  });

  constructor(
    private arbitraryCellService: ArbitraryCellService,
    private mapHttpService: MapHttpService,
    private multipleFeatureSelectionService: MultipleFeaturesSelectionService,
    private router: Router,
    private toast: ToastrService
  ) {}

  public handleCompareLocations(addresses: AddressToCoordinates[]): void {
    this.featuresToCompareMap.clear();
    this.isCompareBeingProcessed = true;

    this.currentComparisonType = 'GEOCODER';

    addresses.forEach((address) => {
      const center = address.coordinates;
      const feature = circle([center.lng, center.lat], 3, {
        steps: 65,
        units: 'miles',
      });
      this.createArbitraryCell(feature, address.name);
    });
  }

  private createArbitraryCell(circle: Feature<Polygon>, address: string): void {
    const wkt: WKT = geojsonToWKT(circle.geometry)
      .replace('POLYGON', 'MULTIPOLYGON')
      .replace(/(\(\()([^()]+)(\)\))/, '((($2)))');

    this.arbitraryCellService
      .requestCreateArbitraryCell(wkt, 'comparative location')
      .subscribe((data) => {
        const id = data.object.id;

        this.arbitraryCellService
          .requestIndexArbitraryCell(id)
          .pipe(
            catchError((err) => {
              this.toast.error('Error while fetching comparison data');
              return of(null);
            })
          )
          .subscribe((data) => {
            if (!data || !data.object) return;
            this.setFeaturesData(id, ARBITRARY_CELL, address);
          });
      });
  }

  public setFeaturesData(id: string, layerId: string, address: string): void {
    this.mapHttpService
      .getDataForSingleCell(id, layerId, this.featuresToRequest)
      .subscribe((data) => {
        this.setData(address, data.object);

        this.notifyUpdates();
      });
  }

  private notifyUpdates() {
    this.featuresUpdated.next();
  }

  private setData(address: string, props: Feature['properties']): void {
    const formattedProps = this.formatData(props);
    this.featuresToCompareMap.set(address, formattedProps);

    this.isCompareBeingProcessed = false;
  }

  private formatData(props: Feature['properties']): Feature['properties'] {
    const featuresToUnwrap = [...targetAudienceIndustries, 'TARGET_AUDIENCE'];

    const formattedData: Feature['properties'] = {};

    for (let prop in props) {
      if (!featuresToUnwrap.includes(prop)) {
        formattedData[prop] = props[prop];
      } else {
        this.unwrapFeature(formattedData, props, prop);
      }
    }

    this.handleHwwFeatures(formattedData, props);

    return formattedData;
  }

  private unwrapFeature(
    formattedData: Feature['properties'],
    props: Feature['properties'],
    prop: string
  ): any {
    if (props && formattedData) {
      if (prop === 'TARGET_AUDIENCE') {
        targetAudienceIndustries.forEach((industry) => {
          formattedData[industry] = props['TARGET_AUDIENCE'][industry];
        });
      }
    }
  }

  private handleHwwFeatures(
    formattedData: Feature['properties'],
    props: Feature['properties']
  ): void {
    const hwwFeatures = ['WEALTH_INDEX', 'HEALTH_INDEX', 'WISE_INDEX'];

    formattedData![COMBINED_HWW_CONST] =
      hwwFeatures.reduce((total, key) => {
        const value = props![key].byCountry;
        formattedData![key] = props![key].byCountry;

        return total + (value ?? 0);
      }, 0) / hwwFeatures.length;
  }

  public handleCompareRedirect(): void {
    this.featuresToCompareMap.clear();

    this.multipleFeatureSelectionService.selectedFeatures.forEach((feature) => {
      this.setFeaturesData(
        feature.properties!.external_id,
        convertLayerIdToCellType(feature.layer.id)!,
        feature.properties!.addressToCompare!
      );
    });

    this.currentComparisonType = 'MULTIPLE_FEATURES_SELECTION';

    this.router.navigate(['/comparative-location-tool']);
  }
}
