import { Injectable } from '@angular/core';
import { FeatureStatus } from '../population-menu/population.service';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import { catchError, of } from 'rxjs';
import { RentRange } from '../../../../../shared/model/range';
import {
  formatChartRangesAndHoverNumber,
  getPercents,
  sortKeys,
} from '../shared/chart-utility';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { Data, Layout } from 'plotly.js-dist-min';

export interface HomeownershipJSON {
  distribution:
    | HomeownershipVacancy[]
    | HomeownershipTenure[]
    | NumberOfRooms[]
    | GrossRent[]
    | HousingSellCost[];
}

export interface HomeownershipVacancy {
  occupancy: boolean;
  housingUnits: number;
}

export interface HomeownershipTenure {
  occupiedBy: string;
  housingUnits: number;
}

export interface NumberOfRooms {
  numberOfRooms: number;
  housingUnits: number;
}

export interface GrossRent {
  rentCostFrom: number;
  rentCostTo: number;
  housingUnits: number;
}

export interface HousingSellCost {
  costFrom: number;
  costTo: number;
  housingUnits: number;
}

enum TypeOfHomeownershipData {
  GROSS_RENT = 'GrossRent',
  HOUSING_SELL_COST = 'HousingSellCost',
}

export interface HomeownershipFeatureStatus extends FeatureStatus {
  vacancyYear: number;
  tenureYear: number;
  numberOfRoomsYear: number;
  grossRentYear: number;
  housingSellCostYear: number;
  containsHomeownershipTenure: boolean;
  containsHomeownershipVacancy: boolean;
  containsNumberOfRooms: boolean;
  containsGrossRent: boolean;
  containsHousingSellCost: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HomeownershipService {
  public readonly accessibleYears: number[] = [
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
    2022,
  ];

  //TODO: Check every once in a while, maybe other years are accessible now
  public readonly housingSaleCostAccessibleYears: number[] = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  ];

  public grossRentAccessibleYears: number[] = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  ];

  private static readonly householdsRentRangesRanges: RentRange[] = [
    // new RentRange(0, 250),
    // new RentRange(250, 500),
    // new RentRange(500, 750),
    // new RentRange(750, 1000),
    // new RentRange(1000, 1250),
    // new RentRange(1250, 1500),
    // new RentRange(1500, 1750),
    // new RentRange(1750, 2000),
    // new RentRange(2000, 2500),
    // new RentRange(2500, 3000),
    // new RentRange(3000, 3500),
    // new RentRange(3500, RentRange.MAX_RENT),

    //TODO: switch to commented ranges
    new RentRange(0, 250),
    new RentRange(250, 500),
    new RentRange(500, 750),
    new RentRange(750, 1000),
    new RentRange(1000, 1250),
    new RentRange(1250, 1500),
    new RentRange(1500, 2000),
    new RentRange(2000, 2500),
    new RentRange(2500, 3000),
    new RentRange(3000, 3500),
    new RentRange(3500, RentRange.MAX_RENT),
  ];

  private static readonly housingSellCostRanges: RentRange[] = [
    new RentRange(0, 10000),
    new RentRange(10000, 50000),
    new RentRange(50000, 100000),
    new RentRange(100000, 200000),
    new RentRange(200000, 300000),
    new RentRange(300000, 400000),
    new RentRange(400000, 500000),
    new RentRange(500000, 750000),
    new RentRange(750000, 1000000),
    new RentRange(1000000, 1500000),
    new RentRange(1500000, 2000000),
    new RentRange(2000000, RentRange.MAX_RENT),
  ];

  public readonly featureStatus: HomeownershipFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsHomeownershipTenure: false,
    containsHomeownershipVacancy: false,
    containsNumberOfRooms: false,
    containsGrossRent: false,
    containsHousingSellCost: false,
    vacancyYear: 2022,
    tenureYear: 2022,
    numberOfRoomsYear: 2022,
    grossRentYear: 2022,
    housingSellCostYear: 2022,
  };

  public readonly homeownershipVacancyChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };
  public readonly homeownershipTenureChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };
  public readonly numberOfRoomsChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };
  public readonly grossRentChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };
  public readonly housingSellCostChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };

  constructor(private http: MapHttpService) {}

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId: string = feature.properties!.external_id;

    this.http
      .getHomeOwnershipVacancyData(naturalId, this.featureStatus.vacancyYear)
      .subscribe((data: JsonResponse<HomeownershipJSON>) => {
        this.featureStatus.containsHomeownershipVacancy = true;
        this.featureStatus.naturalId = naturalId;
        this.featureStatus.isSelected = true;

        this.fillVacancyChart(
          data.object.distribution as HomeownershipVacancy[]
        );
      });
    catchError((err) => {
      this.featureStatus.isSelected = true;
      this.featureStatus.naturalId = naturalId;
      this.featureStatus.containsHomeownershipVacancy = false;
      console.error('ERROR FETCHING HOMEOWNERSHIP OCCUPATION', err);
      return of(null);
    });

    this.http
      .getHomeOwnershipTenureData(naturalId, this.featureStatus.tenureYear)
      .subscribe((data: JsonResponse<HomeownershipJSON>) => {
        this.featureStatus.containsHomeownershipTenure = true;
        this.featureStatus.naturalId = naturalId;
        this.featureStatus.isSelected = true;

        this.fillTenureChart(data.object.distribution as HomeownershipTenure[]);
      });
    catchError((err) => {
      this.featureStatus.isSelected = true;
      this.featureStatus.naturalId = naturalId;
      this.featureStatus.containsHomeownershipTenure = false;
      console.error('ERROR FETCHING HOMEOWNERSHIP TENURE', err);
      return of(null);
    });

    this.http
      .getNumberOfRoomsData(naturalId, this.featureStatus.numberOfRoomsYear)
      .subscribe((data: JsonResponse<HomeownershipJSON>) => {
        this.featureStatus.containsNumberOfRooms = true;
        this.featureStatus.naturalId = naturalId;
        this.featureStatus.isSelected = true;

        this.fillNumberOfRoomsChart(
          data.object.distribution as NumberOfRooms[]
        );
      });
    catchError((err) => {
      this.featureStatus.isSelected = true;
      this.featureStatus.naturalId = naturalId;
      this.featureStatus.containsNumberOfRooms = false;
      console.error('ERROR FETCHING NUMBER OF ROOMS', err);
      return of(null);
    });

    this.http
      .getGrossRentData(naturalId, this.featureStatus.grossRentYear)
      .subscribe((data: JsonResponse<HomeownershipJSON>) => {
        this.featureStatus.isSelected = true;
        this.featureStatus.naturalId = naturalId;
        this.featureStatus.containsGrossRent = true;

        this.fillGrossRentChart(data.object.distribution as GrossRent[]);
      });
    catchError((err) => {
      this.featureStatus.isSelected = true;
      this.featureStatus.naturalId = naturalId;
      this.featureStatus.containsGrossRent = false;
      console.error('ERROR FETCHING GROSS RENT', err);
      return of(null);
    });

    this.http
      .getHousingSellCostData(naturalId, this.featureStatus.housingSellCostYear)
      .subscribe((data: JsonResponse<HomeownershipJSON>) => {
        this.featureStatus.isSelected = true;
        this.featureStatus.naturalId = naturalId;
        this.featureStatus.containsHousingSellCost = true;

        this.fillHousingSellCostChart(
          data.object.distribution as HousingSellCost[]
        );
      });
    catchError((err) => {
      this.featureStatus.isSelected = true;
      this.featureStatus.naturalId = naturalId;
      this.featureStatus.containsHousingSellCost = false;
      console.error('ERROR FETCHING GROSS RENT', err);
      return of(null);
    });
  }

  private fillHousingSellCostChart(data: HousingSellCost[]): void {
    const priceData = this.aggregatePriceRangeData(
      data,
      TypeOfHomeownershipData.HOUSING_SELL_COST,
      HomeownershipService.housingSellCostRanges
    );

    const values = Object.values(priceData);
    const keys = sortKeys(Object.keys(priceData)).map((el) =>
      formatChartRangesAndHoverNumber(el)
    );
    const hoverTexts = values.map(
      (value, i) => `$${keys[i]}: ${formatChartRangesAndHoverNumber(value)}`
    );

    const totalValues = values.reduce((acc, curr) => acc + curr, 0);

    if (values.length === 0) {
      this.featureStatus.containsHousingSellCost = false;
      return;
    }

    const percentages = getPercents(totalValues, values);

    this.housingSellCostChart.data = [
      {
        y: keys,
        x: percentages,
        type: 'bar',
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        customdata: percentages.map((value, i) => {
          return `${keys[i]}: ${value >= 1 ? value.toFixed(1) : '<1'}%`;
        }),
        hovertemplate: `%{customdata}<extra></extra>`,
        orientation: 'h',
        marker: {
          color: '#774FA0',
        },
      },
    ] as Data;

    this.housingSellCostChart.layout = {
      autosize: true,
      height: 400,
      width: 350,
      yaxis: {
        title: 'Home value, $',
      },
      xaxis: {
        title: 'Households',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      margin: { t: 35, b: 50, r: 0, l: 100, pad: 5 },
      bargap: 0.5,
      font: {
        family: 'Rising Sun, sans-serif',
      },
    } as Layout;
  }

  private fillGrossRentChart(data: GrossRent[]): void {
    const rentData = this.aggregatePriceRangeData(
      data,
      TypeOfHomeownershipData.GROSS_RENT,
      HomeownershipService.householdsRentRangesRanges
    );

    const values = Object.values(rentData);
    const keys = sortKeys(Object.keys(rentData)).map((el) =>
      formatChartRangesAndHoverNumber(el)
    );
    const hoverTexts = values.map(
      (value, i) => `$${keys[i]}: ${formatChartRangesAndHoverNumber(value)}`
    );

    const totalValues = values.reduce((acc, curr) => acc + curr, 0);

    if (values.length === 0) {
      this.featureStatus.containsGrossRent = false;
      return;
    }

    const percentages = getPercents(totalValues, values);

    this.grossRentChart.data = [
      {
        y: keys,
        x: percentages,
        type: 'bar',
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        customdata: percentages.map((value, i) => {
          return `${keys[i]}: ${value >= 1 ? value.toFixed(1) : '<1'}%`;
        }),
        hovertemplate: `%{customdata}<extra></extra>`,
        orientation: 'h',
        marker: {
          color: '#774FA0',
        },
      },
    ] as Data;

    this.grossRentChart.layout = {
      autosize: true,
      height: 400,
      width: 350,
      xaxis: {
        title: {
          text: 'Households',
          color: 'white',
          font: {
            size: 0,
            color: '#fff',
          },
        } as any,
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      yaxis: {
        title: 'Rent price, $',
      },
      margin: { t: 35, b: 50, r: 0, l: 100, pad: 5 },
      bargap: 0.5,
      font: {
        family: 'Rising Sun, sans-serif',
      },
    } as Layout;
  }

  private fillNumberOfRoomsChart(data: NumberOfRooms[]): void {
    const mappedData = data.map((el) => {
      return { value: el.housingUnits, rooms: el.numberOfRooms };
    });

    const values = mappedData.map((el) => el.value);
    const keys = mappedData.map((el) => el.rooms);
    const hoverTexts = values.map(
      (value) => `${formatChartRangesAndHoverNumber(value)}`
    );

    const totalValues = values.reduce((acc, curr) => acc + curr, 0);

    if (values.length === 0) {
      this.featureStatus.containsNumberOfRooms = false;
      return;
    }

    const percentages = getPercents(totalValues, values);

    this.numberOfRoomsChart.data = [
      {
        x: keys,
        y: percentages,
        type: 'bar',
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        customdata: percentages.map((value, i) => {
          return `${keys[i]} Rooms: ${value >= 1 ? value.toFixed(1) : '<1'}%`;
        }),
        hovertemplate: `%{customdata}<extra></extra>`,
        marker: {
          color: keys.map((value) => {
            switch (value) {
              case 1:
                return '#AD95C6';
              case 2:
                return '#A084BD';
              case 3:
                return '#9272B3';
              case 4:
                return '#8561AA';
              case 5:
                return '#774FA0';
              case 6:
                return '#6B4790';
              case 7:
                return '#5F3F80';
              case 8:
                return '#533770';
              case 9:
                return '#3E2854';
              default:
                return;
            }
          }),
        },
      },
    ] as Data;

    this.numberOfRoomsChart.layout = {
      autosize: true,
      xaxis: {
        title: 'Number of rooms',
        type: 'category',
        tickfont: {
          size: 14,
        },
      },
      yaxis: {
        title: 'Households',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      bargap: 0.5,
      font: {
        family: 'Rising Sun, sans-serif',
      },
      width: 420,
      height: 330,
      margin: { t: 35, b: 80, r: 30 },
    } as Layout;
  }

  private fillVacancyChart(data: HomeownershipVacancy[]): void {
    const mappedData = data.map((el: HomeownershipVacancy) => {
      return {
        occupancy: !el.occupancy ? 'Vacant' : 'Occupied',
        value: el.housingUnits,
      };
    });

    const values = mappedData.map((el) => el.value);
    const occupancy = mappedData.map((el) => el.occupancy);
    const hoverTexts = values.map(
      (value, i) => `${occupancy[i]}: ${formatChartRangesAndHoverNumber(value)}`
    );

    const totalValues = values.reduce((acc, curr) => acc + curr, 0);

    if (values.length === 0) {
      this.featureStatus.containsHomeownershipVacancy = false;
      return;
    }

    const percentages = getPercents(totalValues, values);

    this.homeownershipVacancyChart.data = [
      {
        x: occupancy,
        y: percentages,
        type: 'bar',
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        customdata: percentages.map((value, i) => {
          return `${occupancy[i]}: ${value >= 1 ? value.toFixed() : '<1'}%`;
        }),
        hovertemplate: `%{customdata}<extra></extra>`,
        marker: {
          color: occupancy.map((value) =>
            value === 'Vacant' ? '#A084BD' : '#6B4790'
          ),
        },
      },
    ] as Data;

    this.homeownershipVacancyChart.layout = {
      autosize: true,
      xaxis: {
        title: {
          text: 'Vacancy',
          color: 'white',
          font: {
            size: 1,
            color: '#fff',
          },
        } as any,
        type: 'category',
      },
      yaxis: {
        title: 'Households',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      bargap: 0.5,
      font: {
        family: 'Rising Sun, sans-serif',
      },
      width: 390,
      height: 330,
      margin: { t: 35, b: 30, r: 30 },
    } as Layout;
  }

  private fillTenureChart(data: HomeownershipTenure[]): void {
    const mappedData = data.map((el: HomeownershipTenure) => {
      return {
        occupancy: el.occupiedBy === 'OWNER' ? 'By owner' : 'By renter',
        value: el.housingUnits,
      };
    });

    const values = mappedData.map((el) => el.value);
    const occupancy = mappedData.map((el) => el.occupancy);
    const hoverTexts = values.map(
      (value, i) => `${occupancy[i]}: ${formatChartRangesAndHoverNumber(value)}`
    );

    const totalValues = values.reduce((acc, curr) => acc + curr, 0);

    if (values.length === 0) {
      this.featureStatus.containsHomeownershipTenure = false;
      return;
    }

    const percentages = getPercents(totalValues, values);

    this.homeownershipTenureChart.data = [
      {
        x: occupancy,
        y: percentages,
        type: 'bar',
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        customdata: percentages.map((value, i) => {
          return `${occupancy[i]}: ${value >= 1 ? value.toFixed() : '<1'}%`;
        }),
        hovertemplate: `%{customdata}<extra></extra>`,
        marker: {
          color: occupancy.map((value) =>
            value === 'Occupied <br> by owner' ? '#A084BD' : '#6B4790'
          ),
        },
      },
    ] as Data;

    this.homeownershipTenureChart.layout = {
      autosize: true,
      xaxis: {
        title: {
          text: 'Occupancy',
        },
        type: 'category',
      },
      yaxis: {
        title: 'Households',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      bargap: 0.5,
      font: {
        family: 'Rising Sun, sans-serif',
      },
      width: 390,
      height: 330,
      margin: { t: 35, r: 30, b: 35 },
    } as Layout;
  }

  private aggregatePriceRangeData(
    data: GrossRent[] | HousingSellCost[],
    typeOfData: string,
    costRangeAggregation: RentRange[]
  ): Record<string, number> {
    let priceData: any;

    if (typeOfData === TypeOfHomeownershipData.GROSS_RENT) {
      priceData = (data as GrossRent[])
        .map((rentRange) => {
          let rentCostFrom = rentRange.rentCostFrom;
          let rentCostTo = !!rentRange.rentCostTo
            ? Math.abs(rentRange.rentCostTo)
            : RentRange.MAX_RENT;

          const householdRentRange = new RentRange(rentCostFrom, rentCostTo);

          const matchingRanges = costRangeAggregation.filter((range) =>
            range.containsWholeRange(householdRentRange)
          );

          return matchingRanges.map((range) => ({
            range: range,
            housingUnits: rentRange.housingUnits,
          }));
        })
        .flat();
    } else if (typeOfData === TypeOfHomeownershipData.HOUSING_SELL_COST) {
      priceData = (data as HousingSellCost[])
        .map((rentRange) => {
          // Discard ranges where costFrom equals costTo
          if (rentRange.costFrom === rentRange.costTo) return [];

          let rentCostFrom = rentRange.costFrom;

          let rentCostTo = !!rentRange.costTo
            ? Math.abs(rentRange.costTo)
            : RentRange.MAX_RENT;

          // Handle weird range as exceptions
          if (rentRange.costTo === 10000) {
            if (rentRange.costFrom === 0 && rentRange.costTo === 10000) {
              return [
                {
                  range: new RentRange(rentRange.costFrom, rentRange.costTo),
                  housingUnits: rentRange.housingUnits,
                },
              ];
            }
          }

          const householdRentRange = new RentRange(rentCostFrom, rentCostTo);

          const matchingRanges = costRangeAggregation.filter((range) =>
            range.containsWholeRange(householdRentRange)
          );

          return matchingRanges.map((range) => ({
            range: range,
            housingUnits: rentRange.housingUnits,
          }));
        })
        .flat();
    }

    // Sort the incomeData by range
    priceData!.sort((a: any, b: any) => {
      return a.range.fromInclusive - b.range.fromInclusive;
    });

    const aggregatedData = priceData.reduce(
      (acc: any, curr: any) => {
        const rangeStr = curr.range.toInclusiveString();
        const housingUnits = Math.round(curr.housingUnits);

        if (!acc[rangeStr]) {
          acc[rangeStr] = 0;
        }
        acc[rangeStr] += housingUnits;
        return acc;
      },
      {} as Record<string, number>
    );

    const filteredAggregatedData = Object.fromEntries(
      Object.entries(aggregatedData).filter(
        ([_, housingUnits]) =>
          Number.isInteger(housingUnits) && Number(housingUnits) > 0
      )
    ) as Record<string, number>;

    return filteredAggregatedData;
  }
}
