<div class="app-checkbox-layout">
  <div class="app-checkbox-inner-container">
    <!-- The actual checkbox -->
    <div class="app-checkbox-frame"></div>
    <div class="app-checkbox-background">
      <svg class="app-checkbox-checkmark" viewBox="0 0 24 24" *ngIf="checked">
        <path class="app-checkbox-checkmark-path" 
              fill="none" 
              stroke="white" 
              d="M4.1,12.7 9,17.6 20.3,6.3" 
              stroke-width="2.5"/>
      </svg>
    </div>
    
    <!-- Custom ripple effect -->
    <div class="app-checkbox-ripple" [class.app-checkbox-ripple-active]="rippleActive"></div>
    
    <!-- Hidden native input for accessibility -->
    <input #input
           type="checkbox"
           class="app-checkbox-input cdk-visually-hidden"
           [id]="id"
           [required]="required"
           [checked]="checked"
           [disabled]="disabled"
           [attr.name]="name"
           [attr.aria-checked]="indeterminate ? 'mixed' : checked"
           [attr.aria-label]="ariaLabel || null"
           [attr.aria-labelledby]="ariaLabelledby"
           [tabIndex]="tabIndex"
           (change)="_onInputClick()"
           (click)="_onInputClick($event)">
  </div>
</div> 