import * as mapboxgl from 'mapbox-gl';
import { LngLat, LngLatLike } from 'mapbox-gl';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { LayerStoreService } from './layer-store.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { SelectedCellService } from './selected-cell.service';
import { MapBoxService } from '../mapbox.service';
import { PopupService } from './popup.service';
import { BreakpointObserverService } from '../../../shared/services/breakpoint-observer.service';

export interface MapUrlParams {
  coordinates?: LngLatLike | null;
  zoom?: number | null;
  level?: string | null;
  selectedCellId?: string | null;
  currentLayer?: string | null;
}

export const MAP_QUERY_PARAMS = 'MAP_QUERY_PARAMS';

@Injectable({
  providedIn: 'root',
})
export class MapUrlService {
  private readonly coordinatesParam = 'll';
  private readonly coordinatesFractionDigits = 5;
  private readonly coordinatesSeparator = ',';
  private readonly zoomParam = 'z';
  private readonly levelParam = 'lv';
  private readonly idParam = 'id';
  private readonly layerParam = 'lr';

  constructor(
    private location: Location,
    private layerStore: LayerStoreService,
    private selectedCellService: SelectedCellService,
    private mapboxService: MapBoxService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private breakpointObserverService: BreakpointObserverService
  ) {}

  /**
   * @return current url get params as {@link MapUrlParams} object.
   **/
  public getUrlParams(query?: string): MapUrlParams {
    let searchParams: URLSearchParams;

    if (query) {
      searchParams = new URLSearchParams(
        query.startsWith('?') ? query.substring(1) : query
      );
    } else {
      const url = new URL(window.location.href);

      if (!url.search) {
        // Use local storage if no URL search params
        const storedParams = localStorage.getItem(MAP_QUERY_PARAMS);
        if (storedParams) {
          searchParams = new URLSearchParams(storedParams);
        } else {
          searchParams = new URLSearchParams();
        }
      } else {
        // Use URL search params
        searchParams = url.searchParams;
      }
    }

    const coordinates = searchParams.get(this.coordinatesParam);
    const ll = coordinates
      ? coordinates.split(this.coordinatesSeparator).map(parseFloat)
      : null;
    const zoom = searchParams.get(this.zoomParam)
      ? +searchParams.get(this.zoomParam)!
      : null;
    const level = searchParams.get(this.levelParam) ?? null;
    const selectedCellId = searchParams.get(this.idParam) ?? null;
    const currentLayer = searchParams.get(this.layerParam) ?? null;

    return {
      coordinates: ll && ll.length === 2 ? [+ll[0], +ll[1]] : null,
      zoom,
      level,
      selectedCellId,
      currentLayer,
    };
  }

  /**
   * Set GET params in the browser URL without refreshing the page.
   */
  private setUrlParams(params: MapUrlParams) {
    const pathName = window.location.pathname;
    const pathNameWithoutParams = pathName.split('?')[0];
    this.location.go(pathNameWithoutParams + '?' + this.paramsToString(params));
    localStorage.setItem(MAP_QUERY_PARAMS, this.paramsToString(params));
  }

  /**
   *  Change some GET params in the browser URL not erasing others.
   *  Does _not_ refresh the page.
   */
  public updateUrlParams(params: MapUrlParams) {
    const currentParams = this.getUrlParams();
    currentParams.coordinates =
      params.coordinates !== undefined
        ? params.coordinates
        : currentParams.coordinates;
    currentParams.zoom =
      params.zoom !== undefined ? params.zoom : currentParams.zoom;
    currentParams.level =
      params.level !== undefined ? params.level : currentParams.level;
    currentParams.selectedCellId =
      params.selectedCellId !== undefined
        ? params.selectedCellId
        : currentParams.selectedCellId;
    currentParams.currentLayer =
      params.currentLayer !== undefined
        ? params.currentLayer
        : currentParams.currentLayer;
    this.setUrlParams(currentParams);
  }

  /**
   * Format {@link MapUrlParams} as GET params string.
   * Does _not_ add '?' in the beginning.
   */
  private paramsToString(params: MapUrlParams): string {
    let coordinatesStr = '';

    if (params.coordinates != null) {
      const lat = (params.coordinates as [number, number])[0].toFixed(
        this.coordinatesFractionDigits
      );
      const lon = (params.coordinates as [number, number])[1].toFixed(
        this.coordinatesFractionDigits
      );
      coordinatesStr =
        this.coordinatesParam + '=' + lat + this.coordinatesSeparator + lon;
    }

    const zoomStr = this.formatParam(this.zoomParam, params.zoom!);
    const levelStr = this.formatParam(this.levelParam, params.level as string);
    const selectedCellIdStr = this.formatParam(
      this.idParam,
      params.selectedCellId as string
    );
    const currentLayerStr = this.formatParam(
      this.layerParam,
      params.currentLayer as string
    );

    return this.ampersandSeparated([
      coordinatesStr,
      zoomStr,
      levelStr,
      selectedCellIdStr,
      currentLayerStr,
    ]);
  }

  private ampersandSeparated(params: string[]): string {
    const res: string = params
      .filter((p) => p !== '')
      .map((p) => p + '&')
      .reduce((s1, s2) => s1 + s2);
    return res.replace(/&+$/, ''); // delete last `&`
  }

  private formatParam(key: string, value: string | number | null) {
    return value != null ? key + '=' + value : '';
  }

  public initWithParams(map: mapboxgl.Map, params?: MapUrlParams): void {
    this.jumpToLocation(map, params);

    this.handleSubscriptions();
  }

  private handleSubscriptions(): void {
    this.selectedCellService.selectedCellGeoId.subscribe((selectedCellId) =>
      this.updateUrlParams({ selectedCellId })
    );

    this.layerStore.activeLevel.subscribe((level) =>
      this.updateUrlParams({ level })
    );
    this.layerStore.activeLayer.subscribe((currentLayer) =>
      this.updateUrlParams({ currentLayer })
    );
  }

  private jumpToLocation(map: mapboxgl.Map, params?: MapUrlParams): void {
    const urlParams = params || this.getUrlParams();

    const center: LngLatLike =
      urlParams.coordinates != null
        ? [
            (urlParams.coordinates as [number, number])[1],
            (urlParams.coordinates as [number, number])[0],
          ]
        : new LngLat(
            this.layerStore.defaultLocation.center[0],
            this.layerStore.defaultLocation.center[1]
          );

    const zoom: number =
      urlParams.zoom != null
        ? urlParams.zoom
        : this.layerStore.defaultLocation.zoom;

    //handle cases when map is loaded and being zoomed in one state which doesn't allow it to color properly
    this.layerStore.onZoomSwitchLayer(
      zoom + 0.01,
      this.breakpointObserverService.isMobile.value
    );

    map.jumpTo({
      center: center,
      zoom: zoom,
    });

    // Handle main map specific params like level and cell id separately as other maps do not require same params
    this.handleMainMapInit(urlParams, zoom);
  }

  private handleMainMapInit(urlParams: MapUrlParams, zoom: number): void {
    const map = this.mapboxService.map;
    const level = urlParams.level != null ? urlParams.level : undefined;
    const selectedCellId = urlParams.selectedCellId
      ? urlParams.selectedCellId
      : undefined;
    const layer =
      urlParams.currentLayer != null ? urlParams.currentLayer : undefined;

    if (level) {
      this.layerStore.handleLevelChange(level);
    } else {
      this.layerStore.onZoomSwitchLayer(
        zoom,
        this.breakpointObserverService.isMobile.value
      );
    }

    if (layer) {
      this.layerStore.activeLayer.next(layer);
    }

    if (selectedCellId && map) {
      map.once('idle', () => {
        const feature = map
          .queryRenderedFeatures(undefined, {
            layers: [this.layerStore.activeLevel.value],
          })
          .find((feature) => {
            return feature.properties!.external_id === selectedCellId;
          });

        if (feature) {
          this.selectedCellService.selectCell(feature);
          this.popupService.handleOnLoadPopup(
            feature,
            this.mapboxService.getFeatureCenter(feature)
          );
        }
      });
    }
  }

  public getUrl(): string {
    return this.location.path().split('?')[0];
  }

  public copyUrlToClipboard(): void {
    const query = this.location.path();
    const url = `${environment.domainUrl}${query}`;

    navigator.clipboard
      .writeText(url)
      .then(() => this.toastr.success('Location is copied to clipboard'));
  }
}
