import { Component, OnInit } from '@angular/core';
import { LevelLayers } from './levels';
import { IconRegistryService } from '../../../../shared/services/icon-registry.service';
import { LevelSelectionService } from './level-selection.service';
import { Industries } from '../../../../shared/util/industries';
import { DropdownMenuComponent } from '../../../../shared/components/dropdown-menu/dropdown-menu.component';
import { NgIf } from '@angular/common';

export const LEVEL_SELECTION_DROPDOWN = 'level';

@Component({
  selector: 'app-level-selection',
  templateUrl: './level-selection.component.html',
  styleUrls: ['./level-selection.component.scss'],
  imports: [DropdownMenuComponent, NgIf],
})
export class LevelSelectionComponent implements OnInit {
  public levels!: any;

  public isDropdownActive: boolean = false;

  public isDropdownVisible: boolean = true;

  constructor(
    private iconRegistry: IconRegistryService,
    private levelService: LevelSelectionService
  ) {
    this.iconRegistry.initLevelSelectionIcons();
    this.levels = LevelLayers;
  }

  ngOnInit(): void {}

  public changeActiveSelection(event: Industries | LevelLayers): void {
    this.levelService.changeLevelBySelection(event as LevelLayers);
  }

  protected readonly LEVEL_SELECTION_DROPDOWN = LEVEL_SELECTION_DROPDOWN;
}
