import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonResponse } from '../shared/api/backend-config';
import { AuthDataJSON } from './sign-in/sign-in.component';
import { AuthDataRegisterJSON } from './sign-up/register-individual/register-individual.component';
import { GoogleAuth0Response } from './oauth';
import { SubscriptionPlans } from './user/user.model';
import { Router } from '@angular/router';
import { REDIRECT_AFTER_LOGIN_PATH } from '../app.component';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const INDIVIDUAL = 'INDIVIDUAL';
export const ENTERPRISE = 'ENTERPRISE';

export interface RegisterData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  category: typeof INDIVIDUAL | typeof ENTERPRISE;
  companyName?: string;
}

export interface UserSubscription {
  plan: SubscriptionPlans;
  // unix timestamp [UTC] when subscription ends. Null for lifetime
  untilUtc: number | null;
  isLifetime: boolean;
  isAutoRenewable: boolean;
}
export interface UserPersonalData extends UserInfo {
  companyName?: string;
  companyPosition?: string;
  category: typeof INDIVIDUAL | typeof ENTERPRISE;
  createdAt: string;
  emailConfirmed: boolean;
  isAdmin: boolean;
  subscription: UserSubscription | null;
  phone?: string;
  zipCode?: string;
  city?: string;
  stateCounty?: string;
  country?: string;
  realtorsAssociationMemberId?: string;
}

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export interface TokensStorage {
  [ACCESS_TOKEN]: string | null;
  [REFRESH_TOKEN]: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * Actual data about logged user.
   */
  public userPersonalData: BehaviorSubject<UserPersonalData | null> =
    new BehaviorSubject<UserPersonalData | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    // @ts-ignore
    window.loginByAngularApi = (email, pass) => {
      //@ts-ignore
      zone.run(() => {
        return this.login(email, pass);
      });
    };
  }

  public login(
    email: string,
    password: string
  ): Observable<JsonResponse<AuthDataJSON>> {
    const body = { email, password };
    return this.http
      .post<JsonResponse<AuthDataJSON>>(`${environment.apiUrl}user/login`, body)
      .pipe(
        tap((res) => {
          this.setTokens(res.object.tokens.access, res.object.tokens.refresh);
          this.getUserInfo().subscribe(() => {
            this.redirectAfterAuth();
          });
        }),
        catchError((err) => {
          console.error('in auth service login: ', err);
          throw err;
        })
      );
  }

  public loginGoogle(
    token: GoogleAuth0Response
  ): Observable<JsonResponse<AuthDataJSON>> {
    return this.http
      .post<
        JsonResponse<AuthDataJSON>
      >(`${environment.apiUrl}user/login/google`, token)
      .pipe(
        tap((res) => {
          this.setTokens(res.object.tokens.access, res.object.tokens.refresh);
          this.refreshUserInfo();
          this.redirectAfterAuth();
        }),
        catchError((err) => {
          console.error('in auth service login: ', err);
          throw err;
        })
      );
  }

  public register(
    data: RegisterData
  ): Observable<JsonResponse<AuthDataRegisterJSON>> {
    return this.http
      .post<
        JsonResponse<AuthDataRegisterJSON>
      >(`${environment.apiUrl}user/register`, data)
      .pipe(
        tap((res) => {
          this.setTokens(res.object.access, res.object.refresh);
        }),
        catchError((err) => {
          console.error('in auth service register: ', err);
          throw err;
        })
      );
  }

  public refreshToken(): Observable<JsonResponse<AuthDataJSON>> {
    const refreshToken = this.getTokens()[REFRESH_TOKEN];
    return this.http
      .post<
        JsonResponse<AuthDataJSON>
      >(`${environment.apiUrl}user/token/refresh`, { refreshToken: refreshToken })
      .pipe(
        tap((res) => {
          this.setTokens(res.object.tokens.access, res.object.tokens.refresh);
        })
      );
  }

  public redirectAfterAuth(): void {
    const savedPath = localStorage.getItem(REDIRECT_AFTER_LOGIN_PATH);
    if (savedPath) {
      this.router.navigateByUrl(savedPath);
    } else {
      this.router.navigate(['/']);
    }
    localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH);
  }

  /**
   * Request user info from server.
   * Subscribe to userPersonalData instead if possible.
   */
  public getUserInfo(): Observable<JsonResponse<UserPersonalData>> {
    const observable = this.http.get<JsonResponse<UserPersonalData>>(
      `${environment.apiUrl}user/`
    );
    observable.subscribe((data) => {
      this.userPersonalData.next(data.object);
    });
    return observable;
  }

  public requestPasswordRecovery(email: string): Observable<any> {
    return this.http.post<JsonResponse<any>>(
      `${environment.apiUrl}password/reset?email=${email.toLowerCase()}`,
      {}
    );
  }

  public resetPassword(newPassword: string, token: string): Observable<any> {
    return this.http.put<JsonResponse<any>>(
      `${environment.apiUrl}password?token=${token}`,
      { newPassword: newPassword }
    );
  }

  /**
   * Re-request user info from server & trigger userPersonalData update.
   */
  public refreshUserInfo(): void {
    this.getUserInfo();
  }

  public updateUserInfo(
    data: Partial<UserPersonalData>
  ): Observable<JsonResponse<UserPersonalData>> {
    return this.http.post<JsonResponse<UserPersonalData>>(
      `${environment.apiUrl}user/`,
      data
    );
  }

  public logout(): void {
    localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH);
    this.userPersonalData.next(null);

    this.router.navigate(['/sign-in']);
    this.clearTokens();
  }

  public clearTokens(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }

  public getTokens(): TokensStorage {
    return {
      [ACCESS_TOKEN]: localStorage.getItem(ACCESS_TOKEN),
      [REFRESH_TOKEN]: localStorage.getItem(REFRESH_TOKEN),
    };
  }

  public setTokens(access: string, refresh: string): void {
    localStorage.setItem(ACCESS_TOKEN, access);
    localStorage.setItem(REFRESH_TOKEN, refresh);
  }

  public hasAccessToken(): boolean {
    const token = this.getTokens()[ACCESS_TOKEN];
    return !!token;
  }

  public getUserEmail(): string {
    return this.userPersonalData.getValue()?.email!;
  }
}
