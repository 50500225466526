import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  LayerStoreService,
  MAP_STYLE,
  STYLE_LIGHT,
  STYLE_STREETS,
} from '../mapbox/services/layer-store.service';

@Injectable({
  providedIn: 'root',
})
export class MapStyleServiceService {
  private mapStyle = new Subject();
  public $mapStyle = this.mapStyle.asObservable();

  constructor(private layerStore: LayerStoreService) {
    this.mapStyle.next(this.layerStore.mapOptions.style);
  }

  public toggleMapStyle(toggleStatus: boolean): void {
    const selectedStyle = toggleStatus ? STYLE_LIGHT : STYLE_STREETS;
    this.layerStore.mapOptions.style = selectedStyle;
    this.mapStyle.next(selectedStyle);
    localStorage.setItem(MAP_STYLE, selectedStyle);
  }
}
