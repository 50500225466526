import { Injectable } from '@angular/core';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import {
  FeatureStatus,
  FEMALE_COLOR,
  MALE_COLOR,
  NaturalId,
} from '../population-menu/population.service';
import { IncomeRange, YearRange } from '../../../../../shared/model/range';
import { IncomeSamples, Sex, Work } from '../../../../../shared/model/income';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import {
  formatChartRangesAndHoverNumber,
  getPercents,
} from '../shared/chart-utility';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { mapIncomeDistribution } from '../../../../../shared/model/population-mapper';
import { Data, Layout } from 'plotly.js-dist-min';

export interface PersonalDistributionJSON {
  year: number;
  workType: 'FULL_TIME' | 'OTHER';
  sex: Sex;
  incomeFrom: number;
  incomeTo: number;
  population: number;
}

export interface HouseholdDistributionJSON {
  year: number;
  incomeFrom: number;
  incomeTo: number;
  households: number;
}

export interface EmploymentDistributionJSON {
  distribution: EmploymentDistribution[];
}

export interface EmploymentDistribution {
  labor: string;
  employed: boolean;
  population: number;
}

export interface HouseholdsIncomeData {
  year: number;
  incomeFrom: number;
  incomeTo: number | null;
  households: number;
}

export interface IncomeDistribution {
  distribution: any;
}

export interface WealthFeatureStatus extends FeatureStatus {
  containsIncomeDistribution: boolean;
  containsEmploymentDistribution: boolean;
}

const LABOR_ABLE = 'Labor-able';
const NOT_ABLE = 'Not Able';
const EMPLOYED = 'Employed';
const UNEMPLOYED = 'Unemployed';
const MILITARY = 'Military';

@Injectable({
  providedIn: 'root',
})
export class IncomeDistributionService {
  public readonly targetYear = 2022;

  private static readonly HouseholdsIncomeRangesAggregation: IncomeRange[] = [
    new IncomeRange(1, 10000),
    new IncomeRange(10000, 15000),
    new IncomeRange(15000, 25000),
    new IncomeRange(25000, 35000),
    new IncomeRange(35000, 50000),
    new IncomeRange(50000, 75000),
    new IncomeRange(75000, 100000),
    new IncomeRange(100000, 150000),
    new IncomeRange(150000, 200000),
    new IncomeRange(200000, IncomeRange.MAX_INCOME),
  ];

  private readonly layoutLegend: Layout['legend'] = {
    x: -0.3,
    y: 1.1,
    orientation: 'h',
  };

  public incomeDistributionChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data as Data,
    layout: {} as Layout as Layout,
  };

  public employmentDistributionChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data as Data,
    layout: {} as Layout as Layout,
  };

  public readonly featureStatus: WealthFeatureStatus = {
    naturalId: undefined,
    isSelected: false,
    containsIncomeDistribution: false,
    containsEmploymentDistribution: false,
  };

  constructor(private http: MapHttpService) {}

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId = feature.properties!.external_id;

    this.getDataFromAPIs(naturalId).subscribe(
      ([personalIncomeData, householdIncomeData, employmentData]) => {
        this.createIncomeDistributionChart(
          personalIncomeData!,
          householdIncomeData!
        );
        this.updateFeatureStatus(naturalId);

        this.calculateEmploymentChart(employmentData);
      }
    );
  }

  private getDataFromAPIs(
    naturalId: NaturalId
  ): Observable<
    [PersonalDistributionJSON[] | null, HouseholdDistributionJSON[] | null, any]
  > {
    // Create an observable for each HTTP request
    const personalIncomeData$ = this.getPersonalIncomeData(naturalId);
    const householdIncomeData$ = this.getHouseholdIncomeData(naturalId);
    const employmentData$ = this.getEmploymentData(naturalId);

    // Use forkJoin to wait for all HTTP requests to complete
    return forkJoin([
      personalIncomeData$,
      householdIncomeData$,
      employmentData$,
    ]);
  }

  private getPersonalIncomeData(
    naturalId: NaturalId
  ): Observable<PersonalDistributionJSON[] | null> {
    return this.http.getPersonalIncomeDistributionData(naturalId).pipe(
      map((data: JsonResponse<PersonalDistributionJSON[]>) => {
        return data.object;
      }),
      catchError((error) => {
        this.featureStatus.isSelected = true;
        this.featureStatus.containsIncomeDistribution = false;
        this.featureStatus.naturalId = naturalId;
        console.error('ERROR FETCHING PERSONAL MEDIAN INCOME', error);
        return of(null);
      })
    );
  }

  private getHouseholdIncomeData(
    naturalId: NaturalId
  ): Observable<HouseholdDistributionJSON[] | null> {
    return this.http.getHouseholdsIncomeDistributionData(naturalId).pipe(
      map((data: JsonResponse<HouseholdDistributionJSON[]>) => {
        return data.object;
      }),
      catchError((error) => {
        console.error('ERROR FETCHING HOUSEHOLD MEDIAN INCOME', error);
        return of(null);
      })
    );
  }

  private getEmploymentData(
    naturalId: NaturalId
  ): Observable<EmploymentDistributionJSON | null> {
    return this.http.getEmploymentData(naturalId, 2022).pipe(
      map((data: any) => {
        this.featureStatus.containsEmploymentDistribution = true;
        this.featureStatus.isSelected = true;

        return data.object;
      }),
      catchError((error) => {
        this.featureStatus.containsEmploymentDistribution = false;
        this.featureStatus.isSelected = true;
        console.error('ERROR FETCHING HOUSEHOLD MEDIAN INCOME', error);
        return of(null);
      })
    );
  }

  private createIncomeDistributionChart(
    personalIncomeData: PersonalDistributionJSON[],
    householdIncomeData: HouseholdDistributionJSON[]
  ): void {
    if (personalIncomeData && householdIncomeData) {
      const personalIncomeChart = this.calculatePersonalIncomeChart(
        personalIncomeData,
        this.targetYear
      );
      const householdsIncomeChart = this.calculateHouseholdIncomeData(
        householdIncomeData,
        this.targetYear
      );

      const personalIncomePercentages = personalIncomeChart.values.map(
        (value) => (value / personalIncomeChart.sum) * 100
      );
      const householdIncomePercentages = householdsIncomeChart.values.map(
        (value) => (value / householdsIncomeChart.sum) * 100
      );

      const totalValues = [
        ...personalIncomeChart.values,
        ...householdsIncomeChart.values,
      ].reduce((acc, curr) => acc + curr, 0);

      if (totalValues < 10) {
        this.featureStatus.containsIncomeDistribution = false;
        return;
      }

      this.incomeDistributionChart.data = [
        {
          y: personalIncomeChart.reformattedKeys,
          x: personalIncomePercentages,
          type: 'bar',
          name: 'Personal',
          orientation: 'h',
          marker: { color: FEMALE_COLOR },
          customdata: personalIncomePercentages.map(
            (percentage, i) =>
              `${personalIncomeChart.reformattedKeys[i]}: ${percentage.toFixed(1)}%`
          ),
          hovertemplate: `%{customdata}<extra></extra>`,
        },
        {
          y: householdsIncomeChart.reformattedKeys,
          x: householdIncomePercentages,
          type: 'bar',
          name: 'Households',
          orientation: 'h',
          marker: { color: MALE_COLOR },
          customdata: householdIncomePercentages.map(
            (percentage, i) =>
              `${householdsIncomeChart.reformattedKeys[i]}: ${percentage.toFixed(1)}%`
          ),
          hovertemplate: `%{customdata}<extra></extra>`,
        },
      ] as Data;

      this.incomeDistributionChart.layout = this.getChartLayout();
    }
  }

  private getChartLayout(): Layout {
    return (this.incomeDistributionChart.layout = {
      autosize: true,
      yaxis: {
        title: 'Income, $',
        titlefont: { size: 16 },
        dtick: 0,
      },
      xaxis: {
        title: 'People',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      font: {
        family: 'Rising Sun, sans-serif',
      },
      legend: this.layoutLegend,
      barmode: 'group',
      bargap: 0.3,
      height: 400,
      margin: {
        b: 40,
        t: 0,
        r: 0,
      },
    } as Layout);
  }

  private updateFeatureStatus(naturalId: NaturalId): void {
    this.featureStatus.containsIncomeDistribution = true;
    this.featureStatus.naturalId = naturalId;
    this.featureStatus.isSelected = true;
  }

  private calculateEmploymentChart(
    employmentData: EmploymentDistributionJSON
  ): void {
    const employmentDistribution: Record<string, number> = {
      [LABOR_ABLE]: 0,
      [NOT_ABLE]: 0,
      [EMPLOYED]: 0,
      [UNEMPLOYED]: 0,
      [MILITARY]: 0,
    };

    employmentData.distribution.forEach((el: EmploymentDistribution) => {
      if (el.labor === 'CIVILIAN') {
        employmentDistribution[LABOR_ABLE] += el.population;
        if (el.employed) {
          employmentDistribution[EMPLOYED] += el.population;
        } else {
          employmentDistribution[UNEMPLOYED] += el.population;
        }
      } else if (el.labor === 'NOT_ABLE') {
        employmentDistribution[NOT_ABLE] += el.population;
      } else if (el.labor === 'MILITARY') {
        employmentDistribution[LABOR_ABLE] += el.population;
        employmentDistribution[MILITARY] += el.population;
        employmentDistribution[EMPLOYED] += el.population;
      }
    });

    const categories = Object.keys(employmentDistribution);
    const populations = Object.values(employmentDistribution);

    const totalValues = populations.reduce((acc, curr) => acc + curr, 0);

    if (totalValues < 10) {
      this.featureStatus.containsEmploymentDistribution = false;
      return;
    }

    this.employmentDistributionChart.data = [
      {
        x: categories,
        y: getPercents(totalValues, populations),
        type: 'bar',
        customdata: getPercents(totalValues, populations).map(
          (el, i) => `${categories[i]}: ${el.toFixed(1)}%`
        ),
        hovertemplate: `%{customdata}<extra></extra>`,
        marker: {
          color: categories.map((value) => {
            switch (value) {
              case LABOR_ABLE:
                return '#A084BD';
              case NOT_ABLE:
                return '#8561AA';
              case EMPLOYED:
                return '#6B4790';
              case UNEMPLOYED:
                return '#5F3F80';
              case MILITARY:
                return '#533770';
              default:
                return;
            }
          }),
        },
      },
    ] as Data;

    this.employmentDistributionChart.layout = {
      autosize: true,
      xaxis: {
        title: 'Employment',
        tickangle: 0,
        tickfont: {
          size: 12,
        },
      },
      yaxis: {
        title: 'People',
        tickformat: ',.0f',
        ticksuffix: '%',
      },
      bargap: 0.4,
      barmode: 'group',
      margin: { t: 30, b: 35, r: 0 },
      height: 300,
      width: 420,
      font: {
        family: 'Rising Sun, sans-serif',
      },
    } as Layout;
  }

  private calculatePersonalIncomeChart(
    incomeData: PersonalDistributionJSON[],
    targetYear: number
  ): { reformattedKeys: string[]; values: number[]; sum: number } {
    const data = mapIncomeDistribution(incomeData)
      .filterByYearRange(new YearRange(targetYear, targetYear + 1))
      .byYears();

    const incomeSums = data
      .asArray()
      .map(([year, income]: [number, IncomeSamples]) => {
        // Get data for both work types
        const fullTimeWorkData = this.sumWorkTypesData(income, Work.FULLTIME);
        const otherWorkData = this.sumWorkTypesData(income, Work.OTHER);

        // Combine the values from both work types for each income range
        const combinedValues = fullTimeWorkData.values.map(
          (value, i) => value + (otherWorkData.values[i] || 0)
        );

        return { keys: fullTimeWorkData.keys, values: combinedValues };
      });

    const keys = incomeSums.map((obj) => obj.keys).flat();
    const values = incomeSums.map((obj) => obj.values).flat();
    const sum = values.reduce((a, b) => a + b, 0);

    const reformattedKeys = this.getRefactoredKeys(keys);

    return {
      reformattedKeys: reformattedKeys,
      values: values,
      sum: sum,
    };
  }

  private sumWorkTypesData(
    incomeData: IncomeSamples,
    workType: Work
  ): { keys: string[]; values: number[] } {
    const filteredIncome = incomeData.filterWork(workType);

    const maleData = filteredIncome
      .filterSex(Sex.MALE)
      .sumInsideIncomeRanges(
        IncomeDistributionService.HouseholdsIncomeRangesAggregation
      )
      .sortedByIncome();

    const femaleData = filteredIncome
      .filterSex(Sex.FEMALE)
      .sumInsideIncomeRanges(
        IncomeDistributionService.HouseholdsIncomeRangesAggregation
      )
      .sortedByIncome();

    const keys = maleData.uniqueIncomeRangesStrings();
    const values = maleData
      .populations()
      .map((el: number, i: number) => el + femaleData.populations()[i]);

    return { keys, values };
  }

  private calculateHouseholdIncomeData(
    data: HouseholdDistributionJSON[],
    targetYear: number
  ): { reformattedKeys: string[]; values: number[]; sum: number } {
    const sortedYear = data
      .filter((el: HouseholdsIncomeData) => el.year === targetYear)
      .sort(
        (a: HouseholdsIncomeData, b: HouseholdsIncomeData) =>
          a.incomeFrom - b.incomeFrom
      )
      .map((el) => {
        if (el.incomeFrom === 0) el.incomeFrom = 1;
        if (el.incomeFrom === 12000) el.incomeFrom = 15000;
        return el;
      });

    const incomeData = this.aggregateHouseholdsData(sortedYear);

    const reformattedKeys = this.getRefactoredKeys(Object.keys(incomeData));
    const values = Object.values(incomeData);
    const sum = values.reduce((a, b) => a + b, 0);

    return {
      reformattedKeys: reformattedKeys,
      values: values,
      sum: sum,
    };
  }

  private getRefactoredKeys(keys: string[], ticktext?: boolean): string[] {
    if (ticktext) {
      return keys.map((key: string) => {
        const [start, end] = key.split('-');

        return formatChartRangesAndHoverNumber(start);
      });
    }

    return keys.map((key: string) => {
      const [start, end] = key.split('-');
      if (end === undefined) {
        return Math.floor(parseInt(start) / 1000) + 'K+ ';
      }

      const startInK =
        start === '1' ? '0' : Math.floor(parseInt(start) / 1000) + 'K ';
      const endInK = Math.ceil(parseInt(end) / 1000) + 'K ';

      return startInK + '-' + endInK;
    });
  }

  private aggregateHouseholdsData(
    data: HouseholdsIncomeData[]
  ): Record<string, number> {
    const incomeData = data
      .map((household) => {
        let incomeFrom = household.incomeFrom;
        let incomeTo = !!household.incomeTo
          ? household.incomeTo
          : IncomeRange.MAX_INCOME;

        const householdIncomeRange = new IncomeRange(incomeFrom, incomeTo);

        const matchingRanges =
          IncomeDistributionService.HouseholdsIncomeRangesAggregation.filter(
            (range) => range.containsWholeRange(householdIncomeRange)
          );

        // Map each matching range to the household count
        return matchingRanges.map((range) => ({
          range: range.toInclusiveString(),
          households: household.households,
        }));
      })
      .flat();

    // Aggregate the data by range
    const aggregatedData = incomeData.reduce(
      (acc, curr) => {
        if (!acc[curr.range]) {
          acc[curr.range] = 0;
        }
        acc[curr.range] += curr.households;
        return acc;
      },
      {} as Record<string, number>
    );

    return aggregatedData;
  }
}
