<div class="common-colorscale-container">

  <app-map-style-selector *ngIf="!isMobile">
  </app-map-style-selector>
  <div class="content-wrapper">
    <div class="title-wrapper">
      <h4>{{currentLayer}}</h4>
<!--      <button class="change-link" *ngIf="!isMobile">Change</button>-->
    </div>
    <app-colorscale [colors]="colorScaleService.colorScaleColors"
                    [isCommonColorScale]="true"
                    class="colorscale"
    ></app-colorscale>
    <button app-icon-button iconSize="16px" class="common-colorscale-toggle-button" [attr.data-cy]="'common-colorscale-toggle-button'" *ngIf="isMobile" (click)="emitOpenMenuMobile()">
      <app-icon svgIcon="dropdownArrow"></app-icon>
    </button>
  </div>

</div>
