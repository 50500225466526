import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FiltersMenuService } from './filters-menu.service';
import {
  LayerStoreService,
  STATE_LEVEL_LAYER,
} from '../../../../mapbox/services/layer-store.service';
import { MenuGroupDisclaimer } from '../menu-group/menu-group.component';
import { HWW_GROUP_TITLE } from '../layers-menu.service';
import { debounceTime } from 'rxjs';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { LayerNameFormatPipe } from '../../../../common-colorscale/layer-name-format.pipe';
import { FilterComponent } from './filter/filter.component';
import { CustomCheckboxComponent } from '../../../../../shared/components/custom-checkbox/custom-checkbox.component';
import { IconComponent } from '../../../../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../../../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss'],
  imports: [
    NgIf,
    NgForOf,
    NgClass,
    LayerNameFormatPipe,
    FilterComponent,
    IconComponent,
    AsyncPipe,
    CustomCheckboxComponent,
    IconButtonDirective,
  ],
})
export class FiltersMenuComponent implements OnInit {
  public isListOpened: boolean = false;
  public openedGroupsList: string[] = [];
  public activeFiltersCheckboxes: string[] = [];

  constructor(
    public filtersService: FiltersMenuService,
    public layerStore: LayerStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.filtersService.parseFeaturesIntoDropdown();

    this.filtersService.updateFiltersSubject
      .pipe(debounceTime(250))
      .subscribe(() => this.cdr.detectChanges());

    this.activeFiltersCheckboxes = this.filtersService.activeFilters.map(
      (filter) => filter.featureConst
    );

    // Subscribe to active level to deactivate filters that are inaccessible on certain levels
    this.layerStore.activeLevel.subscribe((level) => {
      this.filtersService.featuresList.forEach((group) => {
        // To avoid additional nesting checks simply cut out groups that do not contain disclaimer
        if (group.disclaimer || group.groupTitle === HWW_GROUP_TITLE) {
          this.filtersService.activeFilters.forEach((filter) => {
            if (
              group.features.find(
                (feature) => feature.featureConst === filter.featureConst
              )
            ) {
              this.activeFiltersCheckboxes.forEach((feature) => {
                if (filter.featureConst === feature) {
                  if (
                    (group.disclaimer &&
                      group.disclaimer.visibleOnLevels?.includes(level)) ||
                    (group.groupTitle === HWW_GROUP_TITLE &&
                      !this.getIsHwwLayerAccessibleForLevel(feature, level))
                  ) {
                    this.activeFiltersCheckboxes.splice(
                      this.activeFiltersCheckboxes.indexOf(feature),
                      1
                    );
                    this.filtersService.removeFilter(filter.featureConst);
                  }
                }
              });
            }
          });
        }
      });
    });
  }

  public handleGroupClick(group: string): void {
    this.openedGroupsList.includes(group)
      ? (this.openedGroupsList = this.openedGroupsList.filter(
          (el) => el !== group
        ))
      : this.openedGroupsList.push(group);
  }

  public handleFeatureClick(
    feature: string,
    activeLevel?: string,
    disclaimer?: MenuGroupDisclaimer
  ): void {
    if (disclaimer && disclaimer.visibleOnLevels?.includes(activeLevel!)) {
      return;
    }

    const index = this.activeFiltersCheckboxes.indexOf(feature);
    if (index > -1) {
      this.activeFiltersCheckboxes.splice(index, 1);
      this.filtersService.removeFilter(feature);
    } else {
      this.activeFiltersCheckboxes.push(feature);
      this.filtersService.addFilter(feature);
    }
  }

  public getIsHwwLayerAccessibleForLevel(
    layer: string,
    level: string
  ): boolean {
    return level === STATE_LEVEL_LAYER
      ? layer.split('_').includes('country')
      : true;
  }

  protected readonly Math = Math;
  protected readonly Number = Number;
  protected readonly HWW_GROUP_TITLE = HWW_GROUP_TITLE;
}
