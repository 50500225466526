<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>

    <button
      (click)="toggleRightMenu()"
      class="right-menu-close-btn"
    >
      <div class="right-menu-close-btn-container">
        <img
          class="right-menu-close-btn-arrow"
          alt="open/close map demography layers menu"
          [ngClass]="{ 'closed': !menuService.isMenuOpened }"
          src="../../assets/icons/rightMenuCloseBtn.svg"
        />
      </div>
    </button>

    <div class="groups-buttons-container-desktop">
      <button (click)="activeMenuGroup = MAPS_GROUP; openRightMenu()"
              aria-label='Open data menu'
              [ngClass]="activeMenuGroup  === MAPS_GROUP ? 'active' : ''" class="indexesGroup">
        <app-icon svgIcon="indexesGroup"></app-icon>
      </button>

      <button (click)="activeMenuGroup = CHARTS_GROUP; openRightMenu(); handleChartsGroupClick()"
              aria-label='Open demographic charts menu'
              [ngClass]="activeMenuGroup === CHARTS_GROUP  ? 'active' : ''" class="chartsGroup"
              data-cy="charts-menu-button"
      >
        <app-icon svgIcon="chartsGroup"></app-icon>
      </button>

      <button (click)="activeMenuGroup = PLACES_GROUP; openRightMenu(); handleChartsGroupClick()"
              aria-label='Open places and points of interests menu'
              [ngClass]="activeMenuGroup === PLACES_GROUP ? 'active' : ''" class="lensesGroup"
              data-cy="places-menu-button">
        <app-icon svgIcon="pointsGroup"></app-icon>
      </button>

    </div>

    <app-favorites *ngIf="isMobile && !accessService.getIsUnauthorized()"
                   class="favorites"
                   [isFavoritesVisible]="isFavoritesVisible"
                   (closeFavorites)="handleFavoritesClose()"
                   (openFavorites)="handleFavoritesOpen()"
    >
    </app-favorites>

    <app-map-search
      [isSearchInputActive]="isSearchInputActive"
      (searchOpen)="handleSearchOpen()"
      (searchClose)="handleSearchClose()"
    ></app-map-search>

    <div class="map-tools-container"
         [ngClass]="isSearchInputActive ? 'selection-while-search-active' : ''">
      <app-favorites *ngIf="!isMobile && !accessService.getIsUnauthorized()"
        [isFavoritesVisible]="isFavoritesVisible"
        (closeFavorites)="handleFavoritesClose()"
        (openFavorites)="handleFavoritesOpen()"
      >
      </app-favorites>

      <app-level-selection *ngIf="!isMobile"
      ></app-level-selection>


      <app-selection-tool
        *ngIf="!showToolsMobile && ((accessService.isUserPlanUpdated | async) && accessService.getIsAdmin() || accessService.getUserPlan() === SubscriptionPlans.ENTERPRISE)"
      ></app-selection-tool>

      <app-multiple-features-selection *ngIf="!isMobile"></app-multiple-features-selection>
    </div>

    <app-common-colorscale [currentLayer]="(layerStore.activeLayer | async)! | LayerNameFormatPipe"
                           [isMobile]="isMobile"
                           (openMenuMobile)="toggleRightMenu()"
    ></app-common-colorscale>

    <app-mapbox></app-mapbox>
  </mat-sidenav-content>

  <mat-sidenav mode="side" opened="false" position="end" (closed)="onSidenavClosed()">
    <app-map-menu [activeMenuGroup]="activeMenuGroup" (closeRightMenu)="toggleRightMenu()"></app-map-menu>
  </mat-sidenav>

</mat-sidenav-container>
