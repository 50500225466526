<div class="map-menu-container">
  <div class="groups-buttons-container" [ngStyle]="{'justify-content': !(breakpointObserverService.isMobile | async) ? 'space-between' : ''}">
    <button
      *ngIf="
                (breakpointObserverService.isMobile | async) ||
                (activeMenuGroup === MAPS_GROUP || activeMenuGroup === PLACES_GROUP)
            "
    >
      {{ activeMenuGroup === MAPS_GROUP ? 'Maps' : 'Places'}}
    </button>

    <span *ngIf="breakpointObserverService.isMobile | async"> / </span>
    <button
      (click)="onGraphsClick()"
      *ngIf="
                (breakpointObserverService.isMobile | async) ||
                activeMenuGroup === CHARTS_GROUP
            "
      [attr.data-cy]="'charts-button'"
    >
      Charts
    </button>

    <button class="switch-filters-menu-button"
            *ngIf="!(breakpointObserverService.isMobile | async)"
            [ngClass]="isFiltersOpened ? 'filters-active' : ''"
            (click)="isFiltersOpened = !isFiltersOpened"
    >
      {{isFiltersOpened ? '—' : '＋'}}<span>{{isFiltersOpened ? 'Hide filters' : 'Filters' }}</span>
    </button>
  </div>

  <div class="menu-element-container">
    <app-filters-menu *ngIf="isFiltersOpened"></app-filters-menu>

    <form [formGroup]="form">
        <app-menu-group *ngFor="let groupData of layersMenuService.groupsData; trackBy:identify"
                        [groupData]="groupData"
                        [activeMenuGroup]="activeMenuGroup"
                        [form]="form"
                        [isPaidPlan]="accessService.isPaidPlanActive | async"
                        class="map-menu-element"
                        [attr.data-cy]="groupData.e2eName"
                        [isFiltersOpened]="isFiltersOpened"
        ></app-menu-group>

        <app-upcoming-group
          *ngFor="let groupData of upcomingGroupsData; trackBy:identifyUpcoming"
          [upcomingGroupData]="groupData"
          [activeMenuGroup]="activeMenuGroup"
          class="map-menu-element"
        >

        </app-upcoming-group>
    </form>


  </div>
</div>
