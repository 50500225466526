<div class="tool-container">

  <button
    (click)="toggleRadiusSelection()"
    class="radius-toggle-btn"
    [ngClass]="multipleFeatureSelectionService.isSelectionActive ? 'selection-active' : ''"
  >
    <app-icon
      svgIcon="comparative-location"
    >
    </app-icon>
  </button>

  <div *ngIf="multipleFeatureSelectionService.isSelectionActive" class="list-container">
    <h4>To compare: </h4>

    <div class="features-list">
      <span *ngIf="multipleFeatureSelectionService.selectedFeatures.length === 0">List is empty</span>
      <span *ngFor="let feature of multipleFeatureSelectionService.selectedFeatures" class="selected-feature">
       <span class="feature-text">
        <!-- Show address to compare which is built from context object for Non-state layer features,
         if no addressToCompare show name which is only made for states and counties, if both falsy fallback to feature id       -->
        {{ ($any(feature.properties).addressToCompare ?? $any(feature.properties).name) ?? feature.id }}
       </span>
    <button (click)="multipleFeatureSelectionService.deselectFeature(feature)" class="remove-button">
      <app-icon svgIcon="trash"></app-icon>
    </button>
  </span>
    </div>

    <button class="gradient-button" (click)="handleCompare()" [disabled]="multipleFeatureSelectionService.selectedFeatures.length === 0">
      Compare</button>

    <button app-icon-button iconSize="12px" (click)="closeSelection()" class="slider-close-button">
      <app-icon svgIcon="sliderButtonCancel"></app-icon>
    </button>
  </div>
</div>
