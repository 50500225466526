<div class="filters-menu-container" *ngIf="layerStore.activeLevel | async as activeLevel">

  <div class="active-filters" *ngIf="filtersService.activeFilters.length > 0">
    <app-filter *ngFor="let filter of filtersService.activeFilters"
                [activeLevel]="activeLevel"
                [filter]="filter"
                (removeFilter)="handleFeatureClick(filter.featureConst)"
    ></app-filter>
  </div>

  <button class="toggle-list-button" [ngClass]="isListOpened ? 'active' : ''" (click)="isListOpened = !isListOpened">
    <span>Choose filter</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 13.4864L17.6953 8L19 9.2568L12 16L5 9.2568L6.30466 8L12 13.4864Z" fill="currentColor"/>
    </svg>
  </button>

  <div class="features-dropdown-list-wrapper" *ngIf="isListOpened">
    <div class="features-dropdown-list">

      <div *ngFor="let group of filtersService.featuresList" class="features-group">
        <ng-container *ngIf="group.groupTitle">
        <div (click)="handleGroupClick(group.groupTitle)" class="title-container">
          <button app-icon-button iconSize="8px">
            <app-icon
              [ngClass]="{'opened': openedGroupsList.includes(group.groupTitle)}"
              class="arrow-right-icon"
              svgIcon="arrow_right"
            >
            </app-icon>
          </button>
          <span class="features-group-title">{{ group.groupTitle }}</span>
        </div>
          <span class="disclaimer" *ngIf="openedGroupsList.includes(group.groupTitle) && group.disclaimer && group.disclaimer.visibleOnLevels?.includes(activeLevel)">{{group.disclaimer.text}}</span>
        <div *ngFor="let feature of group.features" class="features-block">
          <div
            *ngIf="openedGroupsList.includes(group.groupTitle)"
            (click)="handleFeatureClick(feature.featureConst, activeLevel, group.disclaimer)"
            class="feature"
          >
            <app-custom-checkbox
              [checked]="activeFiltersCheckboxes.includes(feature.featureConst)"
              (click)="$event.stopPropagation(); "
              (change)="handleFeatureClick(feature.featureConst, activeLevel, group.disclaimer)"
              [disabled]="!!group.disclaimer?.visibleOnLevels?.includes(activeLevel) || group.groupTitle === HWW_GROUP_TITLE && !getIsHwwLayerAccessibleForLevel(feature.featureConst, activeLevel)"
            ></app-custom-checkbox>
            <span
              class="feature-name"
              [ngClass]="{ active: activeFiltersCheckboxes.includes(feature.featureConst), disabled: !!group.disclaimer?.visibleOnLevels?.includes(activeLevel) || group.groupTitle === HWW_GROUP_TITLE && !getIsHwwLayerAccessibleForLevel(feature.featureConst, activeLevel)}"
            >
      {{ feature.featureConst | LayerNameFormatPipe }}
    </span>
          </div>
        </div>

        </ng-container>
      </div>
    </div>
  </div>
</div>
