import { Pipe, PipeTransform } from '@angular/core';
import { formatNumbers } from '../../../../shared/pipes/number-formatter.pipe';
import { formatLayerName } from '../../../common-colorscale/layer-name-format.pipe';
import { KeyValue } from '../../../../shared/pipes/object-to-key-value.pipe';

@Pipe({
  name: 'formatFeature',
})
export class FormatFeaturePipe implements PipeTransform {
  transform(
    data: KeyValue,
    isPopup: boolean
  ): { formattedKey: string; formattedValue: string | null } | null {
    const formattedKey = formatLayerName(data.key);
    if (formattedKey) {
      const formattedValue =
        data.value === null
          ? 'N/A'
          : formatNumbers(data.value, 2, data.key, isPopup);
      return { formattedKey, formattedValue };
    }
    return null;
  }
}
