import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitLevelText',
})
export class SplitLevelTextPipe implements PipeTransform {
  transform(value: string, isSize: boolean): string {
    if (!value) return '';
    const match = value.match(/^(.*?)(?:\s\((.*)\))?$/);
    if (match) {
      return !isSize ? match[1] : match[2] ? `(${match[2]})` : '';
    }
    return value;
  }
}
