import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomRadioComponent } from '../custom-radio/custom-radio.component';

@Component({
  selector: 'app-custom-radio-group',
  templateUrl: './custom-radio-group.component.html',
  styleUrls: ['./custom-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomRadioGroupComponent),
      multi: true,
    },
  ],
})
export class CustomRadioGroupComponent
  implements ControlValueAccessor, AfterContentInit
{
  @ContentChildren(CustomRadioComponent)
  radioButtons!: QueryList<CustomRadioComponent>;

  @Input() name: string =
    `app-radio-group-${Math.random().toString(36).substring(2)}`;
  @Input() disabled: boolean = false;
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this._value !== newValue) {
      this._value = newValue;
      this.updateRadioButtonSelections();
    }
  }
  private _value: any = null;

  @Output() change = new EventEmitter<any>();

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    // Set up initial state
    this.updateRadioButtonSelections();

    // Subscribe to radio button changes
    this.radioButtons.forEach((radio) => {
      radio.name = this.name;
      radio.color = this.color;

      radio.change.subscribe((value) => {
        this.value = value;
        this.onChange(value);
        this.change.emit(value);
        this.updateRadioButtonSelections();
      });
    });

    // Listen for new radio buttons being added
    this.radioButtons.changes.subscribe(() => {
      this.updateRadioButtonSelections();
    });
  }

  private updateRadioButtonSelections() {
    if (this.radioButtons) {
      this.radioButtons.forEach((radio) => {
        radio.checked = radio.value === this._value;
        radio.disabled = this.disabled;
        radio.name = this.name;
        radio.color = this.color;
      });
    }
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.updateRadioButtonSelections();
    this.cdr.markForCheck();
  }
}
