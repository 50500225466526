import { Component, Input, OnInit } from '@angular/core';
import { MAPS_GROUP, MenuGroupType } from '../../../../map.component';
import { NgClass, NgIf } from '@angular/common';
import { ChartWarningComponent } from '../shared/chart-warning/chart-warning.component';
import { IconComponent } from '../../../../../shared/components/icon/icon.component';

export interface UpcomingGroupData {
  title: string;
  warningDisclaimer?: string;
}

@Component({
  selector: 'app-upcoming-group',
  templateUrl: './upcoming-group.component.html',
  imports: [NgIf, IconComponent, NgClass, ChartWarningComponent],
  styleUrls: [
    './upcoming-group.component.scss',
    '../../../../../app.component.scss',
  ],
})
export class SpecialHealthCareComponent implements OnInit {
  @Input() activeMenuGroup!: MenuGroupType;

  @Input() upcomingGroupData!: UpcomingGroupData;

  public isSpecialCareVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  protected readonly MAPS_GROUP = MAPS_GROUP;
}
