<mat-nav-list class="navigation-menu-panel" *ngIf="!(breakpointObserverService.isMobile | async)">
  <app-icon svgIcon="logo" class="logo-icon" routerLink="/"></app-icon>

  <div class="desktop-pointer">
<!--    <app-icon svgIcon="pointer"></app-icon>-->
  </div>

  <div class="navmenu-links">
    <a href="http://map-ai.co" target="_blank" matTooltip="Home" matTooltipPosition="right">
      <app-icon svgIcon="home"></app-icon>
    </a>

    <a #navLink routerLink="/" matTooltip="Map" matTooltipPosition="right">
      <app-icon
        svgIcon="map"
        [class.active-link]="isActiveWithQueryParams('/')"
      ></app-icon>
    </a>

    <a #navLink routerLink="comparative-location-tool" matTooltip="Comparative Location Tool" matTooltipPosition="right">
      <app-icon
        svgIcon="comparative-location"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>

    <a #navLink routerLink="reports" matTooltip="Reports" matTooltipPosition="right">
      <app-icon
        svgIcon="report"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>

    <a #navLink routerLink="info" matTooltip="Info" matTooltipPosition="right">
      <app-icon
        svgIcon="help"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>
  </div>

  <div class="user-icon-container">
    <a *ngIf="accessService.getIsAdmin()"
       #navLink
       [routerLink]="'admin'"
       matTooltip="Admin panel" matTooltipPosition="right"
    >
      <app-icon
        routerLinkActive="active-link"
        svgIcon="adminPanel"
        class="admin-icon"
      ></app-icon>
    </a>
    <a #navLink class="user-icon-circle" [routerLink]="authService.hasAccessToken() ? 'user' : 'sign-in'" [matTooltip]="authService.hasAccessToken() ? 'User profile' : 'Sign-in'" matTooltipPosition="right">
      <app-icon
        [svgIcon]="authService.hasAccessToken() ? 'user' : 'logIn'"
        class="user-icon"></app-icon>
    </a>
  </div>
</mat-nav-list>

<nav class="navigation-menu-panel-mobile" *ngIf="breakpointObserverService.isMobile | async">
  <!-- Single sliding pointer for mobile -->
  <div class="mobile-pointer">
  </div>

  <div class="navmenu-links">
    <a href="http://map-ai.co" target="_blank">
      <app-icon svgIcon="home"></app-icon>
    </a>

    <a #navLink [routerLink]="'/'">
      <app-icon
        svgIcon="map"
        [class.active-link]="isActiveWithQueryParams('/')"
      ></app-icon>
    </a>

    <a #navLink [routerLink]="authService.hasAccessToken() ? 'user' : 'sign-in'" class="user-icon">
      <app-icon
        [svgIcon]="authService.hasAccessToken() ? 'user' : 'logIn'"
        [class.active-link]="isActiveWithQueryParams('/user')"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>

    <a *ngIf="accessService.getIsAdmin()"
       #navLink
       [routerLink]="'/admin'">
      <app-icon
        svgIcon="adminPanel"
        routerLinkActive="active-link"
        class="admin-icon"
      ></app-icon>
    </a>

    <a #navLink [routerLink]="'/comparative-location-tool'">
      <app-icon
        svgIcon="comparative-location"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>

    <a #navLink [routerLink]="'/reports'">
      <app-icon
        svgIcon="report"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>

    <a #navLink [routerLink]="'/info'">
      <app-icon
        svgIcon="help"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></app-icon>
    </a>
  </div>
</nav>

