import { Component, Inject, OnInit } from '@angular/core';
import { FavoritesService } from '../favorites.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-favorite-personalization-modal',
  templateUrl: './favorite-personalization-modal.component.html',
  styleUrls: [
    './favorite-personalization-modal.component.scss',
    '../../../app.component.scss',
  ],
  imports: [FormsModule],
})
export class FavoritePersonalizationModalComponent implements OnInit {
  public cellTitle!: string;
  public cellDescription!: string;

  constructor(
    private favoritesService: FavoritesService,
    private dialogRef: MatDialogRef<FavoritePersonalizationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string; title: string; level: string }
  ) {}

  ngOnInit(): void {
    this.cellTitle = this.data.title;
  }

  public requestCreateFavoriteCell(): void {
    this.favoritesService.createFavoriteCell(
      this.data.id,
      this.cellDescription,
      this.cellTitle,
      this.data.level
    );
    this.dialogRef.close();
  }
}
