<div
    class="chart-notification-container"
    *ngIf="!selected && isToggleActive && !comingSoon"
>
    <p>
        To see the {{ chartName }} chart, please click on the required cell
        (state/county/hex) on the map
    </p>
</div>

<div
    class="chart-notification-container"
    *ngIf="selected && isToggleActive && !containsInfo && !comingSoon && (!selectedYear || noAccessibleYears)"
>
    <p>This area lacks sufficient data for graph generation</p>
</div>

<div
  class="chart-notification-container"
  *ngIf="selected && isToggleActive && !containsInfo && !comingSoon && selectedYear && !noAccessibleYears"
>
  <p>This area lacks sufficient data for {{selectedYear}} to generate graph, try another year</p>
</div>

<div class="chart-notification-container" *ngIf="comingSoon && isToggleActive">
    <p>{{ customText }}</p>
</div>
