<div 
  class="toggle-container" 
  [class.disabled]="disabled"
  (click)="toggle()"
>
  <div *ngIf="labelPosition === 'before'" class="toggle-content">
    <ng-content></ng-content>
  </div>
  
  <div class="toggle-track" [ngClass]="{ 'checked': checked }">
    <div class="toggle-thumb"></div>
  </div>
  
  <div *ngIf="labelPosition === 'after'" class="toggle-content">
    <ng-content></ng-content>
  </div>
</div> 