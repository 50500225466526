import { Pipe, PipeTransform } from '@angular/core';
import {
  FeatureType,
  UserAccessService,
} from '../../../../../user/access/user-access.service';

@Pipe({
  name: 'isFeatureAccessible',
})
export class IsFeatureAccessiblePipe implements PipeTransform {
  constructor(private accessService: UserAccessService) {}

  transform(feature: FeatureType, isPlanUpdated: boolean | null): boolean {
    if (isPlanUpdated) {
      return this.accessService.userHasAccess(feature);
    }
    return false;
  }
}
