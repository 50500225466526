import { Injectable } from '@angular/core';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import {
  FeatureStatus,
  NaturalId,
} from '../population-menu/population.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import { debounceTime, of, share } from 'rxjs';
import {
  chartToMap,
  propertyDetailsMap,
  sortBeautyData,
  sortCompetitorsData,
  sortConvenienceData,
  sortFamilyData,
  sortFinancialServicesData,
  sortHealthcareData,
  sortOutdoorData,
  sortRetirementData,
} from './PoiDistribution';
import {
  pointsMinZoom,
  POIS_LAYER,
} from '../../../../mapbox/services/layer-store.service';
import { Geometry } from 'geojson';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import { getTotalAnnotation } from '../shared/chart-utility';
import { FeatureMetaData } from '../../../../mapbox/visualization/map-coloring.service';
import { FeatureFocusService } from '../../../../feature-focus/feature-focus.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../../../../shared/services/modal.service';
import { zoomInText } from '../../../../../user/map-redirect-modal/map-redirect-modal-text';
import { GUIDE_COMPLETED } from '../../../../popup-hint/popup-hint.component';
import { Data, Layout } from 'plotly.js-dist-min';

export interface PoisChartJSON {
  distribution: POIs;
}

export interface POIs {
  [key: string]: number;
}

export type FilteredPoiChartData = { [key: string]: number };

export interface PoisProps {
  types?: string[];
  type?: string;
  color?: string;
  customType?: string;
  customTypes?: string[];
}

export interface POIsMapFeature {
  geometry: Geometry;
  properties: PoisProps;
  type: string;
}

export type PoisDataFeatures = {
  features: POIsMapFeature[];
};

export interface PoisFeatureCollection {
  data: PoisDataFeatures;
}

export interface POIsFeatureStatus extends FeatureStatus {
  containsPOIs: boolean;
  containsCompetitors: boolean;
  containsFamily: boolean;
  containsFinancialServices: boolean;
  containsRetirement: boolean;
  containsHealthcare: boolean;
  containsOutdoor: boolean;
  containsBeauty: boolean;
  containsEntertainmentAndConvenience: boolean;
  activePoisCharts?: string[];
}

type FeatureStatusKeys = keyof POIsFeatureStatus;

const containsCompetitorsKey: FeatureStatusKeys = 'containsCompetitors';
const containsFamilyKey: FeatureStatusKeys = 'containsFamily';
const containsFinancialServicesKey: FeatureStatusKeys =
  'containsFinancialServices';
const containsRetirementKey: FeatureStatusKeys = 'containsRetirement';
const containsHealthcareKey: FeatureStatusKeys = 'containsHealthcare';
const containsEntertainmentAndConvenienceKey: FeatureStatusKeys =
  'containsEntertainmentAndConvenience';
const containsOutdoorKey: FeatureStatusKeys = 'containsOutdoor';
const containsBeautyKey: FeatureStatusKeys = 'containsBeauty';

@Injectable({
  providedIn: 'root',
})
export class PoisService {
  private readonly naturalIds: NaturalId[] = [];

  private layersByType: Map<
    string,
    { layerId: string; features: POIsMapFeature[] }
  > = new Map();
  private featureIdToPoisCollection: Map<NaturalId, POIsMapFeature[]> =
    new Map();
  private featureToPoisChartList: Map<NaturalId, PoisChartJSON> = new Map();

  private wasPoiModalShown: boolean = false;

  // public activePoisCharts: string[] = []

  public competitorsChart: {
    data: Data;
    layout: Layout;
  } = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public entertainmentAndConvenienceChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public healthcareChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public retirementChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public financialServicesChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public familyChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public outdoorChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public beautyChart = {
    data: [] as Data,
    layout: {} as Layout,
  };

  public readonly featureStatus: POIsFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsPOIs: false,
    containsCompetitors: false,
    containsFamily: false,
    containsFinancialServices: false,
    containsRetirement: false,
    containsHealthcare: false,
    containsOutdoor: false,
    containsBeauty: false,
    containsEntertainmentAndConvenience: false,
    activePoisCharts: [],
  };

  constructor(
    private http: MapHttpService,
    private featureFocusService: FeatureFocusService,
    private mapBoxService: MapBoxService,
    private modalService: ModalService,
    private toast: ToastrService
  ) {}

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    this.featureStatus.isSelected = true;

    const featureId: NaturalId = feature.properties!.external_id;

    if (this.featureToPoisChartList.has(featureId)) {
      this.handleFillCharts(
        this.featureToPoisChartList.get(featureId)!.distribution
      );
    } else {
      this.http.getPoisChartData(featureId).subscribe(
        (data: JsonResponse<PoisChartJSON>) => {
          if (data.object.distribution) {
            this.featureStatus.naturalId = featureId;
            this.featureStatus.containsPOIs = true;
          } else {
            this.featureStatus.containsPOIs = false;
            throw new Error('No POIs fetched');
          }

          this.featureToPoisChartList.set(featureId, data.object);

          this.handleFillCharts(data.object.distribution);
        },
        (error) => {
          this.featureStatus.isSelected = true;
          this.featureStatus.containsPOIs = false;
          console.error('ERROR FETCHING POIs', error);
          return of(null);
        }
      );
    }
  }

  private handleFillCharts(data: POIs): void {
    this.fillCompetitorsChart(data);
    this.fillHealthcareChart(data);
    this.fillFamilyChart(data);
    this.fillRetirementChart(data);
    this.fillFinancialServicesChart(data);
    this.fillEntertainmentAndConvenienceChart(data);
    this.fillOutdoorChart(data);
    this.fillBeautyChart(data);
  }

  public handlePoisOnMap(): void {
    // Cannot initialize map here from constructor
    if (this.mapBoxService.map === undefined) {
      return;
    }

    if (this.mapBoxService.map.getZoom() < pointsMinZoom) {
      if (
        !this.wasPoiModalShown &&
        this.mapBoxService.map.getZoom() <= pointsMinZoom &&
        localStorage.getItem(GUIDE_COMPLETED)
      ) {
        this.modalService.openModal(zoomInText);
        this.wasPoiModalShown = true;
      }
      return;
    }

    this.naturalIds.length = 0;

    const naturalIdToFeatureMap: Map<string, FeatureMetaData> = new Map();

    const currentFocusedFeatures = this.featureFocusService
      .currentFocusedFeatures()
      .getFeatures();
    currentFocusedFeatures.forEach((feature) => {
      const featureMetaData: FeatureMetaData = {
        id: feature.id,
        source: feature.source,
        sourceLayer: feature.sourceLayer,
      };

      const state = this.mapBoxService.map.getFeatureState(featureMetaData);

      if (!state[`${POIS_LAYER}Requested`] && feature.properties!.external_id) {
        this.naturalIds.push(feature.properties!.external_id);
        naturalIdToFeatureMap.set(
          feature.properties!.external_id,
          featureMetaData
        );
        this.mapBoxService.map.setFeatureState(feature!, {
          [`${POIS_LAYER}Requested`]: true,
        });
      }
    });

    if (!this.naturalIds.length) {
      return;
    }

    this.http
      .getPoisGeoJson(Array.from(new Set(this.naturalIds)))
      .pipe(debounceTime(350), share())
      .subscribe(
        (data: JsonResponse<PoisFeatureCollection>) => {
          this.drawPoisLayer(data.object.data.features);
          this.handlePoiLayerVisibility();
        },
        (error) => {
          this.toast.error(
            'There was error while rendering poi, please try later'
          );

          // Reset POIS_LAYERRequested state for the features in case of an error
          this.naturalIds.forEach((naturalId) => {
            const featureMetaData = naturalIdToFeatureMap.get(naturalId);
            if (featureMetaData) {
              this.mapBoxService.map.setFeatureState(featureMetaData, {
                [`${POIS_LAYER}Requested`]: false,
              });
            }
          });
        }
      );
  }

  private drawPoisLayer(data: POIsMapFeature[]): void {
    // Group features by type
    const featuresByType = new Map<string, POIsMapFeature[]>();

    const featureToTypes = new Map<POIsMapFeature, Set<string>>();

    data.forEach((feature) => {
      const types = feature.properties.type?.includes(';')
        ? feature.properties.type.split(';')
        : [feature.properties.type];

      types.forEach((type) => {
        propertyDetailsMap.forEach((details, mappedType) => {
          if (type && details.regexp.test(type)) {
            let featuresList = featuresByType.get(mappedType) || [];
            // Initialize or update the set of types for this feature
            let typesSet = featureToTypes.get(feature) || new Set<string>();
            typesSet.add(mappedType);
            featureToTypes.set(feature, typesSet);

            // Update featuresList with the feature if it's not already included
            if (!featuresList.includes(feature)) {
              featuresList.push(feature);
            }
            featuresByType.set(mappedType, featuresList);
          }
        });
      });
    });

    // Now, update each feature with its accumulated customTypes
    featuresByType.forEach((features, mappedType) => {
      features.forEach((feature) => {
        const typesSet = featureToTypes.get(feature);
        if (typesSet) {
          feature.properties.customTypes = Array.from(typesSet);
        }
      });
    });

    chartToMap.forEach((map, chart) => {
      const isChartActive =
        this.featureStatus.activePoisCharts!.includes(chart);
      map.forEach((details, type) => {
        featuresByType.forEach((features, featureType) => {
          if (type === featureType) {
            const layerId = `${POIS_LAYER}_${type}`;

            if (this.layersByType.has(type)) {
              // Update existing layer with new data
              this.appendPois(features, layerId);
              this.mapBoxService.map.setLayoutProperty(
                layerId,
                'visibility',
                isChartActive ? 'visible' : 'none'
              );
            } else {
              // Create new layer if there's no layer of such type
              this.addPoiLayer(features, layerId, type);
              this.layersByType.set(type, { layerId, features });
            }
          }
        });
      });
    });
  }

  public handlePoiLayerVisibility(): void {
    if (this.mapBoxService.map === undefined) {
      return;
    }

    chartToMap.forEach((map, chart) => {
      if (this.featureStatus.activePoisCharts!.includes(chart)) {
        map.forEach((_, type) => {
          const layerId = `${POIS_LAYER}_${type}`;
          if (this.mapBoxService.map.getLayer(layerId)) {
            this.mapBoxService.map.setLayoutProperty(
              layerId,
              'visibility',
              'visible'
            );
          }
        });
      } else {
        this.hidePoiLayer(chart);
      }
    });
  }

  private hidePoiLayer(chartToHide: string): void {
    const poisToKeep: string[] = [];

    // Make an array of poi types that are used by other toggles to not hide it from map
    this.featureStatus.activePoisCharts!.forEach((chart) => {
      chartToMap.get(chart)!.forEach((_, type) => {
        poisToKeep.push(type);
      });
    });

    chartToMap.get(chartToHide)!.forEach((_, type) => {
      if (!poisToKeep.includes(type)) {
        const layerId = `${POIS_LAYER}_${type}`;
        if (this.mapBoxService.map.getLayer(layerId)) {
          this.mapBoxService.map.setLayoutProperty(
            layerId,
            'visibility',
            'none'
          );
        }
      }
    });
  }

  private appendPois(pois: POIsMapFeature[], layerId: string): void {
    const source: mapboxgl.AnySourceImpl =
      this.mapBoxService.map.getSource(layerId);
    //@ts-ignore
    const currentData = source._data;

    // Append new features to the current data
    currentData.features = [...currentData.features, ...pois];

    // @ts-ignore
    source.setData(currentData);
  }

  private addPoiLayer(
    features: POIsMapFeature[],
    layerId: string,
    type: string
  ): void {
    const featureCollection = {
      type: 'FeatureCollection',
      features: features,
    };

    this.mapBoxService.map.addSource(layerId, {
      type: 'geojson',
      //@ts-ignore
      data: featureCollection,
    });

    this.mapBoxService.map.addLayer({
      id: layerId,
      type: 'circle',
      source: layerId,
      minzoom: pointsMinZoom,
      paint: {
        'circle-radius': {
          base: 2, // This is the base radius at zoom 0
          stops: [
            [11, 3], // [ zoom, circle-radius ]
            [12, 4],
            [13, 7],
            [14, 8],
            [15, 9],
            [16, 10],
          ],
        },
        'circle-color': this.getPoiLayerColor(type),
      },
    });

    this.handlePoisEvents(layerId);
  }

  private handlePoisEvents(layerId: string): void {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'bottom',
      className: 'poi-hover-popup',
      offset: [0, -10],
    });

    this.mapBoxService.map.on('mouseenter', layerId, (e) => {
      this.mapBoxService.map.getCanvas().style.cursor = 'pointer';
      const feature = e.features![0];
      const coordinates = e.lngLat;

      popup
        .setLngLat(coordinates)
        .setHTML(this.getPoiPopupHTML(feature))
        .addTo(this.mapBoxService.map);
    });

    this.mapBoxService.map.on('mouseleave', layerId, (e) => {
      this.mapBoxService.map.getCanvas().style.cursor = '';
      // Apply timeout to prevent flickering while hovering over pois, new popup is always overlaps older ones
      setTimeout(() => {
        popup.remove();
      }, 150);
    });
  }

  private getPoiPopupHTML(feature: MapboxGeoJSONFeature): string {
    let spans: string[] = [];

    if (feature.properties && feature.properties.customTypes) {
      const types = JSON.parse(feature.properties!.customTypes);
      spans = types.map((type: string, index: number) => {
        return `<span>${type}${types.length > 1 && index !== types.length - 1 ? ', ' : ' '}</span>`;
      });
    }

    let spansContent = spans.length > 0 ? spans.join(' ') : '';

    if (feature.properties && feature.properties.name) {
      return `<h3>${feature.properties!.name}</h3> ${spansContent}`;
    } else {
      return `${spansContent}`;
    }
  }

  private fillBeautyChart(data: POIs): void {
    this.featureStatus.containsBeauty = true;
    const sortedData: FilteredPoiChartData = sortBeautyData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsBeautyKey);

    const labels = Object.keys(sortedData);

    this.beautyChart.data = this.getPOIsChartData(values, labels);

    this.beautyChart.layout = this.getPOIsChartLayout(values);
  }

  private fillOutdoorChart(data: POIs): void {
    this.featureStatus.containsOutdoor = true;
    const sortedData: FilteredPoiChartData = sortOutdoorData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsOutdoorKey);

    const labels = Object.keys(sortedData);

    this.outdoorChart.data = this.getPOIsChartData(values, labels);

    this.outdoorChart.layout = this.getPOIsChartLayout(values);
  }

  private fillEntertainmentAndConvenienceChart(data: POIs): void {
    this.featureStatus.containsEntertainmentAndConvenience = true;
    const sortedData: FilteredPoiChartData = sortConvenienceData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsEntertainmentAndConvenienceKey);

    const labels = Object.keys(sortedData);

    this.entertainmentAndConvenienceChart.data = this.getPOIsChartData(
      values,
      labels
    );

    this.entertainmentAndConvenienceChart.layout =
      this.getPOIsChartLayout(values);
  }

  private fillHealthcareChart(data: POIs): void {
    this.featureStatus.containsHealthcare = true;
    const sortedData: FilteredPoiChartData = sortHealthcareData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsHealthcareKey);

    const labels = Object.keys(sortedData);

    this.healthcareChart.data = this.getPOIsChartData(values, labels);

    this.healthcareChart.layout = this.getPOIsChartLayout(values);
  }

  private fillRetirementChart(data: POIs): void {
    this.featureStatus.containsRetirement = true;
    const sortedData: FilteredPoiChartData = sortRetirementData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsRetirementKey);

    const labels = Object.keys(sortedData);

    this.retirementChart.data = this.getPOIsChartData(values, labels);

    this.retirementChart.layout = this.getPOIsChartLayout(values);
  }

  private fillFinancialServicesChart(data: POIs): void {
    this.featureStatus.containsFinancialServices = true;
    const sortedData: FilteredPoiChartData = sortFinancialServicesData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsFinancialServicesKey);

    const labels = Object.keys(sortedData);

    this.financialServicesChart.data = this.getPOIsChartData(values, labels);

    this.financialServicesChart.layout = this.getPOIsChartLayout(values);
  }

  private fillFamilyChart(data: POIs): void {
    this.featureStatus.containsFamily = true;
    const sortedData: FilteredPoiChartData = sortFamilyData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsFamilyKey);

    const labels = Object.keys(sortedData);

    this.familyChart.data = this.getPOIsChartData(values, labels);

    this.familyChart.layout = this.getPOIsChartLayout(values);
  }

  private fillCompetitorsChart(data: POIs): void {
    this.featureStatus.containsCompetitors = true;
    const sortedData: FilteredPoiChartData = sortCompetitorsData(data);

    const values = Object.values(sortedData);

    this.handleBadData(values, containsCompetitorsKey);

    const labels = Object.keys(sortedData);

    this.competitorsChart.data = this.getPOIsChartData(values, labels);

    this.competitorsChart.layout = this.getPOIsChartLayout(values);
  }

  private handleBadData(
    values: number[],
    containsChartType: keyof POIsFeatureStatus
  ): void {
    const maxValue = Math.max(
      ...Object.values(values).map((el) => Math.floor(el))
    );

    if (maxValue <= 0 || !maxValue || maxValue === -Infinity) {
      // @ts-ignore
      this.featureStatus[containsChartType] = false;
    }
  }

  private getPOIsChartData(values: number[], labels: string[]): Data {
    const colorsForChart = labels.map((label) => {
      const propertyDetails = propertyDetailsMap.get(label);
      return propertyDetails ? propertyDetails.color : '#FFFFFF'; // Default color if not found
    });

    return [
      {
        values: values,
        labels: labels,
        type: 'pie',
        hole: 0.3,
        textposition: 'inside',
        hoverinfo: 'label+percent+value',
        hovertemplate:
          '&nbsp;&nbsp;%{label}: %{value} (%{percent})&nbsp;&nbsp;<extra></extra>',
        marker: {
          colors: colorsForChart,
        },
      },
    ] as Data;
  }

  private getPOIsChartLayout(values: number[]): Layout {
    return {
      autosize: true,
      font: {
        family: 'Rising Sun, sans-serif',
      },
      height: 315,
      width: 380,
      margin: { t: 20, l: 5 },
      annotations: values ? getTotalAnnotation(values) : undefined,
      legend: {
        x: 1,
        y: 1,
        traceorder: 'normal',
        orientation: 'v',
      },
      yaxis: {
        title: {
          text: 'Poi type',
          font: {
            size: 1,
            color: '#fff',
          },
        },
      },
      xaxis: {
        title: {
          text: 'Poi',
          font: {
            size: 1,
            color: '#fff',
          },
        },
      },
    } as Layout;
  }

  private getPoiLayerColor(matchedType: string): string {
    let color: string = '#FFFFFF';
    if (matchedType && propertyDetailsMap.has(matchedType)) {
      const propertyDetails = propertyDetailsMap.get(matchedType);
      color = propertyDetails ? propertyDetails.color : '';
    }

    return color;
  }
}
