import { CellGeoData } from '../../map/mapbox/services/geocoder-cache';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  STATE_LEVEL_LAYER,
} from '../../map/mapbox/services/layer-store.service';

export function geocoderContextToString(
  cellData: CellGeoData,
  levelLayer?: string,
  defaultName?: string
): string {
  let title, place, district, stateShort, postcode, neighborhood, stateFull;

  cellData.context.forEach((el) => {
    if (el.id.includes('place')) place = el.text;
    if (el.id.includes('district')) district = el.text;
    if (el.id.includes('postcode')) postcode = el.text;
    if (el.id.includes('neighborhood')) neighborhood = el.text;
    if (el.id.includes('region')) {
      stateShort = el.short_code;
      stateFull = el.text;
    }
  });

  title = cellData.text || '';
  if (place) title += `, ${place}`;
  if (stateShort) title += `, ${stateShort}`;

  if (!levelLayer && !defaultName) {
    return title;
  }

  if (levelLayer === STATE_LEVEL_LAYER && defaultName) title = defaultName;

  if (postcode && levelLayer === H3_RES7_LEVEL_LAYER) title += `, ${postcode}`;

  if (levelLayer === COUNTY_LEVEL_LAYER && stateFull) {
    title = `${defaultName}, ${stateFull}`;
  }

  return title;
}
