import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';
import { FocusedFeatures } from './focused-features';
import { UserAccessService } from '../../user/access/user-access.service';
import { LayerStoreService } from '../mapbox/services/layer-store.service';
import { SubscriptionsService } from '../../user/subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFocusService {
  focusedByUserSelection = false;

  /**
   * For each feature going through this service,
   * state `focused` is set to `true` for focused
   * and `false` for unfocused.
   */
  public static readonly focusedFeatureState = 'focused';

  /**
   * Focused features are features for current analysis,
   * so colors are counted for them.
   * In other words, that are `selected features`.
   */
  private readonly _focusedFeatures: BehaviorSubject<FocusedFeatures> =
    new BehaviorSubject<FocusedFeatures>(new FocusedFeatures([]));

  public readonly focusedFeatures: Observable<FocusedFeatures> =
    this._focusedFeatures.asObservable();

  public currentFocusedFeatures(): FocusedFeatures {
    return this._focusedFeatures.value;
  }

  constructor(
    private userAccessService: UserAccessService,
    private layerStore: LayerStoreService,
    private subscriptionsService: SubscriptionsService
  ) {}

  /**
   * Focus on visible features.
   * Does nothing if features are focused by user.
   */
  public focusRenderedFeatures(map: mapboxgl.Map, activeLevel: string): void {
    if (this.focusedByUserSelection) {
      return;
    }

    let isLayerAndSourceExist = false;

    try {
      isLayerAndSourceExist =
        !!map.getLayer(activeLevel) && !!map.getSource(activeLevel);
    } catch (e) {
      isLayerAndSourceExist = false;
    }
    // if active layer does not exist yet (not fully loaded, for example)
    if (!isLayerAndSourceExist) {
      return;
    }
    // TODO: do nothing if active source is State
    // TODO: duplicated features?
    const visibleFeatures = this.getVisibleFeatures(map, activeLevel);

    this.markFeaturesAs(visibleFeatures, true, map);
    this._focusedFeatures.next(new FocusedFeatures(visibleFeatures));
  }

  public focusFeaturesByUserSelection(
    features: MapboxGeoJSONFeature[],
    map: mapboxgl.Map
  ) {
    this.markFeaturesAs(features, true, map);
    // mark other features as not focused
    this.markFeaturesAs(this.getFeaturesWithout(features, map), false, map);
    this._focusedFeatures.next(new FocusedFeatures(features));
    this.focusedByUserSelection = true;
  }

  private getVisibleFeatures(
    map: mapboxgl.Map,
    level: string | undefined
  ): MapboxGeoJSONFeature[] {
    // Prevent inaccessible features from being counted as focused to not request them from backend and not show values on colorScale
    // if (this.userAccessService.getIsTrial() && this.layerStore.isCurrentLevelCells()) {
    //   return map.queryRenderedFeatures(undefined, {
    //     layers: level === undefined ? undefined : [level],
    //   }).filter(el => this.subscriptionsService.getAccessibleFeatures().features.includes(el.properties!.external_id))
    // }

    return map.queryRenderedFeatures(undefined, {
      layers: level === undefined ? undefined : [level],
    });
  }

  /**
   * Get not-focused features for layer
   * by subtracting focused from all.
   * Probably needed to be refactored to mapboxService
   */
  private getFeaturesWithout(
    without: MapboxGeoJSONFeature[],
    map: mapboxgl.Map
  ): MapboxGeoJSONFeature[] {
    const featureLayer = without.length >= 1 ? without[0].sourceLayer : '';
    const visible = this.getVisibleFeatures(map, featureLayer);
    const withoutIds = without.map((w) => w.id);
    return visible.filter((v) => !withoutIds.includes(v.id));
  }

  private markFeaturesAs(
    features: MapboxGeoJSONFeature[],
    focused: boolean,
    map: mapboxgl.Map
  ) {
    features.forEach((f) => map.setFeatureState(f, { focused: focused }));
  }

  public defocusFeaturesByUserSelection(
    map: mapboxgl.Map,
    activeLevel: string
  ) {
    this.focusedByUserSelection = false;
    this.focusRenderedFeatures(map, activeLevel);
  }
}
