import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { MapHttpService } from './map-http.service';
import { SelectedCellService } from './selected-cell.service';
import {
  featuresToCompareToNationalPopup,
  featuresToMultiplyBy100,
  LIFE_EXPECTANCY,
  trendLayers,
} from '../../../shared/types/feature-data-type';
import { Feature } from 'geojson';

export interface ComparisonDataJSON {
  [key: string]: Data;
}

export interface Data {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root',
})
export class ComparisonService {
  constructor(
    private mapHttpService: MapHttpService,
    private selectedCellService: SelectedCellService
  ) {}

  public getAndCalculateComparisonData(
    properties: Feature['properties']
  ): Observable<Feature['properties']> {
    const selectedCellGeoId = this.selectedCellService.getSelectedCellGeoId();
    if (!selectedCellGeoId) {
      return of({});
    }

    const missingFeatures = featuresToCompareToNationalPopup.filter(
      (feature) => {
        const storedData = localStorage.getItem(feature + '_by_country');
        return !storedData;
      }
    );

    if (missingFeatures.length === 0) {
      // All data is already in local storage, calculate comparison data
      return of(this.calculateComparisonDataFromStorage(properties));
    } else {
      // Fetch missing data from the server and then calculate comparison data
      return this.mapHttpService
        .getComparisonData(selectedCellGeoId, missingFeatures)
        .pipe(
          map((data) => {
            for (const feature of missingFeatures) {
              const propName = feature.toUpperCase();
              if (data.object.COUNTRY[propName]) {
                const value = data.object.COUNTRY[propName];
                this.writePropertyDataToStorage({ key: propName, value });
              }
            }
            return this.calculateComparisonDataFromStorage(properties);
          })
        );
    }
  }

  private calculateComparisonData(
    prop: { key: string; value: any },
    data: ComparisonDataJSON
  ): string | null {
    let selectedFeatureValue = prop.value;
    let countryFeatureValue = data['COUNTRY'][prop.key];

    let isFeatureValueAbsent = selectedFeatureValue === null;

    // Multiply values that are fetched in 0 to 1 format but should be presented as %
    if (featuresToMultiplyBy100.includes(prop.key)) {
      selectedFeatureValue = selectedFeatureValue * 100;
      countryFeatureValue = countryFeatureValue * 100;

      if (isFeatureValueAbsent)
        return `National average ${countryFeatureValue.toFixed(1)}%`;
    }

    if (!countryFeatureValue) {
      return null;
    }

    // trends are usually small, so seeing 0.5% compared to 1.5%
    // is more informative than seeing 300% difference
    // (maybe it's wrong, but let's try)
    if (
      trendLayers.includes(prop.key) ||
      selectedFeatureValue * countryFeatureValue < 0
    ) {
      if (isFeatureValueAbsent)
        return `National average ${countryFeatureValue.toFixed(1)}%`;

      return `while national average is ${countryFeatureValue.toFixed(1)} %`;
    }

    if (prop.key === LIFE_EXPECTANCY) {
      if (isFeatureValueAbsent)
        return `National average ${Math.round(countryFeatureValue)} years`;

      const difference = Math.round(selectedFeatureValue - countryFeatureValue);
      const yearsForm = Math.abs(difference) > 1 ? 'years' : 'year';

      if (difference === 0) return 'same as national average';

      return difference > 0
        ? `${difference.toFixed(0)} ${yearsForm} longer than national average`
        : `${Math.abs(difference).toFixed(0)} ${yearsForm} shorter than national average`;
    }

    if (isFeatureValueAbsent)
      return `National average ${countryFeatureValue.toFixed(1)}`;

    const ratio = selectedFeatureValue / countryFeatureValue;
    const percentageDifference = Math.abs(
      ((selectedFeatureValue - countryFeatureValue) / countryFeatureValue) * 100
    ).toFixed(0);

    if (
      selectedFeatureValue === countryFeatureValue ||
      percentageDifference === '0'
    ) {
      return 'same as national average';
    }

    if (ratio <= 0.5) {
      return `${(1 / ratio).toFixed(1)} times less than national average`;
    } else if (ratio >= 0.5 && ratio < 1) {
      return `${percentageDifference}% less than national average`;
    } else if (ratio >= 1 && ratio < 2) {
      return `${percentageDifference}% more than national average`;
    } else {
      return `${ratio.toFixed(1)} times more than national average`;
    }
  }

  private calculateComparisonDataFromStorage(
    properties: Feature['properties']
  ): Feature['properties'] {
    const result: Feature['properties'] = {};

    for (let property in properties) {
      const propName = property.toUpperCase();
      const storedData = this.getPropertyFromStorage(propName);
      if (storedData) {
        const prop = { key: propName, value: properties[property] };
        const data: ComparisonDataJSON = {
          COUNTRY: { [propName]: Number(storedData) },
        };
        result[property] = this.calculateComparisonData(prop, data);
      }
    }

    return result;
  }

  public getPropertyFromStorage(layer: string): string | null {
    return localStorage.getItem(layer.toUpperCase() + '_by_country');
  }

  private writePropertyDataToStorage(prop: { key: string; value: any }): void {
    localStorage.setItem(prop.key + '_by_country', prop.value);
  }
}
