import { Injectable } from '@angular/core';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { NaturalId } from '../../menu/right-menu/layers-menu/population-menu/population.service';
import { ARBITRARY_CELL, LayerStoreService } from './layer-store.service';
import { MapBoxService } from '../mapbox.service';
import { BehaviorSubject } from 'rxjs';
import { convertLayerIdToCellType } from '../../../shared/util/layerIdToCellType';

export const SELECTED_POLYGON = 'SELECTED_POLYGON';

@Injectable({
  providedIn: 'root',
})
export class SelectedCellService {

  public selectedCellGeoId: BehaviorSubject<NaturalId | null>  = new BehaviorSubject<NaturalId | null>(null);

  private selectedFeature: MapboxGeoJSONFeature | null = null;
  private hoveredFeature: MapboxGeoJSONFeature | null = null;

  constructor(private layerStoreService: LayerStoreService,
              private mapboxService: MapBoxService) {
    this.layerStoreService.activeLevel.subscribe(level => {
      this.onLevelChange()
    });
  }

  public selectCell(feature: MapboxGeoJSONFeature): void {
    // deselect previous cell
    this.deselectCell();
    this.selectedFeature = feature;
    this.selectedCellGeoId.next(feature.properties!.external_id);

    if (feature.layer.id !== ARBITRARY_CELL) {
      this.mapboxService.map.addLayer({
        id: SELECTED_POLYGON,
        type: 'line',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'line-color': '#695dff',
          'line-width': 2,
        },
      });
    }
  }


  private onLevelChange() {
    this.deselectCell();
  }

  public isCellSelected(): boolean {
    return this.selectedCellGeoId.getValue() !== null;
  }

  public getSelectedCellGeoId(): NaturalId | null {
    return this.selectedCellGeoId.getValue();
  }

  public getSelectedFeature(): MapboxGeoJSONFeature | null {
    return this.selectedFeature;
  }

  public getCellType(): string | undefined {
    const layerId = this.getSelectedFeature()?.layer.id ?? this.layerStoreService.activeLevel.getValue()

    return convertLayerIdToCellType(layerId)
  }

  public hoverFeature(feature: MapboxGeoJSONFeature): void {
    this.hoveredFeature = feature
  }

  public unhoverFeature(): void {
    this.hoveredFeature = null
  }

  public getHoveredFeature(): MapboxGeoJSONFeature | null {
    return this.hoveredFeature
  }

  public deselectCell() {
    this.selectedCellGeoId.next(null);
    this.selectedFeature = null;

    // Use try catch as when we move from main map we use map.remove() which doesnt cast "map" to falsy, so it exists but
    // calling a method from this object leads to error which created error in "if (map && map.getLayer)" as map counted truthy but map.getLayer is err
    let layerExists = false;
    try {
      layerExists = !!this.mapboxService.map.getLayer(SELECTED_POLYGON);
    } catch (e) {
      layerExists = false;
    }

    if (layerExists) {
      this.mapboxService.map.removeLayer(SELECTED_POLYGON);
      this.mapboxService.map.removeSource(SELECTED_POLYGON);
    }
  }
}
