import { Pipe, PipeTransform } from '@angular/core';

export type KeyValue = { key: string; value: any };

@Pipe({
  name: 'objectToKeyValue',
})
export class ObjectToKeyValuePipe implements PipeTransform {
  transform(value: { [key: string]: any }, specificKey?: string): KeyValue[] {
    if (!value) {
      return [];
    }
    if (specificKey) {
      const specificValue = value[specificKey];
      if (specificValue !== undefined) {
        return [{ key: specificKey, value: specificValue }];
      } else {
        return [];
      }
    }
    return Object.keys(value).map((key) => ({ key: key, value: value[key] }));
  }
}
