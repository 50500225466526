import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartControlBlock } from '../../menu-group/menu-group.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FeatureStatus } from '../../population-menu/population.service';
import { UserAccessService } from '../../../../../../user/access/user-access.service';
import { NgClass, NgIf } from '@angular/common';
import { FeatureTooltipComponent } from '../feature-tooltip/feature-tooltip.component';
import { ChartYearSelectionComponent } from '../chart-year-selection/chart-year-selection.component';
import { PlotlyChartComponent } from '../plotly-chart/plotly-chart.component';
import { ChartWarningComponent } from '../chart-warning/chart-warning.component';
import { CustomSlideToggleComponent } from '../../../../../../shared/components/custom-slide-toggle/custom-slide-toggle.component';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { MapModalData } from '../../../../../../user/map-redirect-modal/map-redirect-modal.component';

@Component({
  selector: 'app-chart-block',
  templateUrl: './chart-block.component.html',
  imports: [
    ReactiveFormsModule,
    NgClass,
    FeatureTooltipComponent,
    NgIf,
    ChartYearSelectionComponent,
    PlotlyChartComponent,
    ChartWarningComponent,
    CustomSlideToggleComponent,
  ],
  styleUrls: [
    './chart-block.component.scss',
    '../../../../../../app.component.scss',
  ],
})
export class ChartBlockComponent implements OnInit {
  @Output() changeSelectedYear = new EventEmitter<{
    year: number;
    usedFor: string;
  }>();

  @Input() chart!: ChartControlBlock;
  @Input() toggle!: FormControl;
  @Input() featureStatus!: FeatureStatus;
  @Input() disabled!: boolean;

  public readonly defaultChartStyle = { height: '430px', 'margin-top': '0px' };

  public wasYearSelected = true;

  constructor(
    private accessService: UserAccessService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    if (this.toggle) {
      this.toggle.valueChanges.subscribe((value) => {
        if (value) {
          if (!this.accessService.checkAccessIfUnauthorized()) {
            return;
          }
        }
      });
    }
  }

  public onSelectedYearChange(data: { year: number; usedFor: string }): void {
    this.changeSelectedYear.emit(data);
  }

  public handleTooltipModalOpen(modalData: MapModalData): void {
    this.modalService.openModal(modalData);
  }
}
