<div class="app-radio-layout">
  <div class="app-radio-inner-container">
    <!-- The actual radio button -->
    <div class="app-radio-outer-circle"></div>
    <div class="app-radio-inner-circle"></div>

    <!-- Custom ripple effect -->
    <div class="app-radio-ripple" [class.app-radio-ripple-active]="rippleActive"></div>

    <!-- Hidden native input for accessibility -->
    <input #input
           type="radio"
           class="app-radio-input cdk-visually-hidden"
           [id]="id"
           [required]="required"
           [checked]="checked"
           [disabled]="disabled"
           [name]="name"
           [value]="value"
           [attr.aria-checked]="checked"
           [attr.aria-label]="ariaLabel || null"
           [attr.aria-labelledby]="ariaLabelledby"
           [tabIndex]="tabIndex"
           (change)="_onInputClick()"
           (click)="_onInputClick($event)">
  </div>

  <div class="app-radio-content">
    <ng-content></ng-content>
  </div>
</div>
