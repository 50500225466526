import { Component, OnInit } from '@angular/core';
import { ActiveToolService, MapTools } from '../../../../active-tool.service';
import { MultipleFeaturesSelectionService } from './multiple-features-selection.service';
import { ComparativeLocationService } from '../../../../../comparative-location-tool/comparative-location.service';
import { Subject, takeUntil } from 'rxjs';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '../../../../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../../../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-multiple-features-selection',
  templateUrl: './multiple-features-selection.component.html',
  styleUrls: [
    './multiple-features-selection.component.scss',
    '../selection-tool.component.scss',
    '../../../../../app.component.scss',
  ],
  imports: [NgClass, IconComponent, NgIf, NgForOf, IconButtonDirective],
})
export class MultipleFeaturesSelectionComponent implements OnInit {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activeToolService: ActiveToolService,
    public multipleFeatureSelectionService: MultipleFeaturesSelectionService,
    private comparativeLocationService: ComparativeLocationService
  ) {}

  ngOnInit() {
    this.activeToolService.activeTool
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeTool) => {
        if (activeTool === MapTools.MULTIPLE_FEATURES_SELECTION_TOOL) {
          this.multipleFeatureSelectionService.isSelectionActive = true;
        } else {
          this.multipleFeatureSelectionService.isSelectionActive = false;
          this.multipleFeatureSelectionService.selectedFeatures.length = 0;
        }
      });
  }

  public handleCompare(): void {
    this.comparativeLocationService.handleCompareRedirect();
  }

  public closeSelection(): void {
    this.multipleFeatureSelectionService.isSelectionActive = false;
  }

  public toggleRadiusSelection(): void {
    this.multipleFeatureSelectionService.isSelectionActive =
      !this.multipleFeatureSelectionService.isSelectionActive;
    const checked = this.multipleFeatureSelectionService.isSelectionActive;

    if (checked) {
      this.activeToolService.activeTool.next(
        MapTools.MULTIPLE_FEATURES_SELECTION_TOOL
      );
    } else {
      this.activeToolService.activeTool.next(MapTools.NONE);
      this.multipleFeatureSelectionService.deselectAllFeatures();
    }
  }
}
