import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MapStyleServiceService } from './map-style-service.service';
import { MAP_STYLE, STYLE_LIGHT } from '../mapbox/services/layer-store.service';
import {
  CustomSlideToggleComponent,
  SlideToggleChange,
} from '../../shared/components/custom-slide-toggle/custom-slide-toggle.component';

@Component({
  selector: 'app-map-style-selector',
  templateUrl: './map-style-selector.component.html',
  styleUrls: ['./map-style-selector.component.scss'],
  imports: [CustomSlideToggleComponent],
})
export class MapStyleSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('toggle') toggleRef!: ElementRef;

  constructor(
    public mapStyleService: MapStyleServiceService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    (this.toggleRef as any).checked =
      localStorage.getItem(MAP_STYLE) === STYLE_LIGHT;
    this.cdr.detectChanges();
  }

  public toggleStyle(event: SlideToggleChange): void {
    this.mapStyleService.toggleMapStyle(event.checked);
  }
}
