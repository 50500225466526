<button
    (click)="toggleRadiusSelection()"
    class="radius-toggle-btn"
    [ngClass]="isCircleSelectionActive ? 'selection-active' : ''"
>
    <app-icon
        svgIcon="radiusButton"
    >
    </app-icon>
</button>

<div *ngIf="isCircleSelectionActive" class="circle-slider-container">
    <h4>Radius</h4>
    <mat-slider
        class="cirlce-radius-slider"
        max="50"
        min="0.5"
        step="0.5"
        style="width: 100%"
  >
      <input (valueChange)="changeSelectionRadius($event)"
             matSliderThumb
             [(ngModel)]="circleRadius"
      >
    </mat-slider>
  <p>{{ circleRadius }} mile</p>

  <div class="button-wrapper" *ngIf="(combinedCellService.cellCreationStatus | async) as status">
    <button (click)="handleRequestCombinedCell()"
            [ngClass]="status === 'in-progress' ? 'loading' : (status === 'error' ? 'error' : '')"
            class="gradient-button"
            [matTooltip]="status === 'in-progress' ? 'Loading may take some time' : (status === 'error' ? '' :'Click the point on the map')"
            [matTooltipPosition]="'right'"
            [disabled]="!selectionService.isCircleRendered"
    >{{ status === 'error' ? 'Error, try again' :
      (status === 'in-progress' ? 'Please wait' : 'Combine data')
      }}</button>

    <button *ngIf="selectionService.isCircleRendered"
            (click)="handleSelectNewLocation()"
            class="white-button-gradient reset-cell-button"
    >Reset radius</button>
  </div>


    <button app-icon-button [iconSize]="'12px'" (click)="closeSelection()" class="slider-close-button">
        <app-icon svgIcon="sliderButtonCancel"></app-icon>
    </button>
</div>
