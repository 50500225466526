import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IconRegistry } from '../../services/icon-registry';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-icon',
  template: `
    <ng-container *ngIf="isMaterialIcon; else svgIcon">
      <i class="material-icons">{{ materialIcon }}</i>
    </ng-container>
    <ng-template #svgIcon>
      <ng-content></ng-content>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
      }

      .material-icons {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    `,
  ],
  imports: [NgIf],
})
export class IconComponent implements OnInit, OnChanges, OnDestroy {
  @Input() svgIcon: string = '';
  @Input() materialIcon: string = '';

  private subscription: Subscription | undefined;

  get isMaterialIcon(): boolean {
    return !!this.materialIcon;
  }

  constructor(
    private elementRef: ElementRef,
    private iconRegistry: IconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['svgIcon'] || changes['materialIcon']) {
      this.setIcon();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setIcon(): void {
    if (this.svgIcon && !this.isMaterialIcon) {
      this.subscription = this.iconRegistry
        .getNamedSvgIcon(this.svgIcon)
        .subscribe((url) => {
          // Get the SVG string from the SafeResourceUrl
          const urlStr = this.sanitizer.sanitize(4, url);

          // Fetch the SVG content
          if (urlStr) {
            fetch(urlStr)
              .then((response) => response.text())
              .then((svgContent) => {
                // Set the SVG content to the element
                this.elementRef.nativeElement.innerHTML = svgContent;

                // Make sure the SVG fills the container
                const svg = this.elementRef.nativeElement.querySelector('svg');
                if (svg) {
                  svg.setAttribute('width', '100%');
                  svg.setAttribute('height', '100%');
                  svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
                }
              })
              .catch((error) =>
                console.error('Error loading SVG icon:', error)
              );
          } else {
            console.error('Invalid URL for SVG icon:', this.svgIcon);
          }
        });
    }
  }
}
