<h4 class="title">{{ title }}</h4>
<div class="popup-list" [attr.data-cy]="'levels-popup-list'">
  <ng-container *ngIf="!isSeeMoreActive">
    <!-- Show only activeLayer feature when isSeeMoreActive === false -->
    <p *ngIf="(properties | objectToKeyValue:activeLayer)[0] as firstFeature else notAvailable">
              <span *ngIf="(firstFeature | formatFeature: true) as formattedFeature ">
                <app-voters-data-list *ngIf="firstFeature.key === PRESIDENTIAL_ELECTIONS_RESULTS2020" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                <app-voters-data-list *ngIf="firstFeature.key === PRESIDENTIAL_ELECTIONS_RESULTS2024" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                <app-voters-data-list *ngIf="firstFeature.key === BIDEN_VS_TRUMP_2020_POPULATION" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                <app-voters-data-list *ngIf="firstFeature.key === HARRIS_VS_TRUMP_2024_POPULATION" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                <app-voters-data-list *ngIf="firstFeature.key === HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                <app-voters-data-list *ngIf="firstFeature.key === SENATE_ELECTION_2022_RESULTS" [properties]="properties" [layer]="firstFeature.key"></app-voters-data-list>
                        <span class="feature-content" *ngIf="
                        firstFeature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2020 &&
                        firstFeature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2024 &&
                        firstFeature.key !== BIDEN_VS_TRUMP_2020_POPULATION &&
                        firstFeature.key !== HARRIS_VS_TRUMP_2024_POPULATION &&
                        firstFeature.key !== HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS &&
                        firstFeature.key !== SENATE_ELECTION_2022_RESULTS">
                          <span class="feature-name">{{ formattedFeature.formattedKey }}:</span>
                          <span class="feature-value" [attr.data-cy]="'levels-popup-list-value'">{{ formattedFeature.formattedValue }}</span>

<!--                          <app-feature-tooltip-->
<!--                            *ngIf="firstFeature.key === POPULATION && firstFeature.value < 10"-->
<!--                            [fillColor]="'#f4d196'"-->
<!--                            [strokeColor]="WHITE"-->
<!--                            [opacity]="1"-->
<!--                            [clickable]="false"-->
<!--                            [tooltipText]="'Small population. Interpret demographic data carefully.'"-->
<!--                            [tooltipPosition]="'right'"-->
<!--                          ></app-feature-tooltip>-->
                        </span>
                        <span *ngIf="comparisonResults![firstFeature.key]
                        && firstFeature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2020
                        && firstFeature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2024
                        && firstFeature.key !== BIDEN_VS_TRUMP_2020_POPULATION
                        && firstFeature.key !== HARRIS_VS_TRUMP_2024_POPULATION
                        && firstFeature.key !== HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS
                        && firstFeature.key !== SENATE_ELECTION_2022_RESULTS" class="comparison-text">
                          {{ comparisonResults![firstFeature.key] }}
                        </span>
              </span>
    </p>
  </ng-container>
  <ng-container *ngIf="isSeeMoreActive">

    <!-- Show all features when isSeeMoreActive === true -->
    <ng-container *ngFor="let feature of properties | objectToKeyValue">
      <ng-container *ngIf="feature.key | isFeatureAccessible: true">

        <p *ngIf="(feature | formatFeature: true) as formattedFeature">
          <app-voters-data-list *ngIf="
          feature.key === PRESIDENTIAL_ELECTIONS_RESULTS2020 ||
          feature.key === PRESIDENTIAL_ELECTIONS_RESULTS2024 ||
          feature.key === BIDEN_VS_TRUMP_2020_POPULATION ||
          feature.key === HARRIS_VS_TRUMP_2024_POPULATION ||
          feature.key === HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS ||
          feature.key === SENATE_ELECTION_2022_RESULTS" [properties]="properties"></app-voters-data-list>
          <span class="feature-content" *ngIf="
          feature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2020 &&
          feature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2024 &&
          feature.key !== BIDEN_VS_TRUMP_2020_POPULATION &&
          feature.key !== HARRIS_VS_TRUMP_2024_POPULATION &&
          feature.key !== HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS &&
          feature.key !== SENATE_ELECTION_2022_RESULTS" [ngClass]="activeLayer === feature.key ? 'selected-feature' : ''">

           <span class="feature-name">{{ formattedFeature.formattedKey }}:</span>
           <span class="feature-value">{{ formattedFeature.formattedValue }}</span>
<!--            <app-feature-tooltip-->
<!--              *ngIf="feature.key === POPULATION && feature.value < 10"-->
<!--              [fillColor]="'#f4d196'"-->
<!--              [strokeColor]="WHITE"-->
<!--              [opacity]="1"-->
<!--              [clickable]="false"-->
<!--              [tooltipText]="'Small population. Interpret demographic data carefully.'"-->
<!--              [tooltipPosition]="'right'"-->
<!--            ></app-feature-tooltip>-->
        </span>


          <span *ngIf="comparisonResults![feature.key] &&
          feature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2020 &&
          feature.key !== PRESIDENTIAL_ELECTIONS_RESULTS2024 &&
          feature.key !== BIDEN_VS_TRUMP_2020_POPULATION &&
          feature.key !== HARRIS_VS_TRUMP_2024_POPULATION &&
          feature.key !== HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS &&
          feature.key !== SENATE_ELECTION_2022_RESULTS" class="comparison-text">
                  {{ comparisonResults![feature.key] }}
        </span>
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>


<ng-template #notAvailable>
  <p>
    <span>
      <span class="feature-content">
       <span class="feature-name">{{ activeLayer | LayerNameFormatPipe }}:</span>
       <span class="feature-value">{{ (colorScaleService.isBeingLoaded | async) ? 'Loading' : 'N/A' }}</span>
<!--        <app-feature-tooltip-->
<!--          [fillColor]="'#f4d196'"-->
<!--          [strokeColor]="WHITE"-->
<!--          [opacity]="1"-->
<!--          [clickable]="false"-->
<!--          [tooltipText]='(activeLayer | LayerNameFormatPipe) + ((colorScaleService.isBeingLoaded | async) ? " is being loaded, please wait" :  " is not available here")'-->
<!--          [tooltipPosition]="'right'"-->
<!--        ></app-feature-tooltip>-->
      </span>
    </span>
  </p>
</ng-template>
