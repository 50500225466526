<div class="years-info-container" *ngIf="isChartVisible">
  <span class="selected-year">{{ selectedYear }}</span>
  <button
    (click)="isYearsVisible = !isYearsVisible"
    class="toggle-accessible-years-button"
  >
    <app-icon
      app-icon-button
      [ngClass]="{ 'opened-arrow-icon': isYearsVisible }"
      class="arrow-right-icon"
      svgIcon="arrow_right"
    >
    </app-icon>
  </button>

  <div *ngIf="isYearsVisible">
    <div class="accessible-years-container">
      <ng-container *ngFor="let year of accessibleYears">
        <button
          *ngIf="year !== selectedYear"
          (click)="onSelectedYearChange(year)"
          class="year-button"
        >
          {{ year }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
