import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconRegistryService } from '../../../../../../shared/services/icon-registry.service';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '../../../../../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../../../../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-chart-year-selection',
  templateUrl: './chart-year-selection.component.html',
  imports: [IconComponent, NgClass, NgIf, NgForOf, IconButtonDirective],
  styleUrls: [
    './chart-year-selection.component.scss',
    '../../../../../../app.component.scss',
  ],
})
export class ChartYearSelectionComponent implements OnInit {
  @Output() selectedYearChange = new EventEmitter<{
    year: number;
    usedFor: string;
  }>();

  @Input() isChartVisible: boolean = false;
  @Input() accessibleYears!: number[];
  @Input() selectedYear!: number;
  @Input() usedFor!: string;

  public isYearsVisible: boolean = false;

  constructor(private iconRegistry: IconRegistryService) {
    this.iconRegistry.initRightMenuIcons();
  }

  ngOnInit(): void {}

  public onSelectedYearChange(year: number) {
    this.selectedYearChange.emit({ year: year, usedFor: this.usedFor });
  }
}
