import { Component, Input, OnInit } from '@angular/core';
import { Feature } from 'geojson';
import { SubscriptionPlans } from '../../../../../user/user/user.model';
import { UserAccessService } from '../../../../../user/access/user-access.service';
import { NgForOf, NgIf } from '@angular/common';

export interface CrimeData {
  [key: string]: number;
}

@Component({
  selector: 'app-ori-popup-list',
  templateUrl: './ori-popup-list.component.html',
  imports: [NgIf, NgForOf],
  styleUrls: ['./ori-popup-list.component.scss', '../map-popup.component.scss'],
})
export class OriPopupListComponent implements OnInit {
  @Input() properties!: Feature['properties'];
  @Input() isSeeMoreActive!: boolean;

  groupedCrimes: any[] = [];
  groupedByLocation: any[] = [];

  constructor(private accessService: UserAccessService) {}

  ngOnInit(): void {
    this.groupedCrimes = this.generateCrimeSummary(this.properties!);
    this.groupedByLocation = this.formatByLocation(this.properties!);
  }

  private generateCrimeSummary(data: CrimeData): any[] {
    const summary = [];
    const isCommercialDataInUse =
      this.accessService.getUserPlan() === SubscriptionPlans.ENTERPRISE;

    const crimeGroupToList: { [key: string]: string[] } = {
      'Violent crime': ['Murder', 'Kidnapping', 'Rape', 'Assault'],
      'Property crime': isCommercialDataInUse
        ? [
            'Robbery',
            'Burglary',
            'Theft (business)',
            'Destruction/Damage/Vandalism of Property',
          ]
        : [
            'Robbery',
            'Burglary',
            'Theft (community)',
            'Motor Vehicle Theft',
            'Destruction/Damage/Vandalism of Property',
          ],
      'Public order crime': [
        'Prostitution',
        'Assisting or Promoting Prostitution',
      ],
      'Counterfeiting & Fraud': [
        'Counterfeiting/Forgery',
        'False Pretenses/Swindle/Confidence Game',
        'Credit Card/Automatic Teller Machine Fraud',
        'Impersonation',
        'Welfare Fraud',
        'Wire Fraud',
      ],
    };

    const crimeSubcategories: { [key: string]: string[] } = {
      Rape: [
        'Forcible Rape',
        'Forcible Sodomy',
        'Sexual Assault With An Object',
        'Fondling (Indecent Liberties/Child Molesting)',
      ],
      Assault: ['Aggravated Assault', 'Simple Assault', 'Intimidation'],
      'Theft (community)': [
        'Theft From Building',
        'Theft From Motor Vehicle',
        'Theft of Motor Vehicle Parts/Accessories',
        'All Other Larceny',
      ],
      'Theft (business)': [
        'Theft From Building',
        'Theft From Motor Vehicle',
        'Theft of Motor Vehicle Parts/Accessories',
        'All Other Larceny',
        'Theft From Coin-Operated Machine or Device',
        'Pocket-picking',
        'Purse-snatching',
        'Shoplifting',
      ],
    };

    const crimeCategoryMapping: { [key: string]: string } = {
      Murder: 'Murder/Nonnegligent Manslaughter',
    };

    for (const [group, categories] of Object.entries(crimeGroupToList)) {
      const groupSummary = {
        groupName: this.formatGroupName(group),
        totalValue: 0,
        categories: [] as any[],
      };

      for (const category of categories) {
        let sum = 0;
        let subcategoryDetails: any = [];
        const actualCategory = crimeCategoryMapping[category] || category;
        const formattedCategory = this.formatCategoryName(actualCategory);

        if (crimeSubcategories[formattedCategory]) {
          subcategoryDetails = crimeSubcategories[formattedCategory].map(
            (subcategory) => {
              const count = data[subcategory] || 0;
              return { subcategoryName: subcategory, count };
            }
          );
          sum = subcategoryDetails.reduce(
            (total: any, subcategory: any) => total + subcategory.count,
            0
          );
        } else {
          sum = data[actualCategory] || 0;
        }

        if (sum > 0) {
          groupSummary.totalValue += sum;
          groupSummary.categories.push({
            categoryName: this.formatCategoryName(category),
            count: sum,
            subcategories: subcategoryDetails,
          });
        }
      }

      if (groupSummary.categories.length > 0) {
        summary.push(groupSummary);
      }
    }
    return summary;
  }

  private formatByLocation(data: CrimeData): any[] {
    const byLocation = [];

    for (let field in data) {
      if (field.includes('BY_LOCATION_')) {
        const locationName = field
          .replace('BY_LOCATION_', '')
          .replace(/_/g, ' ');
        const percentage = (data[field] * 100).toFixed(1);
        if (Number(percentage) > 0) {
          byLocation.push({ locationName, percentage });
        }
      }
    }

    return byLocation;
  }

  private formatGroupName(groupName: string): string {
    return groupName.replace(/ \(community\)| \(business\)/, '');
  }

  private formatCategoryName(categoryName: string): string {
    return categoryName.replace(/ \(community\)| \(business\)/, '');
  }
}
