:host {
  display: inline-block;
}

.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.toggle-container.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.toggle-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
  font-size: 14px;
}

.toggle-track {
  position: relative;
  width: 36px;
  height: 19px;
  border-radius: 34px;
  background-color: var(--UI-disable);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}
.toggle-track.checked {
  background-color: var(--primary-blue);
}

.toggle-thumb {
  position: absolute;
  left: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
}
.checked .toggle-thumb {
  transform: translateX(16px);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2hvbWUvcnVubmVyL3dvcmsvbWFwLWFpLWZyb250ZW5kL21hcC1haS1mcm9udGVuZC9zcmMvYXBwL3NoYXJlZC9jb21wb25lbnRzL2N1c3RvbS1zbGlkZS10b2dnbGUvY3VzdG9tLXNsaWRlLXRvZ2dsZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbn1cblxuLnRvZ2dsZS1jb250YWluZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIHVzZXItc2VsZWN0OiBub25lO1xuXG4gICYuZGlzYWJsZWQge1xuICAgIG9wYWNpdHk6IDAuNTtcbiAgICBjdXJzb3I6IG5vdC1hbGxvd2VkO1xuICB9XG59XG5cbi50b2dnbGUtY29udGVudCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIG1hcmdpbjogMCA4cHg7XG4gIGZvbnQtc2l6ZTogMTRweDtcbn1cblxuLnRvZ2dsZS10cmFjayB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgd2lkdGg6IDM2cHg7XG4gIGhlaWdodDogMTlweDtcbiAgYm9yZGVyLXJhZGl1czogMzRweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tVUktZGlzYWJsZSk7XG4gIHRyYW5zaXRpb246IGJhY2tncm91bmQtY29sb3IgMC4zcyBlYXNlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuXG4gICYuY2hlY2tlZCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcHJpbWFyeS1ibHVlKTtcbiAgfVxufVxuXG4udG9nZ2xlLXRodW1iIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICBsZWZ0OiAycHg7XG4gIGhlaWdodDogMTZweDtcbiAgd2lkdGg6IDE2cHg7XG4gIGJvcmRlci1yYWRpdXM6IDUwJTtcbiAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gIHRyYW5zaXRpb246IHRyYW5zZm9ybSAwLjNzIGVhc2UsIGJhY2tncm91bmQtY29sb3IgMC4zcyBlYXNlO1xuICBib3gtc2hhZG93OiAwIDJweCAycHggcmdiYSgwLCAwLCAwLCAwLjI0KTtcblxuICAuY2hlY2tlZCAmIHtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoMTZweCk7XG4gIH1cbn1cbiJdfQ== */