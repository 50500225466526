import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconRegistryService } from '../../../../shared/services/icon-registry.service';
import { SelectionToolService } from './selection-tool.service';
import { Subject, takeUntil } from 'rxjs';
import { ActiveToolService, MapTools } from '../../../active-tool.service';
import { ArbitraryCellService } from '../../services/arbitrary-cell.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { IconButtonDirective } from '../../../../shared/directives/icon-button.directive';

@Component({
  selector: 'app-selection-tool',
  templateUrl: './selection-tool.component.html',
  styleUrls: [
    './selection-tool.component.scss',
    '../../../../app.component.scss',
  ],
  imports: [
    IconComponent,
    NgIf,
    MatSlider,
    MatSliderThumb,
    FormsModule,
    NgClass,
    MatTooltip,
    AsyncPipe,
    IconButtonDirective,
  ],
})
export class SelectionToolComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  public isCircleSelectionActive!: boolean;

  public circleRadius: number = 10;
  public isSliderActive = false;

  constructor(
    public selectionService: SelectionToolService,
    private iconRegistry: IconRegistryService,
    private activeToolService: ActiveToolService,
    public combinedCellService: ArbitraryCellService
  ) {
    this.iconRegistry.initSelectionToolIcons();
  }

  ngOnInit(): void {
    this.isCircleSelectionActive = this.selectionService.circleSelectionStatus;

    this.activeToolService.activeTool
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeTool) => {
        if (activeTool === MapTools.CIRCLE_SELECTION_TOOL) {
          this.isSliderActive = true;
          this.isCircleSelectionActive = true;
          this.selectionService.enableCircleSelection();
        } else {
          this.isSliderActive = false;
          this.isCircleSelectionActive = false;
          this.selectionService.disable();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public handleRequestCombinedCell(): void {
    this.combinedCellService.handleArbitraryCellCreationAndIndex(
      this.selectionService.lastCircleGeometry!
    );
    this.selectionService.disableCircleSelection();
  }

  public handleSelectNewLocation(): void {
    this.isCircleSelectionActive = !!this.isCircleSelectionActive;
  }

  public changeSelectionRadius(val: number): void {
    this.circleRadius = val;
    this.selectionService.changeSelectionRadius(val);
  }

  public closeSelection(): void {
    this.isCircleSelectionActive = false;
  }

  public toggleRadiusSelection(): void {
    this.isCircleSelectionActive = !this.isCircleSelectionActive;
    if (this.isCircleSelectionActive) {
      this.activeToolService.activeTool.next(MapTools.CIRCLE_SELECTION_TOOL);
    } else {
      this.activeToolService.activeTool.next(MapTools.NONE);
    }
  }
}
