import {
  ARBITRARY_CELL,
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  STATE_LEVEL_LAYER,
} from '../../map/mapbox/services/layer-store.service';

export function convertLayerIdToCellType(layerId: string): string | undefined {
  switch (layerId) {
    case STATE_LEVEL_LAYER:
      return 'STATE';

    case COUNTY_LEVEL_LAYER:
      return 'COUNTY';

    case H3_RES7_LEVEL_LAYER:
      return 'H3_RES_7';

    case H3_RES5_LEVEL_LAYER:
      return 'H3_RES_5';

    case ARBITRARY_CELL:
      return ARBITRARY_CELL;

    default:
      throw new Error('implement switch case for ' + layerId);
  }
}
