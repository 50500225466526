import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum MapTools {
  LEVEL_SELECTION_TOOL = 'levelSelectionTool',
  CIRCLE_SELECTION_TOOL = 'circleSelectionTool',
  MULTIPLE_FEATURES_SELECTION_TOOL = 'MULTIPLE_FEATURES_SELECTION_TOOL',
  NONE = '',
}

@Injectable({
  providedIn: 'root'
})
export class ActiveToolService {

  public activeTool = new BehaviorSubject<MapTools>(MapTools.NONE);

  constructor() { }
}
