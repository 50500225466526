import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ComparisonService } from '../../../services/comparison.service';
import { Feature } from 'geojson';
import {
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  POPULATION,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  SENATE_ELECTION_2022_RESULTS,
} from '../../../../../shared/types/feature-data-type';
import { WHITE } from '../../colorscale/color-scale.component';
import { ColorscaleService } from '../../colorscale/colorscale.service';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ObjectToKeyValuePipe } from '../../../../../shared/pipes/object-to-key-value.pipe';
import { FormatFeaturePipe } from '../format-feature.pipe';
import { VotersDataListComponent } from './voters-data-list/voters-data-list.component';
import { IsFeatureAccessiblePipe } from '../../../../menu/right-menu/layers-menu/shared/is-feature-accessible.pipe';
import { LayerNameFormatPipe } from '../../../../common-colorscale/layer-name-format.pipe';

@Component({
  selector: 'app-layers-popup-list',
  templateUrl: './levels-popup-list.component.html',
  styleUrls: [
    './levels-popup-list.component.scss',
    '../map-popup.component.scss',
  ],
  imports: [
    NgIf,
    ObjectToKeyValuePipe,
    FormatFeaturePipe,
    VotersDataListComponent,
    // FeatureTooltipComponent,
    IsFeatureAccessiblePipe,
    NgForOf,
    NgClass,
    LayerNameFormatPipe,
    AsyncPipe,
  ],
})
export class LevelsPopupListComponent implements OnInit, OnChanges {
  @Input() isSeeMoreActive!: boolean;
  @Input() activeLayer!: string;
  @Input() activeLevel!: string;
  @Input() properties!: { [key: string]: any };
  @Input() title!: string;
  @Input() isHoverPopup: boolean = false;

  comparisonResults: Feature['properties'] = {};

  constructor(
    private comparisonService: ComparisonService,
    public colorScaleService: ColorscaleService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['properties']) {
      this.comparisonService
        .getAndCalculateComparisonData(this.properties)
        .subscribe((results) => {
          for (let result in results) {
            this.comparisonResults![result] = results[result];
          }
        });
    }
  }

  protected readonly POPULATION = POPULATION;
  protected readonly WHITE = WHITE;
  protected readonly PRESIDENTIAL_ELECTIONS_RESULTS2020 =
    PRESIDENTIAL_ELECTIONS_RESULTS2020;
  protected readonly BIDEN_VS_TRUMP_2020_POPULATION =
    BIDEN_VS_TRUMP_2020_POPULATION;
  protected readonly HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS =
    HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS;
  protected readonly PRESIDENTIAL_ELECTIONS_RESULTS2024 =
    PRESIDENTIAL_ELECTIONS_RESULTS2024;
  protected readonly HARRIS_VS_TRUMP_2024_POPULATION =
    HARRIS_VS_TRUMP_2024_POPULATION;
  protected readonly SENATE_ELECTION_2022_RESULTS =
    SENATE_ELECTION_2022_RESULTS;
}
