import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserverService } from '../../../../shared/services/breakpoint-observer.service';
import { MenuGroupType } from '../../../map.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { LayersMenuComponent } from '../layers-menu/layers-menu.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
  selector: 'app-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.scss'],
  imports: [AsyncPipe, NgIf, LayersMenuComponent, IconComponent],
})
export class MapMenuComponent implements OnInit {
  @Input() activeMenuGroup!: MenuGroupType;

  @Output() closeRightMenu = new EventEmitter<void>();

  constructor(public breakpointObserverService: BreakpointObserverService) {}

  ngOnInit(): void {}

  public emitCloseMenuMobile(): void {
    this.closeRightMenu.emit();
  }
}
