import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, Observable, tap, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonResponse } from '../shared/api/backend-config';
import { ARBITRARY_CELL } from '../map/mapbox/services/layer-store.service';
import { ReportTypes } from '../user/user/user.model';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import {
  SELECTED_POLYGON,
  SelectedCellService,
} from '../map/mapbox/services/selected-cell.service';
import { MapBoxService } from '../map/mapbox/mapbox.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NavigationService } from '../shared/services/navigation.service';
import { finalize } from 'rxjs/operators';

export interface RequestPricesResponse {
  paymentUrl: string;
  totalPriceCents: number;
  reports: {
    reportType: ReportTypes;
    quantity: number;
    priceCents: number;
  }[];
}

export interface RequestPricesBody {
  reports: {
    location: {
      cellId: string | number,
      cellType: string | undefined
      name: string,
    },
    type: string
  }[]
}

export interface RequestedReport {
  url?: string,
  name: string,
  orderedAt: string,
  type: string,
  status: string
}

export type ReportStatus = 'idle' | 'processing' | 'ready' | 'error';

export const LAST_UNAUTHORIZED_REPORT_ID = 'LAST_UNAUTHORIZED_REPORT_ID'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  public lastCreatedCellId: string | null = null
  public lastSelectedReportType: ReportTypes | null = null
  public pricesData: RequestPricesResponse | null = null

  public selectedLocation: string | null = null
  public selectedRadius: number | null = null
  public selectedFeature: MapboxGeoJSONFeature | null = null

  public isRedirectedToSelectLocation = false


  // Block of flags used for unauthorized reports
  public unauthorizedReportStatus: ReportStatus = 'idle';
  public unauthorizedReportLink: string | null = null;
  public lastUnauthorizedReportId: string | null = null;
  public isFirstReportAlreadyProcessed: boolean = false
  public isEmailSubmit: boolean = false;
  public email: string | null = null
  public showSubmitEmailMessage: boolean = false

  constructor(private http: HttpClient,
              private selectedCellService: SelectedCellService,
              private mapboxService: MapBoxService,
              private router: Router,
              private toast: ToastrService,
              private navService: NavigationService) {
    this.selectedCellService.selectedCellGeoId.subscribe(id => {
      this.setSelectedReportsFeature(this.selectedCellService.getSelectedFeature())
    })


    this.lastUnauthorizedReportId = localStorage.getItem(LAST_UNAUTHORIZED_REPORT_ID)
    if (!!this.lastUnauthorizedReportId) {
      this.getUnauthorizedReportById().subscribe((data) => {
        this.unauthorizedReportStatus = 'ready'
        this.isFirstReportAlreadyProcessed = true
        this.unauthorizedReportLink = data.object;

        localStorage.removeItem(LAST_UNAUTHORIZED_REPORT_ID)
      });
    }
  }

  public getRequestedReports(): Observable<JsonResponse<RequestedReport[]>> {
    return this.http.get<JsonResponse<RequestedReport[]>>(`${environment.apiUrl}report/my`)
  }

  public requestPrices(type: 'arbitrary' | 'selected'): Observable<JsonResponse<RequestPricesResponse>> {
    const body = this.getRequestPricesBody(type)
    return this.http.post<JsonResponse<RequestPricesResponse>>(`${environment.apiUrl}report/order`, body)
  }

  public getRequestPricesBody(type: 'arbitrary' | 'selected'): RequestPricesBody {
    const isArbitrary = type === 'arbitrary'

    return {
      reports: [
        {
          location: {
            cellId: isArbitrary ? this.lastCreatedCellId! : this.selectedFeature!.id!,
            cellType: isArbitrary ? ARBITRARY_CELL : this.selectedCellService.getCellType(),
            name: isArbitrary ? this.selectedLocation! + ', '+ this.selectedRadius + ' mile'
              : this.selectedLocation!,
          },
          type: this.lastSelectedReportType!
        }
      ]
    }
  }

  public getUnauthorizedReportById(): Observable<JsonResponse<any>> {
    return this.http.get<JsonResponse<any>>(`${environment.apiUrl}report/free/getReportLinkById?reportId=${this.lastUnauthorizedReportId}`)
  }

  public sendUnauthorizedReportToEmail(email: string): Observable<JsonResponse<string>> {
    this.unauthorizedReportStatus = 'processing'
    this.navService.isNavigationBlockedManually = true
    return this.http.get<JsonResponse<any>>(`${environment.apiUrl}report/free/sendEmail?email=${email}&reportId=${this.lastUnauthorizedReportId}`)
      .pipe(finalize(() => {
        this.navService.isNavigationBlockedManually = false
      }),catchError((err) => {
        const errorMessage = err.error.errorMessage
        if (errorMessage === 'Report already sent to this email' || errorMessage === 'Report already sent to another email') {
          this.toast.warning('Report already sent to email', 'Please order new report')
        }
        this.unauthorizedReportStatus = 'error'

        return EMPTY
      }), tap((data) => {
        if (data.object === 'Email sent') {
          this.isEmailSubmit = true;
          this.toast.success('Please check your inbox', 'Email sent');
          this.unauthorizedReportStatus = 'ready';
        }
      }))

  }

  public requestCreateReport(wkt: string): Observable<JsonResponse<string>> {
    return this.http.post<JsonResponse<string>>(`${environment.apiUrl}report/free/order`, {
      wktGeometry: wkt,
      locationName: this.selectedLocation,
      type: ReportTypes.COMMUNITY_COMPASS,
    }).pipe(catchError((err) => {
      const errorMessage = err.error.errorMessage

      if (errorMessage === 'You can order report only once in 2 minutes') {
        this.toast.warning('You can order report only once in 2 minutes', 'Please try later')
      } else if (errorMessage === 'Limit of 7 reports per email and IP is reached') {
        this.toast.warning('Limit of 7 reports is reached, please sign in to continue', 'Please sign in')
          .onTap.subscribe(() => this.router.navigate(['sign-in']))
      }
      this.unauthorizedReportStatus = 'error'
      this.navService.isNavigationBlockedManually = false

      return EMPTY
      }),
      tap(data => {
        this.lastUnauthorizedReportId = data.object;
      })
    );
  }

  public processUnauthorizedReport(wkt: string): void {
    if (!this.isEmailSubmit && this.unauthorizedReportStatus === 'ready') {
      this.showSubmitEmailMessage = true
      return
    }

    this.unauthorizedReportStatus = 'processing';
    this.navService.isNavigationBlockedManually = true

    this.requestCreateReport(wkt)
      .subscribe(res => {
        if (res && res.object) {
          // Start polling every 5 seconds for the report link.
          const pollingSub = timer(0, 5000).subscribe(() => {
            this.getUnauthorizedReportById()
              .subscribe(data => {
              if (data && data.object) {

                this.unauthorizedReportLink = data.object;
                window.open(data.object, '_blank');

                localStorage.setItem(LAST_UNAUTHORIZED_REPORT_ID, this.lastUnauthorizedReportId!)
                this.isFirstReportAlreadyProcessed = true

                this.unauthorizedReportStatus = 'ready';
                this.navService.isNavigationBlockedManually = false

                if (this.isEmailSubmit && this.email) {
                  this.sendUnauthorizedReportToEmail(this.email).subscribe()
                }

                pollingSub.unsubscribe(); // Stop polling once report link is ready.
              }
            });
          });
        }
      });
  }

  public handleReportsDataOnRedirect(): void {
    // Set to true to handle redirect properly and skip type selection in order-report
    this.isRedirectedToSelectLocation = true;

    // On this step we will determine what report type is selected based on what description we have
    this.setSelectedReportType(ReportTypes.COMMUNITY_COMPASS);

    this.setSelectedReportsFeature(this.selectedCellService.getSelectedFeature())
  }

  public setSelectedReportsFeature(feature: MapboxGeoJSONFeature | null): void {
    this.selectedFeature = feature
  }

  public setSelectedReportType(reportType: ReportTypes): void {
    this.lastSelectedReportType = reportType
  }

  public deselectReportsFeature(): void {
    this.selectedFeature = null

    if (this.mapboxService.reportsMap.getLayer(SELECTED_POLYGON)) {
      this.mapboxService.reportsMap.removeLayer(SELECTED_POLYGON)
    }
  }

  public outlineReportCell(feature: MapboxGeoJSONFeature): void {
    this.mapboxService.reportsMap.addLayer({
      id: SELECTED_POLYGON,
      type: 'line',
      source: {
        type: 'geojson',
        data: feature,
      },
      paint: {
        'line-color': '#695dff',
        'line-width': 2,
      },
    });
  }

  public resetData(): void {
    this.lastCreatedCellId = null
    this.pricesData = null
  }
}
