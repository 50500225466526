import { Feature } from 'geojson';
import {
  AREA,
  COMBINED_HWW_BY_STATE,
  GINI_INDEX,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  HOUSEHOLDS,
  MEDIAN_INCOME,
  PEOPLE_PER_MI2,
  POPULATION,
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
} from '../../../shared/types/feature-data-type';
import { transformString } from '../../common-colorscale/layer-name-format.pipe';
import {
  GRADES_OFFERED_HIGHEST,
  GRADES_OFFERED_LOWEST,
  KINDERGARTEN_STUDENTS,
  MAGNET_SCHOOL_INDICATOR,
  PHYSICAL_ADDRESS,
  PREKINDERGARTEN_STUDENTS,
  SCHOOL_PHONE_NUMBER,
  SCHOOL_TYPE_DESCRIPTION,
  SchoolsPoiProperties,
  STUDENT_TEACHER_RATIO,
  TOTAL_STUDENTS_ALL_GRADES,
  WHETHER_CHARTER_SCHOOL,
} from '../../menu/right-menu/layers-menu/education/education.service';

export interface FormattedCellInfo {
  readableFeatureName: string;
  readableFeatureValue: string;
  valueExists: boolean;
}

export class DataFormatter {
  static readonly notShowOnStateLevel = [
    WISE_INDEX_BY_STATE,
    COMBINED_HWW_BY_STATE,
    HEALTH_INDEX_BY_STATE,
    WEALTH_INDEX_BY_STATE,
  ];

  constructor() {}

  public static formatArbitraryCellProperties(
    properties: Feature['properties']
  ): Feature['properties'] {
    const formattedProperties: Feature['properties'] = {
      ...properties,
      [HEALTH_INDEX_BY_COUNTRY]: properties!['HEALTH_INDEX'].byCountry,
      [HEALTH_INDEX_BY_STATE]: properties!['HEALTH_INDEX'].byState,
      [WEALTH_INDEX_BY_COUNTRY]: properties!['WEALTH_INDEX'].byCountry,
      [WEALTH_INDEX_BY_STATE]: properties!['WEALTH_INDEX'].byState,
      [WISE_INDEX_BY_STATE]: properties!['WISE_INDEX'].byState,
      [WISE_INDEX_BY_COUNTRY]: properties!['WISE_INDEX'].byCountry,
      [AREA]: properties!['AREA'].areaMi2,
      [POPULATION]: properties!['POPULATION'],
      [MEDIAN_INCOME]: properties!['MEDIAN_INCOME'],
      [PEOPLE_PER_MI2]: properties!['POPULATION_DENSITY_MILES'],
      [HOUSEHOLDS]: properties!['HOUSEHOLDS'],
      [GINI_INDEX]: properties!['GINI_INDEX'],
      // "CONVENIENCE_STORES": properties!["TARGET_AUDIENCE"]["CONVENIENCE_STORES"],
      // "RETAIL": properties!["TARGET_AUDIENCE"]["RETAIL"],
      // "DRUGSTORES": properties!["TARGET_AUDIENCE"]["DRUGSTORES"],
      // "BEAUTY": properties!["TARGET_AUDIENCE"]["BEAUTY"],
    };

    delete formattedProperties![AREA];
    delete formattedProperties!['POPULATION'];
    delete formattedProperties!['MEDIAN_INCOME'];
    delete formattedProperties!['POPULATION_DENSITY_MILES'];
    delete formattedProperties!['HOUSEHOLDS'];
    delete formattedProperties!['GINI_INDEX'];
    delete formattedProperties!['WISE_INDEX'];
    delete formattedProperties!['WEALTH_INDEX'];
    delete formattedProperties!['HEALTH_INDEX'];
    return formattedProperties;
  }

  public static formatSchoolsPoiFeature(
    properties: SchoolsPoiProperties,
    isSeeMoreActive: boolean
  ): FormattedCellInfo[] {
    const schoolDataInUseByDefault = [
      GRADES_OFFERED_LOWEST,
      TOTAL_STUDENTS_ALL_GRADES,
    ];

    const allSchoolPoiDataInUse: string[] = [
      GRADES_OFFERED_LOWEST,
      TOTAL_STUDENTS_ALL_GRADES,
      PHYSICAL_ADDRESS,
      SCHOOL_PHONE_NUMBER,
      SCHOOL_TYPE_DESCRIPTION,
      WHETHER_CHARTER_SCHOOL,
      MAGNET_SCHOOL_INDICATOR,
      STUDENT_TEACHER_RATIO,
      PREKINDERGARTEN_STUDENTS,
      KINDERGARTEN_STUDENTS,
    ];

    if (isSeeMoreActive) {
      return allSchoolPoiDataInUse.map((data) =>
        this.formatSchoolsData(data, properties)
      );
    } else {
      return schoolDataInUseByDefault.map((data) =>
        this.formatSchoolsData(data, properties)
      );
    }
  }

  private static formatSchoolsData(
    data: string,
    formattedData: SchoolsPoiProperties
  ): FormattedCellInfo {
    // @ts-ignore
    let featureValue = formattedData![data];

    let readableFeatureValue: string;
    let readableFeatureName: string = '';

    if (data === GRADES_OFFERED_LOWEST) {
      readableFeatureName = 'Grade range';
      readableFeatureValue = `${formattedData[GRADES_OFFERED_LOWEST]}-${formattedData[GRADES_OFFERED_HIGHEST]}`;
    } else if (data === STUDENT_TEACHER_RATIO) {
      readableFeatureName = 'Student-teacher ratio';
      readableFeatureValue =
        featureValue >= 50 ? 'N/A' : `${featureValue.toFixed(0)}:1`;
      if (featureValue === 0) {
        featureValue = null;
      }
    } else if (data === TOTAL_STUDENTS_ALL_GRADES) {
      readableFeatureName = 'Total students';
      readableFeatureValue = featureValue.toFixed(0);
      if (featureValue === 0) {
        featureValue = null;
      }
    } else if (data === SCHOOL_PHONE_NUMBER) {
      readableFeatureName = 'Phone number';
      readableFeatureValue = formattedData.schoolPhoneNumber!;
    } else if (typeof featureValue === 'number') {
      readableFeatureValue = Number.isInteger(featureValue)
        ? featureValue.toString()
        : featureValue.toFixed(2);
    } else if (typeof featureValue === 'string' && featureValue) {
      readableFeatureValue = this.toTitleCase(featureValue);
    } else {
      readableFeatureValue = '';
    }

    return {
      readableFeatureName:
        readableFeatureName !== ''
          ? readableFeatureName
          : transformString(data),
      readableFeatureValue: readableFeatureValue,
      valueExists: featureValue != null,
    };
  }

  public static toTitleCase(value: string): string {
    return value
      .split(' ')
      .map((word: string) => word.toLowerCase())
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
